import React, { Fragment, useEffect, useRef, useState } from "react"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core"
import useAsyncEffect from "use-async-effect"
import InfiniteScroll from "react-infinite-scroll-component"
import { RowBox } from "../Boxes"
import { Caption, FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"
import { toId, useDebounce } from "../../utils"
import { ADMINABLE_USERS_LIMIT, useMutationIntegrationSetUserAttribute, useQueryAdminableUsers } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { IntegrationAttributeComponent } from "./IntegrationAttributeComponent"

const useAttributeListItemStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  text: {
    padding: 4,
  },
}))

const getUserAttribute = (user, integration, attribute) =>
  user?.attributes.find((item) => toId(item.integration) === toId(integration) && item.key === attribute.key)?.value ||
  ""

const UserAttributeListItem = ({ integration, user, attribute, onChange }) => {
  const mounted = useRef(false)
  const classes = useAttributeListItemStyles()
  const [setAttribute] = useMutationIntegrationSetUserAttribute()
  const [value, setValue] = useState(getUserAttribute(user, integration, attribute))
  const debouncedValue = useDebounce(value, 300)

  useAsyncEffect(async () => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    await setAttribute({
      variables: {
        id: toId(integration),
        user: toId(user),
        key: attribute.key,
        value: debouncedValue.toString(),
      },
    })
    onChange && onChange()
  }, [debouncedValue, attribute])

  useEffect(() => {
    setValue(getUserAttribute(user, integration, attribute))
  }, [attribute, integration, user])

  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListItemAvatar classes={{ root: classes.avatar }}>
        <RowBox>
          <Icon name="integration-user" />
        </RowBox>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ component: "div" }}
        primary={
          <RowBox alignContent="center">
            <Grid container alignContent="center">
              <Grid item xs={6}>
                <FieldSectionHeading mb={0}>
                  <Tooltip title={toId(user)}>
                    <span>{user.fullName}</span>
                  </Tooltip>
                </FieldSectionHeading>

                <Box>{attribute.name}</Box>
                <Caption mb={0}>{attribute.description}</Caption>
              </Grid>
              <Grid item xs={6}>
                <IntegrationAttributeComponent
                  type="user"
                  integration={integration}
                  attribute={attribute}
                  value={value}
                  onChange={setValue}
                />
              </Grid>
            </Grid>
          </RowBox>
        }
      />
    </ListItem>
  )
}

const adminableUsersOptions = {
  variables: { offset: 0, limit: 30, status: ["active", "invited"] },
  fetchPolicy: "cache-and-network",
}

const IntegrationUserConfiguration = ({ integration }) => {
  const { data, fetchMore } = useQueryAdminableUsers(adminableUsersOptions)
  const [offset, setOffset] = useState(0)
  const {
    integrationProvider: { name, attributes },
  } = integration

  useEffect(() => {
    fetchMore({
      variables: {
        offset,
      },
    })
  }, [fetchMore, offset])

  const items = data?.adminableUsers || []

  const loadMore = () => {
    setOffset(offset + ADMINABLE_USERS_LIMIT)
  }

  return (
    <Box m={1}>
      <Caption>The following {name} attributes are mapped to your Operandio users.</Caption>

      <InfiniteScroll dataLength={items.length || 0} next={loadMore} scrollableTarget="CreatorMaster-content" hasMore>
        {items.map((user, itemIndex) => (
          <Fragment key={toId(user)}>
            {itemIndex > 0 && <Divider />}
            <List disablePadding>
              {attributes.user
                .filter((attribute) => attribute.component !== "hidden")
                .map((attribute, attributeIndex) => (
                  <Fragment key={attribute.key}>
                    {attributeIndex > 0 && <Divider />}
                    <UserAttributeListItem integration={integration} user={user} attribute={attribute} />
                  </Fragment>
                ))}
            </List>
          </Fragment>
        ))}
      </InfiniteScroll>
      {!data && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
    </Box>
  )
}

export { IntegrationUserConfiguration }
