import React from "react"
import { makeStyles, Typography, Box, Divider } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: ({ size }) => (size === "large" ? 20 : 16),
    lineHeight: ({ size }) => (size === "large" ? "36px" : "20px"),
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
  divider: {
    "&:first-child": {
      marginTop: 0,
    },
  },
}))

const FieldSectionHeading = ({ children, mb = 2, size = "normal", className = "", divider = false, ...rest }) => {
  const classes = useStyles({ size })
  return (
    <>
      {divider && (
        <Box className={classes.divider} mt={3} mb={3}>
          <Divider />
        </Box>
      )}
      <Box mb={mb} {...rest}>
        <Typography variant="h3" className={`${classes.root} ${className}`}>
          {children}
        </Typography>
      </Box>
    </>
  )
}

export { FieldSectionHeading }
