const processFormats = [
  // Numeric
  {
    name: "numeric_percentage",
    display: "Percentage (%)",
    symbol: "%",
    type: "Numeric",
  },
  // Temperature
  {
    name: "temperature_celsius",
    display: "Celsius (°C)",
    symbol: "°C",
    type: "Temperature",
  },
  {
    name: "temperature_fahrenheit",
    display: "Fahrenheit (°F)",
    symbol: "°F",
    type: "Temperature",
  },
  // Weight
  {
    name: "weight_grams",
    display: "Grams (g)",
    symbol: "g",
    type: "Weight",
  },
  {
    name: "weight_kilograms",
    display: "Kilograms (kg)",
    symbol: "kg",
    type: "Weight",
  },
  {
    name: "weight_ounces",
    display: "Ounces (oz)",
    symbol: "oz",
    type: "Weight",
  },
  {
    name: "weight_pounds",
    display: "Pounds (lb)",
    symbol: "lb",
    type: "Weight",
  },
  // Length
  {
    name: "length_millimeters",
    display: "Millimeters (mm)",
    symbol: "mm",
    type: "Length",
  },
  {
    name: "length_centimeters",
    display: "Centimeters (cm)",
    symbol: "cm",
    type: "Length",
  },
  {
    name: "length_meters",
    display: "Meters (m)",
    symbol: "m",
    type: "Length",
  },
  {
    name: "length_kilometers",
    display: "Kilometers (km)",
    symbol: "km",
    type: "Length",
  },
  {
    name: "length_inches",
    display: "Inches (in)",
    symbol: "in",
    type: "Length",
  },
  {
    name: "length_feet",
    display: "Feet (ft)",
    symbol: "ft",
    type: "Length",
  },
  {
    name: "length_yards",
    display: "Yards (yd)",
    symbol: "yd",
    type: "Length",
  },
  {
    name: "length_miles",
    display: "Miles (mi)",
    symbol: "mi",
    type: "Length",
  },
  // Volume
  {
    name: "volume_milliliters",
    display: "Milliliters (mL)",
    symbol: "mL",
    type: "Volume",
  },
  {
    name: "volume_liters",
    display: "Liters (L)",
    symbol: "L",
    type: "Volume",
  },
  {
    name: "volume_cubic_centimeters",
    display: "Cubic centimeters (cm³)",
    symbol: "cm³",
    type: "Volume",
  },
  {
    name: "volume_cubic_meters",
    display: "Cubic meters (m³)",
    symbol: "m³",
    type: "Volume",
  },
  {
    name: "volume_fluid_ounces",
    display: "Fluid ounces (fl oz)",
    symbol: "fl oz",
    type: "Volume",
  },
  {
    name: "volume_gallons",
    display: "Gallons (gal)",
    symbol: "gal",
    type: "Volume",
  },
  {
    name: "volume_cubic_inches",
    display: "Cubic inches (in³)",
    symbol: "in³",
    type: "Volume",
  },
  {
    name: "volume_cubic_feet",
    display: "Cubic feet (ft³)",
    symbol: "ft³",
    type: "Volume",
  },
  // Time
  {
    name: "time_seconds",
    display: "Seconds (s)",
    symbol: "s",
    type: "Time",
  },
  {
    name: "time_minutes",
    display: "Minutes (mins)",
    symbol: "min",
    type: "Time",
  },
  {
    name: "time_hours",
    display: "Hours (hrs)",
    symbol: "hrs",
    type: "Time",
  },
  {
    name: "time_days",
    display: "Days (d)",
    symbol: "d",
    type: "Time",
  },
  // Currency
  {
    name: "currency_dollar",
    display: "Dollar ($)",
    symbol: "$",
    type: "Currency",
  },
  {
    name: "currency_pound",
    display: "Pound (£)",
    symbol: "£",
    type: "Currency",
  },
  {
    name: "currency_euro",
    display: "Euro (€)",
    symbol: "€",
    type: "Currency",
  },
  // Acidity / Alkalinity
  {
    name: "acidity_ph",
    display: "pH",
    symbol: "pH ",
    type: "Acidity / Alkalinity",
  },
  // Oil Quality
  {
    name: "oil_quality_tpm",
    display: "Total Polar Matter (%TPM)",
    symbol: "%TPM",
    type: "Oil Quality",
  },
]
export { processFormats }
