import React, { useState, useEffect } from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { makeStyles } from "@material-ui/styles"
import { Button, TextField, Box, List, MenuItem, Divider, Icon, Avatar, Tooltip, Grid } from "@material-ui/core"
import arrayMove from "array-move"
import { Add } from "@material-ui/icons"
import { Trans, t } from "@lingui/macro"
import Config from "react-global-configuration"
import {
  useMutationCreateCategory,
  useMutationCreateKnowledge,
  useMutationUpdateKnowledge,
  useQueryCategories,
  KNOWLEDGE_CONTENT_TYPENAMES,
  KNOWLEDGE_CONTENT_NOTE_TYPE,
  KNOWLEDGE_CONTENT_TYPENAME_LABELS,
} from "../../data"
import { useFormUtils, toId, useMountEffect, useDraft, mapToIds, multipleSelectChange } from "../../utils"

import {
  KnowledgeContentTypeIcon,
  OutlinedSelect,
  DraftBlockquote,
  LocationOutlinedSelect,
  GroupOutlinedSelect,
  Checkbox,
  FieldSectionHeading,
} from ".."
import { CreatorActions, CreatorMaster } from "../Creators"
import { TextDivider } from "../TextDivider"
import IconPicker from "../IconPicker/IconPicker"
import { FlexBox, RowBox } from "../Boxes"
import { useAuth } from "../../services"
import { CreatorContent } from "./Creator/CreatorContent"
import TagsField from "../TextField/TagsField"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  addContentTypeButton: {
    height: 55,
    width: "100%",
  },
  contentTypeIcon: {
    color: theme.palette.text.secondary,
  },
  content: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    fontSize: 14,
  },
  sortableListHelper: {
    zIndex: 99999,
  },
}))

const initialState = {
  title: "",
  category: "",
  isRestrictedToKiosk: false,
  tags: [],
  content: [],
  locations: ["all"],
  groups: ["all"],
}

const initialCategoryState = {
  name: "",
  description: "",
  icon: "category",
}

const SortableContent = SortableElement((props) => <CreatorContent {...props} />)

const SortableListContainer = SortableContainer(
  ({ items, onToggleExpanded, onUpdateContent, onUpdateContentValue, onUpdateContentUploads, onRemoveContent }) => (
    <List component="div">
      {items.map((item, index) => (
        <SortableContent
          key={index}
          index={index}
          contentIndex={index}
          content={item}
          onToggleExpanded={onToggleExpanded}
          onUpdateContent={onUpdateContent}
          onUpdateContentValue={onUpdateContentValue}
          onUpdateContentUploads={onUpdateContentUploads}
          onRemoveContent={onRemoveContent}
        />
      ))}
    </List>
  ),
)

const KnowledgeArticleCreator = ({ open, onCreated, onClose, edit, isInline }) => {
  const classes = useStyles({ xs: isInline })
  const { isValid } = useFormUtils()
  const { hasPermission, hasFeature } = useAuth()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const [createKnowledge] = useMutationCreateKnowledge()
  const [updateKnowledge] = useMutationUpdateKnowledge()
  const [createCategory] = useMutationCreateCategory()
  const { data: categoryData } = useQueryCategories()

  const [title, setTitle] = useState(initialState.title)
  const [category, setCategory] = useState(initialState.category)
  const [groups, setGroups] = useState(initialState.groups)
  const [locations, setLocations] = useState(initialState.locations)
  const [content, setContent] = useState(initialState.content)
  const [isRestrictedToKiosk, setIsRestrictedToKiosk] = useState(initialState.isRestrictedToKiosk)

  const [categoryName, setCategoryName] = useState(initialCategoryState.name)
  const [categoryDescription, setCategoryDescription] = useState(initialCategoryState.description)
  const [categoryIcon, setCategoryIcon] = useState(initialCategoryState.icon)

  const [tags, setTags] = useState([])

  const [draft, setDraft, removeDraft, draftUntouched] = useDraft("knowledge_article_creator", null)

  const [loading, setLoading] = useState(false)

  const isEdit = edit != null

  const showDraft = !isEdit && !!draft && draftUntouched

  useMountEffect(() => {
    if (!edit && draft) {
      draft.title && setTitle(draft.title)
      draft.category && setCategory(draft.category)
      draft.content && setContent(draft.content)
      draft.groups && setGroups(draft.groups)
      draft.locations && setLocations(draft.locations)
      draft.isRestrictedToKiosk && setIsRestrictedToKiosk(draft.isRestrictedToKiosk)

      draft.categoryName && setCategoryName(draft.categoryName)
      draft.categoryDescription && setCategoryDescription(draft.categoryDescription)
      draft.categoryIcon && setCategoryIcon(draft.categoryIcon)

      if (draft?.tags) {
        setTags(draft.tags)
      }
    }
  })

  useEffect(() => {
    if (edit) {
      setTitle(edit.title)
      setCategory(edit.category.id)
      setTags(edit?.tags ?? [])
      setGroups(edit.groups.length ? mapToIds(edit.groups) : ["all"])
      setLocations(edit.locations.length ? mapToIds(edit.locations) : ["all"])

      const collapsedContent = edit.content.map((c) => ({
        ...c,
        expanded: false,
      }))
      setContent([...collapsedContent])
      setIsRestrictedToKiosk(edit.isRestrictedToKiosk)
    }
  }, [edit, setTitle, setCategory, setContent])

  const handleClose = (event, isCancel = true) => {
    handleResetState()
    onClose && onClose(isCancel)
  }

  // NOTE: This can probably be merged with the `handleSubmit` function below
  // as it's not called from anywhere else.
  const handleCreated = (item) => {
    removeDraft()
    onCreated && onCreated(item)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formValid() === false) {
      return
    }

    setLoading(true)

    // For each content type that is going to be saved / created, we need to
    // adjust the data to get it into the right shape before sending it to the
    // backend, this is done here. It's also worth noting that we have a
    // counterpart to this function which controls the default structure of
    // objects when _creating_ new knowledge articles. This is located further
    // down the file.
    const cleanContent = content.map((item) => {
      switch (item.__typename) {
        case KNOWLEDGE_CONTENT_TYPENAMES.TEXT:
          return {
            text: {
              id: item.id,
              heading: item.heading,
              text: item.text,
            },
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.IMAGE:
          return {
            image: {
              id: item.id,
              heading: item.heading,
              uploads: item.uploads.map((upload) => {
                return upload.id
              }),
            },
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.NOTE:
          return {
            note: {
              id: item.id,
              heading: item.heading,
              text: item.text,
              type: item.type,
            },
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.FILES:
          return {
            files: {
              id: item.id,
              heading: item.heading,
              uploads: item.uploads.map((upload) => {
                return upload.id
              }),
            },
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.VIDEO:
          return {
            video: {
              id: item.id,
              heading: item.heading,
              url: item.url,
            },
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE:
          return {
            multichoice: {
              id: item.id,
              heading: item.heading,
              question: item.question,
              answers: item.answers
                .filter((answer) => answer.isCorrect || answer.text.trim())
                .map((answer) => {
                  return {
                    id: answer.id.startsWith("new_") ? null : answer.id,
                    text: answer.text,
                    isCorrect: answer.isCorrect,
                  }
                }),
            },
          }
        default:
          throw new Error(`Unknown type was passed to clean function, was: ${item.__typename}`)
      }
    })

    let newCategory = category

    if (category === "new") {
      const result = await createCategory({
        variables: { name: categoryName, description: categoryDescription, icon: categoryIcon },
      })
      newCategory = result.data.createCategory.id
    }

    const variables = {
      title,
      isRestrictedToKiosk,
      tags: mapToIds(tags),
      content: cleanContent,
      category: newCategory,
      locations: locations.includes("all") ? [] : mapToIds(locations),
      groups: groups.includes("all") ? [] : mapToIds(groups),
    }

    // Are we editing or creating a new knowledge? This affects which mutation
    // will be called on the backend. When creating a new knowledge, we also
    // have some specific logic that must be run after the creation to clean up
    // the draft. This is handled in `handleCreated`.
    if (isEdit) {
      await updateKnowledge({ variables: { id: toId(edit), input: variables } })
      setLoading(false)
      handleClose(e, false)
    } else {
      const result = await createKnowledge({ variables: { input: variables } })
      setLoading(false)
      handleCreated(result.data.createKnowledge)
    }
  }

  const handleChange = (set, name, newValue) => {
    set(newValue)
    setDraft((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  const handleUpdateDraftContent = (newValue) => {
    if (edit) return
    setDraft((prev) => ({
      ...prev,
      content: newValue,
    }))
  }

  const handleDiscardDraft = () => {
    removeDraft()
    handleResetState()
  }

  const handleResetState = () => {
    setTitle(initialState.title)
    setContent(initialState.content)
    setCategory(initialState.category)
    setCategoryName(initialCategoryState.name)
    setCategoryDescription(initialCategoryState.description)
    setCategoryIcon(initialCategoryState.icon)
    setGroups(initialState.groups)
    setLocations(initialState.locations)
    setTags(initialState.tags)
    setIsRestrictedToKiosk(initialState.isRestrictedToKiosk)
  }

  const handleLocationsChanged = (event) => {
    handleChange(setLocations, "locations", [...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (regionLocations) => {
    handleChange(setLocations, "locations", [...mapToIds(regionLocations)])
  }

  const handleGroupsChanged = (event) => {
    handleChange(setGroups, "groups", [...multipleSelectChange(groups, event)])
  }

  const handleTagChanged = (options) => {
    handleChange(setTags, "tags", [...options])
  }

  const formValid = () =>
    (category === "new" ? isValid(categoryName, categoryDescription, categoryIcon) : isValid(category)) &&
    isValid(title, content)

  const handleAddContent = ({ typename }) => {
    // Used as the base for all created knowledge base articles. Note that we
    // start articles in an expanded state.
    const defaults = {
      heading: "",
      expanded: true,
      __typename: typename,
    }

    // Here is where we set specific defaults for each kind of knowledge that
    // is being created. For example, if we wanted to create "note" knowledge
    // types that were of an `IMPORTANT` type instead of `INFORMATION`, this is
    // where we would do it.
    const newContentItem = (() => {
      switch (typename) {
        case KNOWLEDGE_CONTENT_TYPENAMES.TEXT:
          return {
            text: "",
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.IMAGE:
          return {
            uploads: [],
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.NOTE:
          return {
            type: KNOWLEDGE_CONTENT_NOTE_TYPE.INFORMATION,
            text: "",
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.FILES:
          return {
            uploads: [],
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.VIDEO:
          return {
            url: "",
          }
        case KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE:
          return {
            answers: [
              {
                id: "new_0",
                isCorrect: true,
                text: "",
              },
            ],
          }
        default:
          throw new Error(`Attempted to add an unknown content type, was: ${typename}`)
      }
    })()

    setContent((state) => {
      // Collapse all the other possibly open items
      state.forEach((s) => (s.expanded = false))

      // Add the new item (note, this is set to be open in the `default` base
      // variable which gets merged over)
      const result = [...state, { ...defaults, ...newContentItem }]

      handleUpdateDraftContent(result)

      return result
    })
  }
  const handleRemoveContent = (index) => {
    setContent((state) => {
      state.splice(index, 1)
      const result = [...state]
      handleUpdateDraftContent(result)
      return result
    })
  }

  const handleUpdateContent = (index, propName, value) => {
    if (content[index][propName] !== value) {
      const newContent = [...content]
      newContent[index][propName] = value
      setContent(newContent)
      handleUpdateDraftContent(newContent)
    }
  }

  const handleToggleExpanded = (index) => {
    setContent((state) => {
      state[index].expanded = !state[index].expanded
      const result = [...state]
      handleUpdateDraftContent(result)
      return result
    })
  }

  const handleUpdateContentValue = (index, value) => {
    setContent((state) => {
      state[index].value = value
      const result = [...state]
      handleUpdateDraftContent(result)
      return result
    })
  }
  const handleUpdateContentUploads = (index, uploads) => {
    setContent((state) => {
      state[index].uploads = [...uploads]
      const result = [...state]
      handleUpdateDraftContent(result)
      return result
    })
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setContent((prev) => {
      const next = arrayMove(prev, oldIndex, newIndex)
      const result = [...next]
      handleUpdateDraftContent(result)
      return result
    })
  }

  const AddContentTypeButton = ({ tooltip, typename }) => {
    return (
      <Tooltip title={tooltip || KNOWLEDGE_CONTENT_TYPENAME_LABELS[typename]}>
        <Button
          variant="contained"
          disableElevation
          className={classes.addContentTypeButton}
          onClick={() => handleAddContent({ typename })}
          data-cy={`Button-add-content-${typename}`}
        >
          {!isInline && <Add opacity={0.5} />}
          <KnowledgeContentTypeIcon type={typename} />
        </Button>
      </Tooltip>
    )
  }

  const AddContentType = ({ tooltip, typename }) => (
    <Grid item xs={4} sm={3} md={2}>
      <AddContentTypeButton tooltip={tooltip} typename={typename} />
    </Grid>
  )

  const isFormValid = formValid()

  const canCreateCategory = hasPermission("category_create")

  const canKnowledgeUpdateAll = hasPermission("knowledge_update_all")

  const hasLocationsAndGroups = hasFeature("knowledge_access")

  const hasAreas = hasFeature("areas")

  // Only show restricted to kiosk if the user has perrmission knowledge_update_all and it's a kiosk or not a device
  const showRestrictedToKiosk = canKnowledgeUpdateAll && (kiosk || !device)

  const form = (
    <>
      <DraftBlockquote show={showDraft} subject={t`Article`} onDiscard={handleDiscardDraft} />

      <Box>
        <TextField
          variant="outlined"
          fullWidth
          id="title"
          label={<Trans>Article title</Trans>}
          name="title"
          value={title}
          onChange={(event) => handleChange(setTitle, "title", event.target.value)}
          required
          data-cy="TextField-title"
        />
      </Box>

      {hasLocationsAndGroups && (
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocationOutlinedSelect
                value={locations}
                onChange={handleLocationsChanged}
                onRegionChange={handleRegionChange}
                multiple
                data-cy="KnowledgeArticleCreator-locations"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GroupOutlinedSelect
                value={groups}
                onChange={handleGroupsChanged}
                multiple
                data-cy="KnowledgeArticleCreator-groups"
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {showRestrictedToKiosk && (
        <Box mt={2}>
          <Checkbox
            type="label"
            color="primary"
            label={<Trans>Restrict article access to kiosk devices</Trans>}
            checked={isRestrictedToKiosk}
            onChange={(event) => handleChange(setIsRestrictedToKiosk, "isRestrictedToKiosk", event.target.checked)}
          />
        </Box>
      )}

      <Box mt={2}>
        <OutlinedSelect
          label={<Trans>Category</Trans>}
          value={category}
          native={false}
          onChange={(event) => handleChange(setCategory, "category", event.target.value)}
          required
          data-cy="OutlinedSelect-category"
        >
          {canCreateCategory && (
            <MenuItem value="new" data-cy="MenuItem-category-new">
              <RowBox>
                <Icon>add</Icon>
                <Box ml={1}>
                  <Trans>New category...</Trans>
                </Box>
              </RowBox>
            </MenuItem>
          )}
          {canCreateCategory && <Divider />}
          {categoryData?.categories.map(({ id, name, icon }) => (
            <MenuItem key={id} value={id}>
              <RowBox>
                <Icon className="material-icons-outlined">{icon}</Icon>
                <Box ml={1}>{name}</Box>
              </RowBox>
            </MenuItem>
          ))}
        </OutlinedSelect>
      </Box>

      {category === "new" && (
        <Box mt={2}>
          <Divider />
          <Box display="flex" flexDirection="row" mt={2} alignItems="center">
            <Box flexGrow={1}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                label={<Trans>New category name</Trans>}
                name="name"
                value={categoryName}
                onChange={(event) => handleChange(setCategoryName, "categoryName", event.target.value)}
                autoFocus
                required
                data-cy="TextField-category-name"
              />
            </Box>
            <Box>
              <IconPicker
                value={categoryIcon}
                onChange={(value) => handleChange(setCategoryIcon, "categoryIcon", value)}
                selectText={<Trans>Category icon</Trans>}
              >
                <Avatar className={classes.avatar}>
                  <Icon className="material-icons-outlined">{categoryIcon}</Icon>
                </Avatar>
              </IconPicker>
            </Box>
          </Box>
          <Box mt={2} mb={2}>
            <TextField
              variant="outlined"
              fullWidth
              label={<Trans>New category description</Trans>}
              name="description"
              value={categoryDescription}
              onChange={(event) => handleChange(setCategoryDescription, "categoryDescription", event.target.value)}
              multiline
              minRows={3}
              required
              data-cy="TextField-category-description"
            />
          </Box>
          <Divider />
        </Box>
      )}

      {hasAreas && (
        <Box mt={2}>
          <TagsField
            value={tags ?? []}
            label={<Trans>Tags</Trans>}
            onChange={(_, options) => handleTagChanged(options)}
          />
        </Box>
      )}

      <FieldSectionHeading mt={3}>
        <Trans>Article content</Trans>
      </FieldSectionHeading>

      <SortableListContainer
        items={content}
        onToggleExpanded={handleToggleExpanded}
        onUpdateContent={handleUpdateContent}
        onUpdateContentValue={handleUpdateContentValue}
        onUpdateContentUploads={handleUpdateContentUploads}
        onRemoveContent={handleRemoveContent}
        useDragHandle
        onSortEnd={handleSortEnd}
        helperClass={classes.sortableListHelper}
      />

      <TextDivider>
        <Trans>Add content to your article</Trans>
      </TextDivider>

      <FlexBox my={2} justifyContent="center" justifySelf="center">
        <RowBox flexShrink={1}>
          <Grid container spacing={1} justifyContent="center" direction="row" style={{ width: "auto" }}>
            <AddContentType typename={KNOWLEDGE_CONTENT_TYPENAMES.TEXT} />
            <AddContentType typename={KNOWLEDGE_CONTENT_TYPENAMES.IMAGE} />
            <AddContentType
              typename={KNOWLEDGE_CONTENT_TYPENAMES.NOTE}
              tooltip={<Trans>Information, important or warning</Trans>}
            />
            <AddContentType typename={KNOWLEDGE_CONTENT_TYPENAMES.FILES} />
            <AddContentType typename={KNOWLEDGE_CONTENT_TYPENAMES.VIDEO} />
            <AddContentType typename={KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE} />
          </Grid>
        </RowBox>
      </FlexBox>

      <CreatorActions
        id="KnowledgeArticleCreator-CreatorActions"
        subject={t`Article`}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || loading}
        submitLoading={loading}
      />
    </>
  )

  return (
    <CreatorMaster
      id="KnowledgeArticleCreator"
      open={open}
      subject={t`Article`}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { KnowledgeArticleCreator }
