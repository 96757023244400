import React, { useEffect, useState } from "react"
import { List, ListItem, ListItemText, ListItemAvatar, Chip, Box } from "@material-ui/core"
import { useParams, NavLink, useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { Star } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import Config from "react-global-configuration"
import { Content, AreaHeader, LoadingSpinner, Avatar, Icon, TimeAgo, SearchInput, IconMessage } from "../../components"
import { useLazyQuerySearchAll } from "../../data"
import { usePostUtils, useJobUtils, useKnowledgeUtils, toId } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: theme.cards.tableCard,
  item: {
    padding: theme.spacing(1, 2, 1, 2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: theme.palette.action.hover,
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.action.focus,
      outline: "none",
    },
  },
}))

const getResultTypeName = (type) => {
  switch (type) {
    case "Post":
      return "Communications Hub"
    case "Process":
    case "Task":
      return "Jobs"
    case "Knowledge":
      return "Knowledge Base"
    default:
      return ""
  }
}

const Search = () => {
  const { query } = useParams()
  const history = useHistory()
  const [searchText, setSearchText] = useState(null)
  const { location } = useAuth()
  const { clientKiosk: kiosk } = Config.get()
  const [load, { called, loading, data }] = useLazyQuerySearchAll()
  const classes = useStyles()

  const { getNavigateToLink: getPostLink } = usePostUtils()
  const { getNavigateToLinkForTypeLocationDayAndID: getJobLink } = useJobUtils()
  const { getNavigateToArticleLinkForID: getKnowledgeLink } = useKnowledgeUtils()

  const handleSearchChange = (value) => {
    history.push(`/search/${encodeURIComponent(value)}`)
  }

  useEffect(() => {
    if (query) setSearchText(query.trim())
  }, [query])

  useEffect(() => {
    if (searchText) {
      load({ variables: { searchText, location: toId(location), filter: { client: kiosk ? "kiosk" : "" } } })
    }
  }, [load, location, searchText, kiosk])

  return (
    <>
      <AreaHeader title={<Trans>Search</Trans>} titleIcon="search-dark" />

      <Content full start>
        <SearchInput
          initialValue={query}
          debounce={500}
          placeholder="Enter a search term"
          onChange={handleSearchChange}
          autoFocus
        />

        {loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {data && (
          <List disablePadding className={classes.root}>
            {data.searchAll.results.items.map(({ id, title, type, date, score }) => (
              <ListItem
                key={id}
                component={NavLink}
                className={classes.item}
                to={() => {
                  switch (type) {
                    case "Post":
                      return getPostLink({ id })
                    case "Process":
                    case "Task":
                      return getJobLink(type, moment(date).format("YYYY-MM-DD"), toId(location), id)
                    case "Knowledge":
                    case "KnowledgeV2":
                      return getKnowledgeLink(id)
                    default:
                      return ""
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar icon={<Icon name={type} />} />
                </ListItemAvatar>
                <ListItemText
                  primary={title}
                  secondary={
                    <>
                      <TimeAgo date={date} accurate /> - {getResultTypeName(type)}
                    </>
                  }
                />
                <ListItemAvatar>
                  <Chip icon={<Star style={{ fontSize: 12 }} />} label={score.toFixed(2)} />
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        )}
        {searchText && called && !loading && (!data || (data && !data.searchAll.results.total)) && (
          <IconMessage name="empty-search" text="We couldn't find any results." />
        )}
      </Content>
    </>
  )
}

export default Search
