import { useState } from "react"
import fileDownload from "js-file-download"
import axios from "axios"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import Config from "react-global-configuration"
import { Icon, LinkButton, useSnackbar } from "../components"
import { useLazyQueryRetrieveFileFromS3 } from "../data"
import { useDeviceUtils } from "./useDeviceUtils"

const useStyles = makeStyles((theme) => ({
  viewAllLink: {
    color: theme.palette.text.white,
    marginLeft: 10,
  },
}))

export const useDownload = () => {
  const classes = useStyles()
  const theme = useTheme()
  const snackbar = useSnackbar()
  const [isDownloading, setIsDownloading] = useState()
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [retrieveFileFromS3] = useLazyQueryRetrieveFileFromS3()
  const { canPostMessage, postDownloadMessage } = useDeviceUtils()
  const { clientKiosk: kiosk, clientDevice: device, httpBaseUri } = Config.get()

  const downloadFile = async ({ id, fileName, url, contentType, disk = "local" }) => {
    if ((kiosk || device) && !canPostMessage()) {
      snackbar.showMessage({ message: "Please download via desktop browser" })
      return
    }

    setIsDownloading(true)

    try {
      let downloadUrl
      if (disk === "s3") {
        const { data } = await retrieveFileFromS3({ variables: { id } })
        if (data) {
          const { signedUrl } = data.downloads.one
          downloadUrl = signedUrl
        }
      } else {
        downloadUrl = `${httpBaseUri}${url}`
      }

      if (kiosk || device) {
        postDownloadMessage({
          signedDownloadUrl: downloadUrl,
          upload: {
            fileName,
            contentType,
          },
        })
        setIsDownloading(false)
        return
      }

      const response = await axios({
        url: downloadUrl,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

          setDownloadProgress(percentCompleted)
        },
        headers: {
          "Content-Type": contentType,
        },
      })

      fileDownload(response.data, fileName, contentType)

      setTimeout(() => {
        snackbar.showMessage({
          message: (
            <Box>
              PDF download complete{" "}
              <LinkButton component="a" href="/downloads" className={classes.viewAllLink}>
                View all
              </LinkButton>
            </Box>
          ),
          icon: <Icon name="pdf" />,
          autoHideDuration: 5000,
          showCloseButton: true,
          chipProps: {
            style: { backgroundColor: theme.palette.success.main },
          },
        })
      }, 1000)
    } catch (error) {
      setTimeout(() => {
        snackbar.showMessage({
          message: "Unknown error",
          chipProps: {
            style: { backgroundColor: theme.palette.error.main },
          },
        })
      }, 1000)
    } finally {
      setTimeout(() => {
        setIsDownloading(false)
      }, 2000)
    }
  }

  return {
    isDownloading,
    downloadProgress,
    downloadFile,
  }
}
