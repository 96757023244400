import React, { useEffect, useRef } from "react"
import { Box, Divider, Popover, makeStyles } from "@material-ui/core"
import { Caption, FormatDateTimeCompact, DeleteConfirmIconButton, ImagesViewer, DownloadList } from ".."
import { ProcessCollapse } from "./ProcessCollapse"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { Avatar } from "../Avatar"
import { toId, preventDefault } from "../../utils"
import NoteForm from "./NoteForm"

const useStyles = makeStyles((theme) => ({
  popover: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw",
      maxHeight: 300,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 450,
    },
  },
  list: {
    backgroundColor: theme.palette.grey[50],
    maxHeight: 400,
    overflowY: "auto",
    width: "100%",
    flexDirection: "column-reverse",
  },
  divider: {
    width: "100%",
  },
  row: {
    width: "100%",
    cursor: "pointer",
  },
  author: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  formWrapper: {
    width: "100%",
    padding: theme.spacing(1),
  },
  form: {
    width: "100%",
  },
  formUploading: {
    width: "100%",
  },
  text: {
    fontSize: 12,
    wordBreak: "break-word",
    wordWrap: "break-word",
    minWidth: "1%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(0.5),
  },
}))

const DesktopNotesPopup = ({
  open,
  anchorEl,
  onClose,
  hasNotes,
  notes,
  show,
  onShow,
  location,
  showDeleting,
  onDeleting,
  onDelete,
  deleteLoading,
  text,
  onTextChange,
  onFocus,
  onEnter,
  hasNativeCamera,
  step,
  onDeviceUploaded,
  onItemUploaded,
  uploads,
  uploading,
  onRemoveUpload,
  onAdd,
  isValid,
}) => {
  const classes = useStyles()
  const listRef = useRef(null)

  useEffect(() => {
    if (!listRef.current) {
      return
    }

    listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: "smooth" })
  }, [notes])

  const handleClose = (event) => {
    onClose && onClose(event)
  }

  const handleShow = (item) => {
    onShow && onShow(item)
  }

  const handleDeleting = (event) => {
    onDeleting && onDeleting(event)
  }

  const handleDelete = (event) => {
    onDelete && onDelete(event)
  }

  const handleFocus = (event) => {
    onFocus && onFocus(event)
  }

  const handleTextChange = (newText) => {
    onTextChange && onTextChange(newText)
  }

  const handleEnter = (event) => {
    onEnter && onEnter(event)
  }

  const handleDeviceUploaded = (item) => {
    onDeviceUploaded && onDeviceUploaded(item)
  }

  const handleItemUploaded = (item) => {
    onItemUploaded && onItemUploaded(item)
  }

  const handleRemoveUpload = (item) => {
    onRemoveUpload && onRemoveUpload(item)
  }

  const handleAdd = (event) => {
    onAdd && onAdd(event)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <ColumnBox className={classes.popover}>
        {hasNotes && (
          <ColumnBox ref={listRef} className={classes.list} data-cy="AddNoteFooterButton-list">
            {notes.map((item, index) => (
              <React.Fragment key={toId(item)}>
                {index > 0 && <Divider className={classes.divider} />}
                <Box p={1} className={classes.row} onClick={() => handleShow(item)}>
                  <RowBox alignItems="flex-start">
                    <Box mr={1}>
                      <Avatar {...item.author} className={classes.author} />
                    </Box>
                    <RowBox flexGrow={1} className={classes.text}>
                      {item.text}
                    </RowBox>
                    <ColumnBox ml={1} alignItems="flex-end" mr={1}>
                      <ProcessCollapse in={show !== toId(item)}>
                        <Caption mb={0} mt={1}>
                          <FormatDateTimeCompact
                            value={item.createdAt}
                            compactTodayTimeZone={location?.timeZone || null}
                          />
                        </Caption>
                      </ProcessCollapse>
                      <ProcessCollapse in={show === toId(item)}>
                        <FlexBox alignSelf="flex-end" onClick={preventDefault}>
                          <DeleteConfirmIconButton
                            deleting={showDeleting}
                            onDeleting={handleDeleting}
                            onDelete={() => handleDelete(item)}
                            loading={deleteLoading}
                          />
                        </FlexBox>
                      </ProcessCollapse>
                    </ColumnBox>
                  </RowBox>
                  {item.uploads.length > 0 ? (
                    <Box pt={1}>
                      <ImagesViewer uploads={item.uploads} height={80} width={100} />
                      <DownloadList uploads={item.uploads} variant="chips" />
                    </Box>
                  ) : null}
                </Box>
              </React.Fragment>
            ))}
          </ColumnBox>
        )}
        <ColumnBox className={classes.formWrapper}>
          <NoteForm
            text={text}
            onTextChange={handleTextChange}
            onFocus={handleFocus}
            onEnter={handleEnter}
            hasNativeCamera={hasNativeCamera}
            step={step}
            onDeviceUploaded={handleDeviceUploaded}
            onItemUploaded={handleItemUploaded}
            uploads={uploads}
            uploading={uploading}
            onRemoveUpload={handleRemoveUpload}
            onAdd={handleAdd}
            isValid={isValid}
            containerClassName={classes.form}
          />
        </ColumnBox>
      </ColumnBox>
    </Popover>
  )
}

export default DesktopNotesPopup
