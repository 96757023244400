import { useQuery, gql } from "@apollo/client"
import { CALENDAR_FIELDS_FRAGMENT } from "./fragments/calendarFieldsFragment"

const CALENDARS_QUERY = gql`
  query Calendars {
    calendars {
      ...CalendarFields
    }
  }
  ${CALENDAR_FIELDS_FRAGMENT}
`

const useQueryCalendars = (options) => useQuery(CALENDARS_QUERY, options)

export { useQueryCalendars, CALENDARS_QUERY }
