import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Skeleton } from "@material-ui/lab"
import { MentionOutput, DownloadList, Job } from ".."
import { KnowledgeArticle } from "../Knowledge"
import { ImagesViewer } from "../ImagesViewer/ImagesViewer"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: "600",
    paddingTop: theme.spacing(2),
    overflowWrap: "anywhere",
  },
  content: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowWrap: "anywhere",
  },
  mentionItem: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mentionItemTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  mentionItemDetail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
}))

const PostContent = ({ post }) => {
  const classes = useStyles()

  return (
    <Box ml={0} mr={0}>
      {post.title && (
        <Typography variant="h1" className={classes.title}>
          {post.title}
        </Typography>
      )}
      <div className={classes.content}>
        <MentionOutput content={post.content} />
        <ImagesViewer uploads={post.uploads} />
        <DownloadList uploads={post.uploads} variant="cards" mt={1} />
      </div>
      {post.job && <Job job={post.job} />}
      {post.knowledge && <KnowledgeArticle variant="cardlink" knowledge={post.knowledge} />}
    </Box>
  )
}

const PostContentSkeleton = () => {
  const classes = useStyles()

  return (
    <Box ml={1} mr={1}>
      <div className={classes.content}>
        <Skeleton variant="rect" height={25} />
      </div>
    </Box>
  )
}

export default PostContent
export { PostContentSkeleton }
