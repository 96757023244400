import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Tooltip } from "@material-ui/core"
import { SortableHandle } from "react-sortable-hoc"
import { DragIndicator as DragIndicatorIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { preventDefault } from "../../utils"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  dragIcon: {
    color: theme.palette.text.secondary,
    paddingLeft: 0,
    marginLeft: -7,
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
    margin: 0,
    padding: 0,
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    margin: 0,
    padding: 0,
  },
  unset: {
    color: theme.palette.text.secondary,
  },
}))

const DragHandleActive = SortableHandle(({ className }) => <DragIndicatorIcon className={className} />)

const DragHandle = ({ expanded, className }) =>
  expanded ? <DragIndicatorIcon className={className} /> : <DragHandleActive className={className} />

const ExpansionPanelTitle = ({ icon, classes, title, placeholders, description }) => {
  const internalClasses = useStyles()
  return (
    <>
      {icon && (
        <Box className={classes && classes.icon} display="flex" mr={0.5}>
          {icon}
        </Box>
      )}
      <Box display="flex" alignItems="center" className={classes && classes.summary}>
        <Box flexDirection="column">
          {(title || (placeholders && placeholders.title)) && (
            <Typography variant="h1" className={`${internalClasses.title} ${!title && internalClasses.unset}`}>
              {title || (placeholders && placeholders.title)}
            </Typography>
          )}
          {(description || (placeholders && placeholders.description)) && (
            <Typography
              variant="subtitle1"
              className={`${internalClasses.description} ${!description && internalClasses.unset}`}
            >
              {description || (placeholders && placeholders.description)}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}

const ExpansionPanel = ({
  icon,
  title,
  description,
  children,
  chips,
  sortable,
  sortableTitle = "",
  placeholders,
  expansionClasses,
  expansionSummaryClasses,
  expansionDetailsClasses,
  classes,
  expanded,
  elevation = 0,
  ...rest
}) => {
  const internalClasses = useStyles()

  return (
    <Accordion elevation={elevation} expanded={expanded} classes={expansionClasses} {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={expansionSummaryClasses}>
        <RowBox flexGrow={1} alignItems="center">
          {sortable && (
            <Tooltip title={sortableTitle || "Sort order"}>
              <Box onClick={expanded ? null : preventDefault}>
                <DragHandle expanded={expanded} className={internalClasses.dragIcon} />
              </Box>
            </Tooltip>
          )}
          <ExpansionPanelTitle
            icon={icon}
            title={title}
            classes={classes}
            placeholders={placeholders}
            description={description}
          />
          <RowBox ml="auto">{chips}</RowBox>
        </RowBox>
      </AccordionSummary>
      <AccordionDetails className={classes && classes.details} classes={expansionDetailsClasses}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export { ExpansionPanel, ExpansionPanelTitle }
