import React from "react"
import { TextField } from "@material-ui/core"
import { ProtectedTextField } from "../TextField/ProtectedOutlinedTextField"
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"
import Checkbox from "../Checkbox"
import { IntegrationAttributeAutocompleteComponent } from "./IntegrationAttributeAutocompleteComponent"
import { ReadOnlyOutlinedInput } from "../TextField"

const IntegrationAttributeComponent = ({ type, integration, location, attribute, value, onChange }) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue)
  }

  const handleAutocompleteChange = (newValue) => {
    onChange && onChange(newValue?.key || "")
  }

  if (attribute.protect) {
    return (
      <ProtectedTextField
        label={attribute.name}
        placeholder="Protected item, set value only"
        value={value}
        fullWidth
        onChange={(event) => handleChange(event.target.value)}
      />
    )
  }

  if (attribute.component === "role") {
    return (
      <RoleOutlinedSelect
        label={attribute.name}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        fullWidth
      />
    )
  }

  if (attribute.component === "checkbox") {
    return (
      <Checkbox
        type="label"
        label={attribute.name}
        checked={value === "true"}
        onChange={(event) => onChange(event.target.checked.toString())}
      />
    )
  }

  if (attribute.dataSource) {
    return (
      <IntegrationAttributeAutocompleteComponent
        type={type}
        integration={integration}
        location={location}
        attribute={attribute}
        value={value}
        onChange={handleAutocompleteChange}
      />
    )
  }

  if (attribute.component === "hidden") {
    return null
  }

  if (attribute.component === "readonly") {
    return <ReadOnlyOutlinedInput label={attribute.name} value={value} fullWidth labelWidth={100} />
  }

  return (
    <TextField
      label={attribute.name}
      value={value}
      variant="outlined"
      placeholder="Not set"
      fullWidth
      onChange={(event) => handleChange(event.target.value)}
    />
  )
}

export { IntegrationAttributeComponent }
