import React, { useState } from "react"
import { Box, Collapse, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { SelectorMaster, LoadingSpinner, Icon } from ".."
import { toId, useMountEffect } from "../../utils"
import { PRINTER_TYPE, PRINTER_TYPE_LABELS } from "../../data"
import { SelectionBadge } from "../Badges/SelectionBadge"
import { useProbes } from "../../utils/useProbes"
import { PROCESS_STEP_FORMAT_UNIT } from "../../data/enums"
import { RequireDeviceClientVersion } from "../VersionCheck/RequireDeviceClientVersion"

const { OIL_QUALITY_TPM } = PROCESS_STEP_FORMAT_UNIT

const ProbeSelectorListItem = ({ probe, selected, onSelect }) => {
  const handleSelect = () => {
    onSelect && onSelect(probe)
  }

  const name = probe.name !== probe.address ? probe.name : PRINTER_TYPE_LABELS[probe.type]

  return (
    <Collapse in>
      <ListItem key={toId(probe)} button onClick={handleSelect}>
        <ListItemAvatar>
          <SelectionBadge status={selected ? "selected" : "none"}>
            <Icon name="probe" fontSize="large" />
          </SelectionBadge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {name || "Unknown Probe"}
              {selected ? " (active)" : ""}
            </>
          }
          secondary={probe.address}
          onClick={handleSelect}
          style={{ cursor: "pointer" }}
        />
      </ListItem>
    </Collapse>
  )
}

const ProbeSelector = ({ open, onClose, format, minimumDeviceClientVersion }) => {
  const { probe, probes, oilQualityTester, oilQualityTesters, findProbes, finding, selectProbe } = useProbes({ format })
  const [mode, setMode] = useState("list")
  const [newProbe, setNewProbe] = useState(null)

  const probeToUse = format === OIL_QUALITY_TPM ? oilQualityTester : probe
  const probesToUse = format === OIL_QUALITY_TPM ? oilQualityTesters : probes

  useMountEffect(() => {
    findProbes()
  })

  const handleChange = (newValue) => {
    if (newValue.type === PRINTER_TYPE.UNKNOWN_BLUETOOTH) {
      setNewProbe({ ...newValue })
      setMode("manual")
      return
    }
    selectProbe(newValue)
    onClose && onClose()
  }

  const handleSelect = async () => {
    if (mode === "manual") {
      if (newProbe.name && newProbe.address && newProbe.type) {
        selectProbe(newProbe)
        onClose && onClose()
      }
    } else {
      findProbes()
    }
  }

  const handleCancel = () => {
    if (mode === "manual") {
      setMode("list")
    } else {
      onClose && onClose()
    }
  }

  const hasItems = Boolean(probesToUse.length)

  const form = (
    <RequireDeviceClientVersion minimumVersion={minimumDeviceClientVersion}>
      <Box mb={2}>
        <Collapse in={mode === "list" && finding}>
          <List disablePadding>
            <ListItem>
              <ListItemAvatar>
                <LoadingSpinner size={40} />
              </ListItemAvatar>
              <ListItemText
                primary="Finding probes"
                secondary="Ensure your probe is on and in discovery mode if required"
              />
            </ListItem>
          </List>
        </Collapse>
        <Collapse in={mode === "list" && !finding}>
          <List disablePadding>
            {probesToUse.map((item) => (
              <ProbeSelectorListItem
                key={toId(item)}
                probe={item}
                selected={item.address === probeToUse?.address}
                onSelect={handleChange}
              />
            ))}
            {!hasItems && !finding && (
              <ListItem>
                <ListItemAvatar>
                  <Icon name="probe-disabled" fontSize="large" />
                </ListItemAvatar>
                <ListItemText
                  primary="No probes could be found"
                  secondary="Ensure you have paired the probe through your Bluetooth settings, that it is on, and in discovery mode if required"
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      </Box>
    </RequireDeviceClientVersion>
  )

  return (
    <SelectorMaster
      open={open}
      title="Probes"
      maxWidth="sm"
      form={form}
      selectText={mode === "manual" ? "Add probe" : "Refresh"}
      selectVariant={mode === "manual" ? "button" : "link"}
      onSelect={handleSelect}
      selectDisabled={mode === "manual" || finding}
      cancelText={mode === "manual" ? <Trans>Back</Trans> : <Trans>Cancel</Trans>}
      onCancel={handleCancel}
    />
  )
}

export { ProbeSelector }
