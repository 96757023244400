import React, { Fragment, useMemo, useRef, useState } from "react"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core"
import useAsyncEffect from "use-async-effect"
import { Autocomplete } from "@material-ui/lab"
import { RowBox } from "../Boxes"
import { Caption, FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"
import { toId, useDebounce } from "../../utils"
import { useMutationIntegrationSetLocationAttribute, useQueryLocations } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { useQueryIntegrationLocationAttributeValues } from "../../data/integrations/useQueryIntegration"

const useAttributeListItemStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  text: {
    padding: 4,
  },
}))

const LocationAttributeListItem = ({ integration, location, attribute, onChange }) => {
  const mounted = useRef(false)
  const classes = useAttributeListItemStyles()
  const { data, loading } = useQueryIntegrationLocationAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: !attribute.dataSource,
  })
  const [setAttribute] = useMutationIntegrationSetLocationAttribute()
  const [value, setValue] = useState(
    location?.attributes.find((item) => toId(item.integration) === toId(integration) && item.key === attribute.key)
      ?.value || "",
  )
  const debouncedValue = useDebounce(value, 300)

  useAsyncEffect(async () => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    await setAttribute({
      variables: {
        id: toId(integration),
        location: toId(location),
        key: attribute.key,
        value: debouncedValue.toString(),
      },
    })
    onChange && onChange()
  }, [debouncedValue, attribute])

  const handleChange = async (_, newValue) => {
    setValue(newValue?.key || "")
  }

  const optionValue = useMemo(
    () => data?.integration?.locationAttributeValues.find((item) => item.key === value) || null,
    [data, value],
  )

  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListItemAvatar classes={{ root: classes.avatar }}>
        <RowBox>
          <Icon name="integration-location" />
        </RowBox>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ component: "div" }}
        primary={
          <RowBox alignContent="center">
            <Grid container alignContent="center">
              <Grid item xs={6}>
                <FieldSectionHeading mb={0}>
                  <Tooltip title={toId(location)}>
                    <span>{location.name}</span>
                  </Tooltip>
                </FieldSectionHeading>
                <Caption mb={0}>{attribute.description}</Caption>
              </Grid>
              <Grid item xs={6}>
                {attribute.dataSource && (
                  <Autocomplete
                    value={optionValue}
                    autoHighlight
                    getOptionLabel={(option) => `${option.value} (#${option.key})`}
                    getOptionSelected={(option) => option.key === optionValue.key}
                    options={data?.integration?.locationAttributeValues || []}
                    loading={loading}
                    disabled={loading}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={attribute.name}
                        variant="outlined"
                        placeholder="Not set"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <LoadingSpinner size={24} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {!attribute.dataSource && (
                  <TextField
                    label={attribute.name}
                    variant="outlined"
                    placeholder="Not set"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                )}
              </Grid>
            </Grid>
          </RowBox>
        }
      />
    </ListItem>
  )
}

const IntegrationLocationConfiguration = ({ integration }) => {
  const { data } = useQueryLocations()
  const {
    integrationProvider: { name, attributes },
  } = integration

  const items = data?.locations || []

  return (
    <Box m={1}>
      <Caption>The following {name} attributes are mapped to your Operandio locations.</Caption>

      {items.map((location, itemIndex) => (
        <Fragment key={toId(location)}>
          {itemIndex > 0 && <Divider />}
          <List disablePadding>
            {attributes.location.map((attribute, attributeIndex) => (
              <Fragment key={attribute.key}>
                {attributeIndex > 0 && <Divider />}
                <LocationAttributeListItem integration={integration} location={location} attribute={attribute} />
              </Fragment>
            ))}
          </List>
        </Fragment>
      ))}
      {!data && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
    </Box>
  )
}

export { IntegrationLocationConfiguration }
