import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { SENSOR_FIELDS } from "./fragments/sensorFieldsFragment"
import { toId } from "../../utils"

const SENSORS_QUERY = gql`
  query Sensors($location: ID!) {
    sensors(location: $location) {
      ...SensorFields
    }
  }
  ${SENSOR_FIELDS}
`

const useQuerySensors = (options) => useQuery(SENSORS_QUERY, options)

const useLazyQuerySensors = (options) => useLazyQuery(SENSORS_QUERY, options)

// Gets a single sensor from the latest readings cache
const useQuerySensorLatest = (id, location) => {
  const { data } = useQuery(SENSORS_QUERY, {
    variables: { location: toId(location, true) },
    skip: !location || !id,
    fetchPolicy: "cache-only",
  })

  const sensor = data?.sensors?.find((item) => item.id === id)

  return { sensor }
}

export { useQuerySensors, useLazyQuerySensors, useQuerySensorLatest, SENSOR_FIELDS }
