import * as Sentry from "@sentry/browser"
import { makeVar } from "@apollo/client"
import { v4 as uuid } from "uuid"
import { useState, useCallback } from "react"
import moment from "moment"
import { DEVICE_POSTMESSAGE_TYPE, PROBE_TYPE, PROCESS_STEP_FORMAT_UNIT } from "../data"
import { useDeviceUtils } from "./useDeviceUtils"
import { useMountEffect } from "./useMountEffect"
import { useMountAwareReactiveVar } from "./useMountAwareReactiveVar"
import { getLocalStorageItem, useLocalStorage } from "./useLocalStorage"

const { ETI_BLUETOOTH_LE, OIL_QUALITY_TESTER } = PROBE_TYPE
const { TEMPERATURE_FAHRENHEIT, OIL_QUALITY_TPM } = PROCESS_STEP_FORMAT_UNIT

const probesVar = makeVar([])
const findingProbesVar = makeVar(false)

// init probe from local storage
const probeLocalStorageKey = "probe"
const storedProbeJson = getLocalStorageItem(probeLocalStorageKey)
const probeVar = makeVar(storedProbeJson ? JSON.parse(storedProbeJson) : null)

// init oil quality tester from local storage
const oilQualityTestersVar = makeVar([])
const findingOilQualityTestersVar = makeVar(false)
const oilQualityTesterLocalStorageKey = "oil_quality_tester"
const storedOilQualityTesterJson = getLocalStorageItem(oilQualityTesterLocalStorageKey)
const oilQualityTesterVar = makeVar(storedOilQualityTesterJson ? JSON.parse(storedOilQualityTesterJson) : null)

const dummyLoadingMs = 1000

let dummyValueInterval = null

const useProbes = (options = {}) => {
  const { onRecord = null, format = null } = options
  const { postFindProbesMessage, postStartReadingProbeValueMessage, postStopReadingProbeValueMessage, canPostMessage } =
    useDeviceUtils()
  const [, setLocalStorage] = useLocalStorage(probeLocalStorageKey, null)
  const probes = useMountAwareReactiveVar(probesVar)
  const probe = useMountAwareReactiveVar(probeVar)
  const oilQualityTesters = useMountAwareReactiveVar(oilQualityTestersVar)
  const oilQualityTester = useMountAwareReactiveVar(oilQualityTesterVar)
  const finding = useMountAwareReactiveVar(findingProbesVar)
  const [value, setValue] = useState(null)

  const handleConversionCallback = useCallback(
    (readValue) => {
      if (format === TEMPERATURE_FAHRENHEIT) {
        return ((readValue * 9) / 5 + 32).toFixed(1)
      }

      return readValue
    },
    [format],
  )

  useMountEffect(() => () => clearInterval(dummyValueInterval))

  // For Oil Quality Testers
  useMountEffect(() => {
    function handleOilQualityTesterList(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.OIL_QUALITY_TESTER_LIST) {
          oilQualityTestersVar([
            ...message.payload.reduce((acc, item) => {
              // ensure unique on address
              if (!acc.find((accItem) => accItem.address === item.address)) {
                acc.push(item)
              }
              return acc
            }, []),
          ])
          findingOilQualityTestersVar(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleOilQualityTesterList)

    // remove listener
    return () => {
      window.removeEventListener("deviceMessage", handleOilQualityTesterList)
    }
  })

  // For Probes
  useMountEffect(() => {
    function handleProbesList(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.PROBE_LIST) {
          probesVar([
            ...message.payload.reduce((acc, item) => {
              // ensure unique on address
              if (!acc.find((accItem) => accItem.address === item.address)) {
                acc.push(item)
              }
              return acc
            }, []),
          ])
          findingProbesVar(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleProbesList)

    // remove listener
    return () => {
      window.removeEventListener("deviceMessage", handleProbesList)
    }
  })

  useMountEffect(() => {
    function handleProbeValue(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.PROBE_VALUE) {
          setValue(message.payload)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleProbeValue)

    // remove listener
    return () => {
      window.removeEventListener("deviceMessage", handleProbeValue)
    }
  })

  // Listen to ping from the device button
  useMountEffect(() => {
    function handleProbeButton(event) {
      const message = event.detail
      try {
        if (!message?.type) {
          return
        }

        // return response to device
        if (message.type === DEVICE_POSTMESSAGE_TYPE.PROBE_BUTTON) {
          setValue(message.payload)
          if (onRecord && typeof onRecord === "function") {
            const convertedValue = handleConversionCallback(message.payload?.value)
            onRecord(null, null, convertedValue)
          }
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleProbeButton)

    // remove listener
    return () => window.removeEventListener("deviceMessage", handleProbeButton)
  })

  const makeDummyProbe = () => {
    const random = Math.random()
    const displayRandom = Math.round(random * 100)

    const name = format !== OIL_QUALITY_TPM ? "BlueTherm One" : "Oil Quality Sensor"
    const type = format !== OIL_QUALITY_TPM ? ETI_BLUETOOTH_LE : OIL_QUALITY_TESTER

    return {
      id: uuid(),
      name: `${name} ${displayRandom}`,
      realName: `${name}${displayRandom}`,
      address: `1A:2B:3C:4D:5E:6F:${displayRandom}`,
      type,
    }
  }

  const triggerProbeFinding = (state = false) => {
    if (format === OIL_QUALITY_TPM) {
      findingOilQualityTestersVar(state)
    } else {
      findingProbesVar(state)
    }
  }

  const saveProbesValue = (list) => {
    if (format === OIL_QUALITY_TPM) {
      oilQualityTestersVar(list)
      return
    }
    probesVar(list)
  }

  const saveProbeValue = (obj) => {
    if (format === OIL_QUALITY_TPM) {
      oilQualityTesterVar(obj)
      return
    }
    probeVar(obj)
  }

  const findProbes = async (payload) => {
    triggerProbeFinding(true)
    if (!canPostMessage()) {
      // simulate find probes for testing
      setTimeout(() => {
        // random result
        const random = Math.random()
        if (random <= 0.7) {
          saveProbesValue([
            ...Array(Math.round(Math.random() * 4))
              .fill()
              .map(makeDummyProbe),
          ])
        } else {
          saveProbesValue([])
        }
        triggerProbeFinding(false)
      }, dummyLoadingMs)
      return
    }
    postFindProbesMessage({ ...payload, format })
  }

  const startReadingValue = () => {
    if (!canPostMessage()) {
      // simulate reading value for testing
      dummyValueInterval = setInterval(() => {
        const fullValue = Number(Math.random() * 100)
        setValue({
          value: fullValue.toFixed(1),
          fullValue,
          unit:
            format === OIL_QUALITY_TPM
              ? PROCESS_STEP_FORMAT_UNIT.OIL_QUALITY_TPM
              : PROCESS_STEP_FORMAT_UNIT.TEMPERATURE_CELSIUS,
          at: moment().format(),
          device: { ...(format === OIL_QUALITY_TPM ? oilQualityTester : probe) },
        })
      }, dummyLoadingMs)
      return
    }

    postStartReadingProbeValueMessage({
      probe: format === OIL_QUALITY_TPM ? oilQualityTester : probe,
      format,
    })
  }

  const stopReadingValue = () => {
    if (!canPostMessage()) {
      clearInterval(dummyValueInterval)
      setValue(null)
      return
    }

    postStopReadingProbeValueMessage()
  }

  const selectProbe = (item) => {
    if (!item) {
      return
    }

    saveProbeValue({ ...item })
    setLocalStorage({ ...item })
  }

  return {
    probes,
    probe,
    oilQualityTesters,
    oilQualityTester,
    finding,
    value,

    startReadingValue,
    stopReadingValue,
    findProbes,
    selectProbe,

    handleConversionCallback,
  }
}

export { useProbes }
