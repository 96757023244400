import { Box, Grid, TextField } from "@material-ui/core"
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons"
import { RowBox, TimePicker } from ".."
import { RemoveIconButton } from "../Buttons"

const FoodItemLimitCreator = ({
  limit,
  loading = false,
  onChange,
  onDelete,
  timePickerProps = {},
  useStandardCoolingProfile = false,
}) => {
  const handleChange = (prop, val) => {
    onChange && onChange(prop, val)
  }

  const handleDelete = () => {
    onDelete && onDelete()
  }
  const handleChangeElapse = (value) => {
    if (useStandardCoolingProfile) return
    const elapseTime = {
      hour: value?.hours() ?? 0,
      minute: value?.minutes() ?? 0,
    }

    handleChange("elapsedTime", elapseTime)
  }

  return (
    <Box mb={2}>
      <RowBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TimePicker
              required
              value={limit.elapsedTime}
              onChange={handleChangeElapse}
              label="Elapsed time"
              clearLabel={false}
              minutesStep={1}
              data-cy="Timepicker-elapsedTime"
              format="HH:mm"
              ampm={false}
              {...timePickerProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              id="minTemperature"
              label="Min temperature"
              name="minTemperature"
              value={limit.minTemperature}
              onChange={(e) => handleChange("minTemperature", Number(e.target.value))}
              data-cy="TextField-minTemperature"
              required
              disabled={useStandardCoolingProfile}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              id="maxTemperature"
              label="Max temperature"
              name="maxTemperature"
              value={limit.maxTemperature}
              onChange={(e) => handleChange("maxTemperature", Number(e.target.value))}
              data-cy="TextField-maxTemperature"
              required
              disabled={useStandardCoolingProfile}
            />
          </Grid>
        </Grid>
        <RemoveIconButton
          onConfirmedClick={() => handleDelete()}
          subject="limit"
          disabled={loading || useStandardCoolingProfile}
        >
          <DeleteOutlineIcon />
        </RemoveIconButton>
      </RowBox>
    </Box>
  )
}

export { FoodItemLimitCreator }
