import React, { useEffect, useState } from "react"
import {
  Divider,
  Box,
  Paper,
  Typography,
  Breadcrumbs,
  ListItem,
  ListItemText,
  List,
  Link,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import Config from "react-global-configuration"
import { Alert, AlertTitle } from "@material-ui/lab"
import { KNOWLEDGE_CONTENT_TYPENAMES, KNOWLEDGE_CONTENT_NOTE_TYPE } from "../../data"
import { toId, useTraining } from "../../utils"
import {
  AuthorTools,
  Avatar,
  TimeAgo,
  SubNavigation,
  AdornmentIcon,
  TooltipIcon,
  ExpansionPanel,
  NoItemsMessage,
} from ".."
import { KnowledgeActionCard } from "../ActionCards"
import { CopyLinkButton, QRButton } from "../Buttons"
import { RowBox } from "../Boxes"
import { ArticleContent } from "./Article/ArticleContent"
import { TrainingArticleFooter, TrainingArticleHeader } from "../Training"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: 1,
    cursor: "pointer",
  },
  paper: {
    "&:hover": theme.cards.hoverCard,
    "&:focus": theme.cards.hoverCard,
  },
  authorPanel: {
    backgroundColor: theme.palette.grey[25],
  },
  authorContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: "24px",
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "28px",
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  description: {
    color: theme.palette.text.primary,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  author: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  footer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  lastUpdated: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    padding: theme.spacing(0.25, 2, 0.25, 2),
    color: theme.palette.primary.main,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    fontWeight: "600",
    "&:hover": {
      background: theme.palette.primary.backgroundDark,
      borderRadius: "4px",
      textDecoration: "none",
    },
    "&:focus": {
      background: theme.palette.primary.backgroundDark,
      borderRadius: "4px",
      textDecoration: "none",
    },
  },
  listItemAvatar: {
    minWidth: theme.spacing(4),
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "600 !important",
    textDecoration: "none",
  },
  submenu: {
    padding: `${theme.spacing(2)}px !important`,
    maxHeight: "calc(100vh - 160px)",
    overflowY: "auto",
  },
  submenuTitle: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: "24px",
    marginLeft: theme.spacing(2),
  },
  by: {
    color: theme.palette.text.secondary,
  },
  trainingIcon: {
    display: "flex",
    flexDirection: "row",
  },
  listToc: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    borderRadius: `0 0 4px 4px`,
  },
  listItemTocExpansion: {
    padding: theme.spacing(0, 2),
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}))

const useExpansionStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
  },
}))

const useExpansionSummaryStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
}))

const useExpansionDetailsClasses = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
}))

const KnowledgeArticle = ({ knowledge, variant, onEdit, onDelete, onCopy }) => {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down("sm"))
  const { clientKiosk: kiosk } = Config.get()
  const { getActiveTrainingForKnowledge, getTrainingForKnowledge, modules: trainingModules } = useTraining()
  const { isCurrentUser, hasPermission, hasFeature } = useAuth()
  const [hasTrainingCourse, setHasTrainingCourse] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const linear = !hasFeature("training_nonlinear")
  const classes = useStyles()
  const expansionClasses = useExpansionStyles()
  const expansionSummaryClasses = useExpansionSummaryStyles()
  const expansionDetailsClasses = useExpansionDetailsClasses()

  const { id, title, category, content, groups, locations, author, createdAt, updatedAt } = knowledge

  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const training = linear ? getActiveTrainingForKnowledge(knowledge) : getTrainingForKnowledge(knowledge)
  const hasUserTraining = training && (training.knowledgeActive || training.knowledgeCompleted)

  // Configures a map of all articles that will require training to be able to
  // continue through this course.
  const defaultCompletedArticles =
    content?.reduce((acc, contentItem) => {
      // Currently only multichoice questions require interaction.
      if (contentItem.__typename === KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE) {
        acc[contentItem.id] = false
      }

      return acc
    }, {}) || []

  const [completedArticles, setCompletedArticles] = useState(defaultCompletedArticles)

  const canViewMultipleChoicesQuestion = () => {
    const hasMultichoiceQuestion = content.some((c) => c.__typename === KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE)
    if (!hasMultichoiceQuestion) return false

    const isUser = isCurrentUser(author)
    const editPermission = hasPermission("knowledge_update_all")
    const trainingCreatePermission = hasPermission("training_create")

    return editPermission || trainingCreatePermission || isUser || hasUserTraining
  }

  useEffect(() => {
    if (hasUserTraining) {
      setCompletedArticles((prev) => {
        training.moduleKnowledge.answers.forEach((answer) => {
          prev[answer.knowledgeContent] = true
        })
        return prev
      })
    }
  }, [hasUserTraining, training])

  useEffect(() => {
    if (!knowledge || !trainingModules?.length) {
      return
    }
    setHasTrainingCourse(
      trainingModules.some((trainingModule) =>
        trainingModule.knowledges.some((item) => toId(item) === toId(knowledge)),
      ),
    )
  }, [trainingModules, knowledge])

  const scrollTo = (event, to) => {
    event.preventDefault()
    const offset = document.querySelector("header").clientHeight + 20
    const pos = document.getElementById(to).getBoundingClientRect().top + document.documentElement.scrollTop
    window.scrollTo({
      top: pos - offset,
      behavior: "smooth",
    })
  }

  const ArticleAuthorTools = () => (
    <AuthorTools
      item={knowledge}
      author={knowledge.author.id}
      subject={t`article`}
      admins={{ edit: "knowledge_update_all", delete: "knowledge_delete_all" }}
      onEdit={onEdit}
      onDelete={onDelete}
      onCopy={onCopy}
    />
  )

  /**
   * First when some articles have been "completed", that is, the user has
   * answered a multichoice question. This could also be extended to other
   * types of interaction, i.e. watching a video to completion.
   *
   * @param {*} content The article that was completed
   */
  const handleArticleCompleted = (contentItem) => {
    setCompletedArticles({
      ...completedArticles,
      [contentItem.id]: true,
    })
  }

  // Build a list of article ids that still require completion before we can
  // allow training to progress. This is fed into the footer element to allow
  // it to display what needs to be done next
  const articlesRequiringCompletion = Object.entries(completedArticles).reduce((acc, [completedArticleId, status]) => {
    if (status === false) {
      acc.push(completedArticleId)
      return acc
    }

    return acc
  }, [])

  const hasGroupsOrLocations = Boolean(groups?.length) || Boolean(locations?.length)

  const hasRestrictedToKiosk = knowledge.isRestrictedToKiosk && !kiosk && !hasPermission("knowledge_update_all")

  const restrictedMessage = (
    <NoItemsMessage>
      <Trans>
        This article is restricted to kiosk devices for your permission level.
        <br />
        Please access it on a kiosk device to view.
      </Trans>
    </NoItemsMessage>
  )

  if (variant === "submenu") {
    return (
      <SubNavigation className={classes.submenu} mr={fullWidth ? 0 : 6}>
        <Typography variant="h2" className={classes.submenuTitle}>
          <span className="notranslate">
            <Trans>Contents</Trans>
          </span>
        </Typography>
        <List>
          {content
            .filter(
              (c) =>
                c.heading &&
                ![
                  KNOWLEDGE_CONTENT_NOTE_TYPE.INFORMATION,
                  KNOWLEDGE_CONTENT_NOTE_TYPE.DANGER,
                  KNOWLEDGE_CONTENT_NOTE_TYPE.IMPORTANT,
                ].includes(c.type),
            )
            .map((c) => (
              <ListItem
                key={toId(c)}
                component={Link}
                href={`#${c.id}`}
                onClick={(event) => scrollTo(event, toId(c))}
                classes={{ root: classes.listItem }}
              >
                <ListItemText>
                  <Typography className={classes.listItemText}>{c.heading}</Typography>
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </SubNavigation>
    )
  }

  if (variant === "peek") {
    if (hasRestrictedToKiosk) {
      return restrictedMessage
    }
    return (
      <Box>
        {content.map((c) => (
          <ArticleContent key={c.id} knowledge={knowledge} id={c.id} content={c} />
        ))}
      </Box>
    )
  }

  if (variant === "full") {
    if (hasRestrictedToKiosk) {
      return restrictedMessage
    }
    return (
      <Box data-cy="KnowledgeArticle-full" id="knowledge">
        <Box mb={xs ? 2 : 3}>
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <Box>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
            </Box>
            <Box ml="auto" display="flex" alignItems="center" flexDirection="row">
              <QRButton value={window.location.href} />
              <CopyLinkButton />
              <ArticleAuthorTools />
            </Box>
          </Box>
        </Box>
        {!hasUserTraining && (
          <Box mb={xs ? 2 : 4} data-cy="KnowledgeArticle-breadcrumbs">
            <Breadcrumbs>
              <NavLink to="/knowledge">
                <span className="notranslate">
                  <Trans>Knowledge Base</Trans>
                </span>
              </NavLink>
              <NavLink to={`/knowledge/category/${category.id}`}>{category.name}</NavLink>
              <Box>{title}</Box>
            </Breadcrumbs>
          </Box>
        )}
        {!hasUserTraining && (
          <Box mb={smDown ? 1 : 3}>
            <Paper elevation={0} className={classes.authorPanel}>
              <RowBox className={classes.authorContent}>
                <Box display="flex" alignItems="center">
                  <Avatar {...author} />
                  <Box ml={1} className={classes.by}>
                    <span className="notranslate">
                      <Trans>By</Trans> {author.firstName} {author.lastName} | <TimeAgo date={updatedAt || createdAt} />{" "}
                    </span>
                  </Box>
                </Box>
                {(hasGroupsOrLocations || hasTrainingCourse || knowledge.isRestrictedToKiosk) && (
                  <Box className={classes.icons}>
                    {hasGroupsOrLocations && (
                      <TooltipIcon
                        name="restricted"
                        refClassName={classes.trainingIcon}
                        tooltip={<Trans>Restricted to locations and/or areas of work</Trans>}
                      />
                    )}
                    {hasTrainingCourse && (
                      <TooltipIcon
                        name="training"
                        refClassName={classes.trainingIcon}
                        tooltip={<Trans>Article is used as training</Trans>}
                      />
                    )}
                    {knowledge.isRestrictedToKiosk && (
                      <TooltipIcon
                        name="phone-link-lock"
                        refClassName={classes.trainingIcon}
                        tooltip={<Trans>Restricted to access on kiosk devices</Trans>}
                      />
                    )}
                  </Box>
                )}
              </RowBox>
            </Paper>
          </Box>
        )}
        {hasUserTraining && (
          <Box mb={3}>
            <TrainingArticleHeader {...training} />
          </Box>
        )}

        {!hasUserTraining && canViewMultipleChoicesQuestion() && (
          <Box pb={3}>
            <Alert severity="info">
              <AlertTitle>
                <Trans>Quiz questions are shown in this article</Trans>
              </AlertTitle>
              <Trans>
                These are not visible to staff unless they form part of their training. Only managers with elevated
                training permissions can view these quiz questions outside of a training course.
              </Trans>
            </Alert>
          </Box>
        )}

        <Hidden mdUp>
          <Box mb={3}>
            <ExpansionPanel
              title={<Trans>Contents</Trans>}
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
              expansionClasses={expansionClasses}
              expansionSummaryClasses={expansionSummaryClasses}
              expansionDetailsClasses={expansionDetailsClasses}
            >
              <List className={classes.listToc}>
                {content
                  .filter(
                    (c) =>
                      c.heading &&
                      ![
                        KNOWLEDGE_CONTENT_NOTE_TYPE.INFORMATION,
                        KNOWLEDGE_CONTENT_NOTE_TYPE.DANGER,
                        KNOWLEDGE_CONTENT_NOTE_TYPE.IMPORTANT,
                      ].includes(c.type),
                  )
                  .map((c) => (
                    <ListItem
                      key={toId(c)}
                      component={Link}
                      href={`#${c.id}`}
                      onClick={(event) => scrollTo(event, toId(c))}
                      classes={{ root: [classes.listItem, classes.listItemTocExpansion].join(" ") }}
                    >
                      <ListItemText>
                        <Typography className={classes.listItemText}>{c.heading}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
            </ExpansionPanel>
          </Box>
        </Hidden>
        {content.map((c) => (
          <ArticleContent
            key={c.id}
            knowledge={knowledge}
            id={c.id}
            content={c}
            onArticleCompleted={() => handleArticleCompleted(c)}
            canViewMultipleChoicesQuestion={canViewMultipleChoicesQuestion()}
          />
        ))}
        {hasUserTraining && (
          <Box mb={10}>
            <TrainingArticleFooter
              {...training}
              articlesRequiringCompletion={articlesRequiringCompletion}
              content={content}
            />
          </Box>
        )}
      </Box>
    )
  }

  if (variant === "cardlink") {
    return <KnowledgeActionCard knowledge={knowledge} />
  }

  return (
    <Box className={classes.root} mb={xs ? 2 : 3}>
      <Paper elevation={0} className={classes.paper}>
        <Box p={2} display="flex" flexDirection="row">
          <Box component={NavLink} to={`/knowledge/article/${id}`} display="flex" flexDirection="row" flexGrow={1}>
            <Box>
              <Typography variant="h4" className={classes.cardTitle}>
                {title}
              </Typography>
              <Box className={classes.author}>
                {author.firstName} {author.lastName}
              </Box>
            </Box>
          </Box>
          <Box ml="auto">
            <ArticleAuthorTools />
          </Box>
        </Box>
        <Divider />

        <RowBox className={classes.footer} alignItems="center" component={NavLink} to={`/knowledge/article/${id}`}>
          {hasGroupsOrLocations && (
            <Box mr={1}>
              <AdornmentIcon name="restricted" tooltip={<Trans>Restricted to locations and/or areas of work</Trans>} />
            </Box>
          )}
          {hasTrainingCourse && (
            <Box mr={1}>
              <AdornmentIcon name="training" tooltip={<Trans>Article is used as training</Trans>} />
            </Box>
          )}
          {knowledge.isRestrictedToKiosk && (
            <Box>
              <AdornmentIcon name="phone-link-lock" tooltip={<Trans>Restricted to access on kiosk devices</Trans>} />
            </Box>
          )}
          <Box flexGrow={1} />
          <RowBox className={classes.lastUpdated}>
            {updatedAt !== createdAt && (
              <Box mr={1}>
                <Trans>Updated</Trans> <TimeAgo date={updatedAt} />
              </Box>
            )}
            {updatedAt === createdAt && (
              <Box mr={1}>
                <Trans>Created</Trans> <TimeAgo date={createdAt} />
              </Box>
            )}
            <Avatar {...author} />
          </RowBox>
        </RowBox>
      </Paper>
    </Box>
  )
}

export { KnowledgeArticle }
