import { gql } from "@apollo/client"

const CALENDAR_FIELDS_FRAGMENT = gql`
  fragment CalendarFields on Calendar {
    id
    url
    locations {
      id
      name
    }
    groups {
      id
      name
    }
    schedules
    createdAt
    updatedAt
  }
`

export { CALENDAR_FIELDS_FRAGMENT }
