import React from "react"
import { Chip, makeStyles } from "@material-ui/core"
import { TEMPERATURE_TIMER_STATUS, TEMPERATURE_TIMER_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.status.resolved,
  },
}))

const TemperatureInRangeChip = ({
  label = TEMPERATURE_TIMER_STATUS_LABELS[TEMPERATURE_TIMER_STATUS.IN_RANGE],
  className,
}) => {
  const classes = useStyles()

  return <Chip label={label} classes={classes} className={className} size="small" />
}

export { TemperatureInRangeChip }
