import React from "react"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import { Box, makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useDeviceUtils } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
  },
}))

const DeviceClientUpdateRequiredAlert = () => {
  const classes = useStyles()
  const { isOS } = useDeviceUtils()

  const messageiOS = <Trans>Tap here to update your app through the App Store.</Trans>
  const messageAndroid = <Trans>Tap here to update your app through the Play Store.</Trans>

  const message = (() => {
    if (isOS("ios")) return messageiOS
    if (isOS("android")) return messageAndroid
    return <Trans>Click here to update your app.</Trans>
  })()

  const handleClick = (e) => {
    e.preventDefault()
    let link = ""
    if (isOS("ios")) link = Config.get("linkDownloadApp.ios")
    if (isOS("android")) link = Config.get("linkDownloadApp.android")
    if (link) window.open(link, "_blank")
  }

  return (
    <Box onClick={handleClick} mb={2} className={classes.root}>
      <Alert severity="info">
        <Box mb={1}>An app update is required to enable this feature.</Box>
        <Box>{message}</Box>
      </Alert>
    </Box>
  )
}

export { DeviceClientUpdateRequiredAlert }
