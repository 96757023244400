import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1004_2460)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 4H26V2C26 1.45 25.55 1 25 1C24.45 1 24 1.45 24 2V4H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V4H3C2.45 4 2 4.45 2 5V29C2 29.55 2.45 30 3 30H29C29.55 30 30 29.55 30 29V5C30 4.45 29.55 4 29 4ZM6 6V8C6 8.55 6.45 9 7 9C7.55 9 8 8.55 8 8V6H24V8C24 8.55 24.45 9 25 9C25.55 9 26 8.55 26 8V6H28V12H4V6H6ZM4 28V14H28V28H4Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 18C6 17.45 6.45 17 7 17H10C10.55 17 11 17.45 11 18C11 18.55 10.55 19 10 19H7C6.45 19 6 18.55 6 18Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18C21 17.45 21.45 17 22 17H25C25.55 17 26 17.45 26 18C26 18.55 25.55 19 25 19H22C21.45 19 21 18.55 21 18Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 18C13.5 17.45 13.95 17 14.5 17H17.5C18.05 17 18.5 17.45 18.5 18C18.5 18.55 18.05 19 17.5 19H14.5C13.95 19 13.5 18.55 13.5 18Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 24C6 23.45 6.45 23 7 23H10C10.55 23 11 23.45 11 24C11 24.55 10.55 25 10 25H7C6.45 25 6 24.55 6 24Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 24C21 23.45 21.45 23 22 23H25C25.55 23 26 23.45 26 24C26 24.55 25.55 25 25 25H22C21.45 25 21 24.55 21 24Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 24C13.5 23.45 13.95 23 14.5 23H17.5C18.05 23 18.5 23.45 18.5 24C18.5 24.55 18.05 25 17.5 25H14.5C13.95 25 13.5 24.55 13.5 24Z"
      fill={primary}
    />
    <path
      d="M20 10H12C11.45 10 11 9.55 11 9C11 8.45 11.45 8 12 8H20C20.55 8 21 8.45 21 9C21 9.55 20.55 10 20 10Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_1004_2460" x1="11" y1="9" x2="21" y2="9" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
