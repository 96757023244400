import { Box, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import moment from "moment-timezone"
import { RowBox } from "../Boxes"
import { FormatDateCompact, FormatDateTimeCompact } from "../Format"
import { Avatar } from "./Avatar"

const useStyles = makeStyles((theme) => ({
  completedByDate: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  completedByAvatar: {
    width: 30,
    height: 30,
    fontSize: 12,
  },
}))

const CompletedByAvatar = ({ at, by, time = true, forceTime = false, text = true, timeZone, ...rest }) => {
  const classes = useStyles()

  return (
    <RowBox {...rest}>
      <Hidden xsDown={!forceTime}>
        {text && (
          <Box mr={by ? 1 : 0} className={classes.completedByDate} title={moment(at).local().format()}>
            {time && <FormatDateTimeCompact value={at} timeZone={timeZone} />}
            {!time && <FormatDateCompact value={at} timeZone={timeZone} />}
          </Box>
        )}
      </Hidden>
      {by && <Avatar {...by} className={classes.completedByAvatar} />}
    </RowBox>
  )
}

export { CompletedByAvatar }
