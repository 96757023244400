import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1408_2432)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 19C15.45 19 15 18.55 15 18V7C15 6.45 15.45 6 16 6C16.55 6 17 6.45 17 7V18C17 18.55 16.55 19 16 19Z"
      fill={secondary}
    />
    <path
      d="M15.85 26.09C15.61 26.09 15.37 26 15.18 25.83L7.33 18.74C7.02 18.46 6.92 18.02 7.07 17.64C7.22 17.25 7.59 17 8 17H11V3C11 2.45 11.45 2 12 2C12.55 2 13 2.45 13 3V18C13 18.55 12.55 19 12 19H10.6L15.86 23.76L21.32 19H19.99C19.44 19 18.99 18.55 18.99 18V11C18.99 10.45 19.44 10 19.99 10C20.54 10 20.99 10.45 20.99 11V17H23.99C24.41 17 24.78 17.26 24.93 17.65C25.08 18.04 24.96 18.48 24.65 18.75L16.5 25.84C16.31 26 16.08 26.09 15.84 26.09H15.85Z"
      fill={primary}
    />
    <path
      d="M6 30C4.35 30 3 28.66 3 27V23C3 22.45 3.45 22 4 22C4.55 22 5 22.45 5 23V27C5 27.55 5.45 28 6 28H26.01C26.56 28 27.01 27.55 27.01 27V23C27.01 22.45 27.46 22 28.01 22C28.56 22 29.01 22.45 29.01 23V27C29.01 28.65 27.67 30 26.01 30H6Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_1408_2432" x1="17" y1="6" x2="17" y2="19" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)
export default Icon
