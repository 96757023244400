import React, { forwardRef, memo } from "react"
import { Box, useTheme } from "@material-ui/core"

const RowBox = forwardRef(function RowBox({ alignItems = "center", gap, children, ...rest }, ref) {
  const theme = useTheme()

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="row"
      alignItems={alignItems}
      sx={{ gap: gap ? theme.spacing(gap) : 0 }}
      {...rest}
    >
      {children}
    </Box>
  )
})

const RowBoxMemo = memo(RowBox)

export { RowBoxMemo as RowBox }
