import { useQuery, gql } from "@apollo/client"

const SENSOR_ALERTS_QUERY = gql`
  query SensorAlerts($id: ID!) {
    sensorAlerts(id: $id) {
      event_type
      event_data {
        timestamp
        hardware_id
        payload {
          type
          value
        }
      }
      device {
        thing_name
        sensor_use
      }
    }
  }
`

const useQuerySensorAlerts = (options) => useQuery(SENSOR_ALERTS_QUERY, options)

export { useQuerySensorAlerts }
