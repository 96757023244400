import { useQuery, gql } from "@apollo/client"

const SENSORS_ALERTS_QUERY = gql`
  query SensorsAlerts($location: ID!) {
    sensorsAlerts(location: $location) {
      event_type
      event_data {
        timestamp
        hardware_id
        payload {
          type
          value
        }
      }
      device {
        thing_name
        sensor_use
      }
    }
  }
`

const useQuerySensorsAlerts = (options) => useQuery(SENSORS_ALERTS_QUERY, options)

export { useQuerySensorsAlerts }
