import { makeStyles, List } from "@material-ui/core"
import { Trans } from "@lingui/macro"

import DownloadListItem from "./DownloadListItem"
import { NoItemsMessage } from "../Lists"
import { ColumnBox } from "../Boxes"
import { LoadingSpinner } from "../LoadingSpinner"
import { ErrorBoundary } from "../ErrorBoundary"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"

const useStyles = makeStyles((theme) => ({
  root: theme.cards.tableCard,
  empty: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
}))

const DownloadList = ({ popover = true, downloadsQuery, shouldVirtualize = false, limit = 10, spinnerSize = 30 }) => {
  const classes = useStyles()
  const { loading, data } = downloadsQuery

  const renderItems = () => {
    if (!data) return null

    const sortedDownloads = [...data.downloads.list].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    const visibleDownloads = limit ? sortedDownloads.slice(0, limit) : sortedDownloads

    if (visibleDownloads.length === 0) {
      return (
        <NoItemsMessage className={classes.empty}>
          <Trans>You don't currently have any downloads</Trans>
        </NoItemsMessage>
      )
    }

    if (shouldVirtualize) {
      return (
        <List disablePadding className={!popover ? classes.root : null}>
          <ListWindowVirtualizer
            items={visibleDownloads}
            itemContent={(index, download) => {
              return (
                <DownloadListItem
                  key={download.id}
                  download={download}
                  popover={popover}
                  isLastItem={index === visibleDownloads.length - 1}
                />
              )
            }}
          />
        </List>
      )
    }

    return (
      <List disablePadding className={!popover ? classes.root : null}>
        {visibleDownloads.map((download) => (
          <DownloadListItem popover={popover} key={download.id} download={download} />
        ))}
      </List>
    )
  }

  if (loading) {
    return (
      <ColumnBox justifyContent="center" alignItems="center" height="100%">
        <LoadingSpinner size={spinnerSize} />
      </ColumnBox>
    )
  }

  return <ErrorBoundary>{renderItems()}</ErrorBoundary>
}

export default DownloadList
