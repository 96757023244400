import { gql } from "@apollo/client"

const DOWNLOAD_FIELDS = gql`
  fragment DownloadFields on Download {
    id
    name
    fileName
    path
    disk
    contentType
    fileSize
    status
    failAt
    overdueAt
    isOverdue @client
    completedAt
    createdAt
    updatedAt
  }
`

export { DOWNLOAD_FIELDS }
