import { gql } from "@apollo/client"
import moment from "moment-timezone"
import { scheduleDateToIsoDateString } from "../../../utils"

const PREP_LIST_ITEM_FIELDS = gql`
  fragment PrepListItemFields on PrepListItem {
    id
    foodItem {
      id
      name
    }
    countOnHand
    countPrepped
    salesYield
    numberOfUnits
    minimumStock
    unitDescription
    rounding
    lastUpdatedAt
    lastUpdatedBy {
      firstName
      lastName
      avatar {
        key
      }
    }
  }
`

const PREP_LIST_FIELDS = gql`
  fragment PrepListFields on PrepList {
    id
    name
    location {
      id
      timeZone
    }
    day {
      year
      month
      day
    }
    lockAfter {
      hour
      minute
    }
    lockAfterAt @client
    isLocked @client
    isTemplate
    templateLocations {
      id
      name
    }
    items {
      ...PrepListItemFields
    }
    childPrepListCount
    salesForecast
    author {
      id
    }
    createdAt
  }
  ${PREP_LIST_ITEM_FIELDS}
`

const prepListTypePolicies = {
  root: {},
  scoped: {
    PrepList: {
      fields: {
        lockAfterAt: {
          read(_, { readField, cache }) {
            const day = readField("day")
            const lockAfter = readField("lockAfter")
            const locationRef = readField("location")

            if (!day || !lockAfter || !locationRef) {
              return null
            }

            const location = cache.readFragment({
              id: locationRef.__ref,
              fragment: gql`
                fragment LocationFields on Location {
                  timeZone
                }
              `,
            })

            if (!location) {
              return null
            }

            return moment.tz(scheduleDateToIsoDateString({ ...day, ...lockAfter }), location.timeZone).toISOString()
          },
        },
        isLocked: {
          read(_, { readField }) {
            const lockAfterAt = readField("lockAfterAt")

            if (!lockAfterAt) {
              return false
            }

            return moment().isAfter(lockAfterAt)
          },
        },
      },
    },
  },
}

export { PREP_LIST_FIELDS, PREP_LIST_ITEM_FIELDS, prepListTypePolicies }
