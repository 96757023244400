import { gql } from "@apollo/client"

const RESPONSE_TEMPERATURE_TIMER_FIELDS = gql`
  fragment ResponseTemperatureTimerFields on JobStepResponseTemperatureTimer {
    at
    value
    isInRange
  }
`

export { RESPONSE_TEMPERATURE_TIMER_FIELDS }
