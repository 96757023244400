import { gql } from "@apollo/client"
import { LOCATION_FIELDS } from "../../locations"

const FOOD_ITEM_FIELDS = gql`
  fragment FoodItemFields on FoodItem {
    id
    name
    description
    useStandardCoolingProfile
    coolingProfile {
      format {
        decimalPlaces
        unit
      }
      items {
        id
        elapsedTime {
          hour
          minute
        }
        maxTemperature
        minTemperature
      }
    }
    locations {
      ...LocationFields
    }
  }
  ${LOCATION_FIELDS}
`

export { FOOD_ITEM_FIELDS }
