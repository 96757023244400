import { useState, useMemo } from "react"

import {
  FormControl,
  Divider,
  Collapse,
  Box,
  makeStyles,
  Grid,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
} from "@material-ui/core"
import PropTypes from "prop-types"
import moment from "moment"
import clsx from "clsx"
import { RowBox } from "../Boxes"
import { toId } from "../../utils"
import { jobStepTypes } from "../../types"
import SelectionField from "../TextField/SelectionField"
import { Icon } from "../Icon"
import {
  makeJobStepStartStopOptimisticResponse,
  PROCESS_STEP_SELECTION_SOURCES,
  useMakeJobStepOptimistic,
  useMutationJobStepComplete,
  useMutationJobStepSetSensors,
  useMutationJobStepStart,
  useMutationJobStepStop,
} from "../../data"
import { IntervalRenderer, useSnackbar } from ".."
import SelectionPeekDialog from "./SelectionPeekDialog"
import { SelectedSensorButton } from "../Sensors/SelectedSensorButton"
import { StepContentFrame } from "../JobViewer/StepContentFrame"
import { FormatDateTimeCompact } from "../Format"
import { TemperatureOutOfRangeChip } from "../Chips/TemperatureOutOfRangeChip"
import { TemperatureInRangeChip } from "../Chips/TemperatureInRangeChip"
import { FormatSymbol } from "../Format/FormatSymbol"
import { useQuerySensor } from "../../data/sensors/useQuerySensor"

const useStyles = makeStyles((theme) => ({
  selection: {
    padding: theme.spacing(2, 2, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  links: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  link: {
    cursor: "pointer",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 16,
    marginRight: 2,
    marginTop: -3,
  },
  description: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    width: 160,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: "100%",
    },
  },
  firstCell: {
    paddingLeft: 0,
  },
  status: {
    "&&": {
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
  },
  lastCell: {
    textAlign: "right",
    width: 100,
    whiteSpace: "nowrap",
    "&&": {
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
  },
  textSize: {
    fontSize: 14,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}))

const TemperatureTimerStep = ({
  job,
  step,
  expand,
  collapseTimeout = "auto",
  classes,
  completed = false,
  isPreview = false,
  location,
}) => {
  const xs = useMediaQuery((theme) => theme.breakpoints.down("xs"))
  const selectionClasses = useStyles()
  const selection = {
    selectFrom: PROCESS_STEP_SELECTION_SOURCES.FOOD_ITEMS,
  }
  const [open, setOpen] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState({})
  const [foodItem, setFoodItem] = useState(null)
  const [setSensors] = useMutationJobStepSetSensors()
  const [starting, setStarting] = useState(false)
  const [stopping, setStopping] = useState(false)
  const [jobStepStart] = useMutationJobStepStart(job, step)
  const [jobStepStop] = useMutationJobStepStop(job, step)
  const [completeJobStep] = useMutationJobStepComplete(job, step)
  const { makeJobStepCompleteOptimisticResponse } = useMakeJobStepOptimistic()
  const snackbar = useSnackbar()

  const startingOrStopping = starting || stopping

  // Hooks to get sensor data
  const queryVariables = useMemo(() => {
    const hasSensor = step?.responseSensors?.length > 0
    const sensorId = hasSensor ? step.responseSensors[0] : null

    return {
      variables: {
        id: sensorId,
        gt: new Date(Date.now() - 24 * 60 * 60 * 1000),
        lt: new Date(),
      },
      skip: !sensorId,
    }
  }, [step?.responseSensors]) // Only recalculate if responseSensors changes
  const { data: sensorData, loading: sensorLoading } = useQuerySensor(queryVariables)
  const sensor = sensorData?.sensor

  const makeOptimisticResponse = (variables) =>
    makeJobStepCompleteOptimisticResponse({
      ...variables,
      job,
    })

  const isTemperatureInRange = (temperature, profile) => {
    if (!temperature) return null
    return temperature >= profile?.minTemperature && temperature <= profile?.maxTemperature
  }

  const handleFoodItemChange = async (event, option) => {
    if (!isPreview) {
      setFoodItem(option?.item)

      const foodItemId = toId(option)

      const variables = {
        job: toId(job),
        step: toId(step),
        skip: false,
        complete: false,
        responseSelections: option
          ? {
              ids: [foodItemId],
              selectedFrom: "foodItem",
            }
          : null,
      }

      await completeJobStep({
        variables,
        optimisticResponse: makeOptimisticResponse({
          ...variables,
          compactResponseSelections: [option],
        }),
      })
    }
  }

  const handleStart = async () => {
    setStarting(true)
    const jobId = toId(job)
    const stepId = toId(step)

    try {
      const variables = {
        job: jobId,
        step: stepId,
      }
      await jobStepStart({
        variables,
        optimisticResponse: makeJobStepStartStopOptimisticResponse(variables, true),
      })
    } catch (error) {
      console.log(error)
      snackbar.showMessage({
        message: "Failed to start recording",
        severity: "error",
      })
    } finally {
      setStarting(false)
    }
  }

  const handleStop = async () => {
    setStopping(true)
    const jobId = toId(job)
    const stepId = toId(step)

    try {
      const variables = {
        job: jobId,
        step: stepId,
      }
      await jobStepStop({
        variables,
        optimisticResponse: makeJobStepStartStopOptimisticResponse(variables, false),
      })
    } catch (error) {
      snackbar.showMessage({
        message: "Failed to stop recording",
        severity: "error",
      })
    } finally {
      setStopping(false)
    }
  }

  const handleSelect = (selectedSensor) => {
    setSensors({
      variables: {
        job: toId(job),
        step: toId(step),
        input: {
          sensors: [toId(selectedSensor)],
        },
      },
    })
  }

  const handleCloseSelectionPeekDialog = () => {
    setOpen(false)
    setSelectedItemId()
  }

  const handleToggleRecording = async () => {
    if (step?.startedAt) {
      await handleStop()
      return
    }

    await handleStart()
  }

  // TODO: Re-enable this if we want to have a manual submit recording button
  // const handleCompletedChange = async () => {
  //   const variables = {
  //     ...step,
  //     job: toId(job),
  //     step: toId(step),
  //     complete: true,
  //   }

  //   await completeJobStep({
  //     variables,
  //     optimisticResponse: makeOptimisticResponse(variables),
  //   })
  // }

  const StartRecordingButton = ({ disabled, ...props }) => (
    <Button
      variant="contained"
      className={classes.startRecordingButton}
      color={step?.startedAt ? "default" : "primary"}
      {...props}
      disabled={disabled || starting || stopping}
    >
      <Icon name="timer" />
      <Box ml={1}>
        {!startingOrStopping && step?.startedAt && "Reset record"}
        {!startingOrStopping && !step?.startedAt && "Start recording"}
        {starting && "Starting..."}
        {stopping && "Resetting..."}
      </Box>
    </Button>
  )

  const canStart = !!foodItem
  const isRunning = !!step?.startedAt

  return (
    <Collapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />

      <Box className={selectionClasses.selection}>
        <RowBox alignItems="start">
          <FormControl variant="outlined" fullWidth>
            <SelectionField
              selection={selection}
              value={foodItem}
              onChange={handleFoodItemChange}
              disabled={(foodItem && completed) || isRunning}
              isPreview={isPreview}
              location={location}
              label="Food item"
              placeholder="Select food item"
              defaultValue={step?.compactResponseSelections?.length > 0 ? step.compactResponseSelections[0] : null}
              defaultValueAction={setFoodItem}
            />
          </FormControl>
        </RowBox>
      </Box>

      <StepContentFrame>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm="auto">
            <SelectedSensorButton
              onSelect={handleSelect}
              sensor={sensor}
              loading={sensorLoading}
              disabled={isRunning}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Grid container spacing={1}>
              <Grid item sm="auto" xs={6}>
                <StartRecordingButton disabled={!canStart} onClick={handleToggleRecording} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StepContentFrame>

      {foodItem?.coolingProfile?.items?.length > 0 && Boolean(step?.startedAt) && (
        <Box px={2} pb={2}>
          <Box mb={2}>
            <IntervalRenderer delay={1000 * 60}>
              {() => (
                <Table size="small">
                  <TableBody>
                    {foodItem?.coolingProfile?.items?.map((item, index) => {
                      const allSamples = step?.responseTemperatureTimer || []
                      const items = foodItem?.coolingProfile?.items
                      const nextItem = index < items.length - 1 ? items[index + 1] : null

                      const startTime = step?.startedAt
                      const elapsedTime = item.elapsedTime
                      const duration = elapsedTime.hour * 60 + elapsedTime.minute
                      const time = moment(startTime).add(duration, "minutes")

                      const endTime = nextItem
                        ? moment(startTime).add(nextItem.elapsedTime.hour * 60 + nextItem.elapsedTime.minute, "minutes")
                        : null

                      let samplesInTimeBand = []
                      if (endTime) {
                        samplesInTimeBand = allSamples.filter((sample) => moment(sample.at).isBetween(time, endTime))
                      }

                      const formattedTime = moment(elapsedTime).format("HH:mm")
                      const isStarted = moment().isAfter(time)

                      let isFinished = true
                      if (nextItem) {
                        const nextItemTime = moment(startTime).add(
                          nextItem.elapsedTime.hour * 60 + nextItem.elapsedTime.minute,
                          "minutes",
                        )
                        isFinished = moment().isAfter(nextItemTime)
                      }

                      const hasReading = samplesInTimeBand.length > 0
                      const hasNextItem = !!nextItem
                      const hasSingleReading = samplesInTimeBand.length === 1
                      const hasMultipleReadings = samplesInTimeBand.length > 1
                      const isInRange = samplesInTimeBand.every((sample) => isTemperatureInRange(sample.value, item))

                      const result = (
                        <>
                          {isStarted && !isFinished && "In progress..."}
                          {isStarted && isFinished && hasNextItem && !hasReading && "No result"}
                          {isFinished && hasReading && time.format("MMM YY, h:mmA")}
                          {!isStarted && !!nextItem && "Pending"}
                          {isStarted && !nextItem && "Completed"}
                        </>
                      )

                      const maxTemperature = Math.max(...samplesInTimeBand.map((sample) => sample.value))
                      const minTemperature = Math.min(...samplesInTimeBand.map((sample) => sample.value))

                      return (
                        <TableRow key={index}>
                          <TableCell className={selectionClasses.firstCell}>
                            <Box
                              display="flex"
                              alignItems="start"
                              flexDirection="column"
                              className={selectionClasses.textSize}
                            >
                              <strong>{formattedTime}</strong>
                              {xs && <Box className={selectionClasses.description}>{result}</Box>}
                            </Box>
                          </TableCell>
                          {!xs && <TableCell className={selectionClasses.description}>{result}</TableCell>}
                          <TableCell align="right" className={selectionClasses.status}>
                            {isStarted && hasReading && isInRange && (
                              <TemperatureInRangeChip className={selectionClasses.textSize} />
                            )}
                            {isStarted && hasReading && !isInRange && (
                              <TemperatureOutOfRangeChip className={selectionClasses.textSize} />
                            )}
                          </TableCell>
                          <TableCell
                            className={clsx(selectionClasses.textSize, selectionClasses.lastCell)}
                            align="right"
                          >
                            {isStarted && hasReading && (
                              <>
                                {hasMultipleReadings && (
                                  <>
                                    {maxTemperature}
                                    <FormatSymbol format={foodItem.coolingProfile.format} /> to {minTemperature}
                                    <FormatSymbol format={foodItem.coolingProfile.format} />
                                  </>
                                )}
                                {hasSingleReading && (
                                  <>
                                    {maxTemperature}
                                    <FormatSymbol format={foodItem.coolingProfile.format} />
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              )}
            </IntervalRenderer>
          </Box>
          <Box>
            Recording started{" "}
            <strong>{step?.startedAt ? <FormatDateTimeCompact value={moment(step?.startedAt)} /> : "soon"}</strong>, for{" "}
            <strong>{foodItem?.name}</strong>
          </Box>
        </Box>
      )}

      {/* Manual Submit Row */}
      {/* {!step?.completedAt && (
        <Box>
          <Divider />
          <Box px={2} py={1} display="flex" justifyContent="space-between" alignItems="center">
            <strong>
              <Trans>Submit recording?</Trans>
            </strong>

            <IconButton
              onClick={handleCompletedChange}
              // disabled={!value || !valid || isScheduled || hasRequirements}
              className={classes.incompletedButton}
              data-cy="ProcessStep-field-temperature-timer-complete"
            >
              <DoneIcon />
            </IconButton>
          </Box>
        </Box>
      )} */}

      {open && selectedItemId && (
        <SelectionPeekDialog
          id={selectedItemId}
          source={PROCESS_STEP_SELECTION_SOURCES.FOOD_ITEMS}
          open={open}
          onClose={handleCloseSelectionPeekDialog}
        />
      )}
    </Collapse>
  )
}

TemperatureTimerStep.propTypes = {
  step: jobStepTypes,
  expand: PropTypes.bool,
  collapseTimeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  completed: PropTypes.bool,
  isPreview: PropTypes.bool,
}

export default TemperatureTimerStep
