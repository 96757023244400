import React from "react"
import { Button, makeStyles } from "@material-ui/core"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    height: "100%",
    backgroundColor: ({ active, disabled }) =>
      disabled ? theme.palette.action.disabledBackground : active ? theme.palette.primary.main : "inherit",
    lineHeight: "inherit",
    "&&:hover": {
      backgroundColor: ({ active, disabled }) =>
        disabled
          ? theme.palette.action.disabledBackground
          : active
            ? theme.palette.primary.main
            : theme.palette.primary.backgroundMed,
    },
    "& .MuiButton-root": {
      height: "100%",
      backgroundColor: "transparent",
      borderRadius: "inherit",
    },
    "& .MuiButton-root.Mui-disabled": {
      backgroundColor: "transparent",
    },
    "& .MuiButton-label": {
      height: "100%",
    },
  },
  content: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 16,
    fontWeight: "normal",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.spacing(0.5),
    color: ({ active, disabled }) =>
      disabled ? theme.palette.text.disabled : active ? theme.palette.text.white : theme.palette.text.primary,
  },
  description: {
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: "normal",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    color: ({ active, disabled }) =>
      disabled ? theme.palette.text.disabled : active ? theme.palette.text.white : theme.palette.text.secondary,
  },
}))

const TileButton = ({ title, description, active, onClick, cy = "TileButton", disabled = false, ...rest }) => {
  const classes = useStyles({ active, disabled })

  return (
    <PaperBox
      component={Button}
      className={classes.root}
      color="primary"
      onClick={onClick}
      data-cy={cy}
      fullWidth
      disabled={disabled}
      {...rest}
    >
      <ColumnBox
        className={classes.content}
        px={1}
        pt={1.25}
        pb={1.75}
        justifySelf="flex-start"
        alignItems="flex-start"
      >
        <RowBox className={classes.title}>{title}</RowBox>
        <RowBox className={classes.description}>{description}</RowBox>
      </ColumnBox>
    </PaperBox>
  )
}

export { TileButton }
