import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { RowBox } from "../Boxes"
import { NumberDisplay } from "../Charts"
import { convertCelsius } from "../../utils"

const useStyles = makeStyles((theme) => ({
  paperWarning: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  display: {
    gap: theme.spacing(2),
    width: "100%",
  },
  reading: {
    flexBasis: "100%",
    maxWidth: "min-content",
    minWidth: "30%",
  },
  value: {
    fontSize: ({ xs }) => (xs ? 24 : 30),
    fontWeight: "600",
    lineHeight: ({ xs }) => (xs ? "24px" : "30px"),
  },
  channelLabel: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 500,
    marginBottom: theme.spacing(0.75),
  },
  scale: {
    color: theme.palette.text.faint,
    fontSize: ({ xs }) => (xs ? 16 : 18),
    lineHeight: ({ xs }) => (xs ? "16px" : "18px"),
    paddingTop: theme.spacing(0.25),
  },
  at: {},
  detail: {
    position: "sticky",
    top: 80,
  },
  sensorIcon: {
    color: theme.palette.text.primary,
  },
  days: {
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "-2px",
  },
  warning: {
    color: "red",
    fontWeight: "600",
  },
  iconBtn: {
    cursor: "pointer",
  },
  menu: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fullscreen: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    overflowY: "auto",
  },
  fullscreenContent: {
    background: "white",
    borderRadius: 10,
    padding: theme.spacing(2),
  },
}))

// TODO: probeTemperature should be null if not available, otherwise falsy value 0 will be problematic
const SensorChannelNumberDisplay = ({ probeTemperature, mainTemperature, displayUnit, humidity, footer }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  return (
    <NumberDisplay
      alignItems="flex-start"
      justifyContent="flex-end"
      width={xs ? "100%" : "inherit"}
      value={
        <RowBox justifyContent="flex-start" alignItems="flex-start" className={classes.display}>
          <Box className={classes.reading}>
            <Box className={classes.channelLabel}>
              {probeTemperature ? <Trans>Probe Temp.</Trans> : <Trans>Temperature</Trans>}
            </Box>
            <RowBox alignItems="flex-start" className={classes.value}>
              {convertCelsius(mainTemperature, displayUnit)?.toFixed(1) ?? "--"}
              <sup className={classes.scale}>{displayUnit === "f" ? "°F" : "°C"}</sup>
            </RowBox>
          </Box>

          {!!probeTemperature && (
            <Box className={classes.reading}>
              <Box className={classes.channelLabel}>
                <Trans>Temperature</Trans>
              </Box>
              <RowBox alignItems="flex-start" className={classes.value}>
                {convertCelsius(probeTemperature, displayUnit)?.toFixed(1)}
                <sup className={classes.scale}>{displayUnit === "f" ? "°F" : "°C"}</sup>
              </RowBox>
            </Box>
          )}

          {humidity && (
            <Box className={classes.reading}>
              <Box className={classes.channelLabel}>
                <Trans>Humidity</Trans>
              </Box>
              <RowBox alignItems="flex-start" className={classes.value}>
                {humidity.value?.toFixed(1)}
                <sup className={classes.scale}>%RH</sup>
              </RowBox>
            </Box>
          )}
        </RowBox>
      }
      subject={footer}
    />
  )
}

export { SensorChannelNumberDisplay }
