import React from "react"
import { Box, Collapse, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { SelectorMaster, LoadingSpinner, Icon } from ".."
import { toId } from "../../utils"
import { PRINTER_TYPE_LABELS, useQuerySensors } from "../../data"
import { SelectionBadge } from "../Badges/SelectionBadge"
import { useAuth } from "../../services"

const SensorSelectorListItem = ({ sensor, selected, onSelect }) => {
  const handleSelect = () => {
    onSelect && onSelect(sensor)
  }

  const name = sensor.name !== sensor.id ? sensor.name : PRINTER_TYPE_LABELS[sensor.type]

  return (
    <Collapse in>
      <ListItem key={toId(sensor)} button onClick={handleSelect}>
        <ListItemAvatar>
          <SelectionBadge status={selected ? "selected" : "none"}>
            <Icon name="probe" fontSize="large" />
          </SelectionBadge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {name || "Unknown Sensor"}
              {selected ? " (active)" : ""}
            </>
          }
          secondary={sensor.address}
          style={{ cursor: "pointer" }}
        />
      </ListItem>
    </Collapse>
  )
}

const SensorSelector = ({ open, value, onSelect, onClose }) => {
  const { location } = useAuth()
  const { data, loading, refetch } = useQuerySensors({
    variables: {
      location: toId(location),
    },
  })

  const handleChange = (newValue) => {
    onSelect && onSelect(newValue)
  }

  const handleRefresh = async () => {
    await refetch()
  }

  const handleCancel = () => {
    onClose && onClose()
  }

  const sensors = data?.sensors || []

  const hasItems = Boolean(sensors.length)

  const form = (
    <Box mb={2}>
      <Collapse in={loading}>
        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <LoadingSpinner size={40} />
            </ListItemAvatar>
            <ListItemText
              primary="Finding sensors"
              secondary="Ensure your sensor is on and in discovery mode if required"
            />
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={!loading}>
        <List disablePadding>
          {sensors.map((item) => (
            <SensorSelectorListItem
              key={toId(item)}
              sensor={item}
              selected={toId(item) === toId(value, true)}
              onSelect={handleChange}
            />
          ))}
          {!hasItems && !loading && (
            <ListItem>
              <ListItemAvatar>
                <Icon name="probe-disabled" fontSize="large" />
              </ListItemAvatar>
              <ListItemText
                primary="No sensors could be found"
                secondary="Ensure you have sensors available at this location suitable for this operation"
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </Box>
  )

  return (
    <SelectorMaster
      open={open}
      title="Sensors"
      maxWidth="sm"
      form={form}
      selectText="Refresh"
      selectVariant="link"
      onSelect={handleRefresh}
      selectDisabled={loading}
      cancelText="Cancel"
      onCancel={handleCancel}
    />
  )
}

export { SensorSelector }
