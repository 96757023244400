import React, { useState, useEffect, useRef } from "react"
import { Popover, InputBase, Divider, Box, List } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import SearchIcon from "@material-ui/icons/Search"
import { useDebounce } from "@react-hook/debounce"
import moment from "moment"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { useQuerySettings, useLazyQuerySearch, useMutationAddUserSearch } from "../../data"
import SearchAreaResultList from "./SearchAreaResultList"
import { SearchListItem } from "./SearchListItem"
import { toId } from "../../utils"
import { RequirePermissions } from "../Permissions"

const usePopoverStyles = makeStyles(() => ({
  root: {
    width: 512,
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  form: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 4,
    minHeight: 40,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    display: "flex",
    justifySelf: "stretch",
  },
  type: {
    padding: theme.spacing(2),
  },
}))

const SearchInput = ({ onChange, initialSearchText, cy = "SearchInput-input" }) => {
  const classes = useStyles()
  const initRef = useRef(false)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    if (!initRef.current && initialSearchText) {
      initRef.current = true
      setSearchText(initialSearchText)
    }
  }, [initialSearchText])

  const handleChange = (e) => {
    setSearchText(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <Box className={classes.form}>
      <SearchIcon />
      <InputBase
        placeholder={t`Search...`}
        value={searchText}
        onChange={handleChange}
        className={classes.input}
        autoFocus
        fullWidth
        data-cy={cy}
      />
    </Box>
  )
}

const SearchPopover = ({ open, anchorEl, onClose }) => {
  const classes = useStyles()
  const {
    location,
    settings: { searches },
  } = useAuth()
  const { clientKiosk: kiosk } = Config.get()
  const [load, { called, loading, data }] = useLazyQuerySearch({
    fetchPolicy: "no-cache",
  })
  const [addUserSearch] = useMutationAddUserSearch()
  const popoverClasses = usePopoverStyles()
  const settings = useQuerySettings()
  const [searchText, setSearchText, setSearchTextImmediate] = useDebounce("", 300)
  const [globalArea, setGlobalArea] = useState(null)
  const [more, setMore] = useState(null)

  useEffect(() => {
    if (searchText.trim()) {
      load({
        variables: { searchText, location: toId(location), limit: 9, filter: { client: kiosk ? "kiosk" : "" } },
      })
    }
  }, [searchText, location, load, kiosk])

  useEffect(() => {
    if (globalArea && more !== globalArea) {
      setMore(globalArea)
    }
    if (globalArea) setGlobalArea("")
  }, [globalArea, setGlobalArea, more, setMore])

  const handleSearchChange = (value, immediate) => {
    if (!value) {
      setSearchText("")
      setMore(null)
      return
    }

    if (value.trim() && value !== searchText) {
      if (immediate) {
        setSearchTextImmediate(value)
      } else {
        setSearchText(value)
      }
    }
  }

  const id = open ? "search-popover" : ""

  const jobs = (data && data.searchAreas.jobs) || {}
  const knowledge = (data && data.searchAreas.knowledge) || {}
  const posts = (data && data.searchAreas.posts) || {}

  const handleMoreResultsClick = (area) => {
    if (area) setMore(more ? null : area)
    else setMore(area)
  }

  const handleSearchResultClick = (event) => {
    onClose(event)
    if (
      !searches ||
      !searches.length ||
      !searches.find((search) => search.text.toLowerCase() === searchText.toLowerCase())
    ) {
      addUserSearch({ variables: { searchText } })
    }
  }

  const BoundSearchAreaResultList = ({ area, icon, linkBase, results, requires, requiresOperator = "or" }) => {
    if (more && more?.props?.id !== area?.props?.id) return null
    return (
      <RequirePermissions requires={requires} operator={requiresOperator}>
        <SearchAreaResultList
          area={area}
          linkBase={linkBase}
          searchText={searchText}
          onClick={handleSearchResultClick}
          isBusy={loading}
          results={results}
          icon={icon}
          onMoreResultsClick={handleMoreResultsClick}
          selected={more?.props?.id === area?.props?.id}
        />
        <Divider />
      </RequirePermissions>
    )
  }

  const handleRecentSearchClick = (text) => {
    handleSearchChange(text, false)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={popoverClasses}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      data-cy="SearchPopover"
    >
      <div className={classes.header}>
        <SearchInput
          onChange={handleSearchChange}
          initialSearchText={searchText}
          cy="SearchPopover-SearchInput-input"
        />
      </div>
      {!called && searches && searches.length > 0 && (
        <Box className={classes.type}>
          <List disablePadding>
            {searches.map(({ at, text }, index) => (
              <SearchListItem
                key={index}
                date={at}
                onClick={() => handleRecentSearchClick(text)}
                style={{ padding: 8 }}
              >
                {text}
              </SearchListItem>
            ))}
          </List>
        </Box>
      )}
      {called && (
        <>
          <BoundSearchAreaResultList
            requires={["jobprocess_read_self", "jobprocess_read_all"]}
            requiresOperator="or"
            area={settings.lang.area.jobs}
            linkBase={(item) => `/jobs/${moment(item.date).format("YYYY-MM-DD")}/${item.type.toLowerCase()}/`}
            results={jobs}
          />
          <BoundSearchAreaResultList
            requires={["category_read", "knowledge_read"]}
            requiresOperator="and"
            area={settings.lang.area.knowledge}
            linkBase="/knowledge/article/"
            results={knowledge}
          />
          <BoundSearchAreaResultList
            requires="post_read"
            area={settings.lang.area.hub}
            linkBase="/hub/post/"
            results={posts}
          />
          {searchText && (
            <Box className={classes.type}>
              <NavLink to={`/search/${encodeURIComponent(searchText)}`} onClick={onClose} data-cy="SearchPopover-all">
                <Trans>
                  Search across all areas for <strong>"{searchText}"</strong>
                </Trans>
              </NavLink>
            </Box>
          )}
        </>
      )}
    </Popover>
  )
}

export default SearchPopover
