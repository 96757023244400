import React from "react"
import { Icon } from ".."

const DownloadIcon = ({ contentType }) => {
  switch (contentType) {
    case "application/pdf":
      return <Icon name="upload-pdf" />
    default:
      return <Icon name="zip" />
  }
}

export default DownloadIcon
