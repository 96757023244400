import React, { useState } from "react"
import { Box, Chip, Hidden, IconButton, makeStyles, Typography } from "@material-ui/core"
import { CancelOutlined, CloudDownload, GetAppOutlined } from "@material-ui/icons"
import Axios from "axios"
import FileDownload from "js-file-download"
import Config from "react-global-configuration"
import { FileIcon } from "../Icon"
import { useMutationRetrieveDownload } from "../../data"
import { useSnackbar } from "../SnackbarProvider"
import { preventDefault, useDeviceUtils, useStringUtils } from "../../utils"
import { LoadingSpinner } from ".."
import { FormatBytes } from "../Format"
import { FlexBox, PaperBox, RowBox } from "../Boxes"

const useStyle = makeStyles((theme) => ({
  chip: {
    borderRadius: 8,
    paddingRight: 3,
    paddingLeft: 2,
  },
  chipLabel: {
    maxWidth: 220,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    marginRight: theme.spacing(0.5),
  },
  chipSize: {
    display: "inline-block",
  },
  light: {
    color: theme.palette.text.secondary,
  },
  cardRoot: {
    cursor: "pointer",
  },
  cardTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  cardDetail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  boxRoot: {
    cursor: "pointer",
  },
}))

const DownloadList = ({ uploads, onDownload, onRemove, variant = "chips", mt = 0.5 }) => {
  const classes = useStyle()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const [retrieveDownload] = useMutationRetrieveDownload()
  const { canPostMessage, postDownloadMessage } = useDeviceUtils()
  const { truncateInMiddle } = useStringUtils()
  const snackbar = useSnackbar()
  const [busy, setBusy] = useState(null)

  if (!["chips", "cards", "boxes"].includes(variant)) throw new Error("Invalid variant, must be chips or cards")

  const handleDownload = async (id) => {
    if ((kiosk || device) && !canPostMessage()) {
      snackbar.showMessage({ message: "Please download via desktop browser" })
      return
    }

    setBusy(id)
    try {
      const { data } = await retrieveDownload({ variables: { id } })
      if (data) {
        if (kiosk || device) {
          postDownloadMessage(data.retrieveDownload)
          setBusy(null)
          return
        }

        const { signedDownloadUrl, upload } = data.retrieveDownload
        const response = await Axios({
          url: signedDownloadUrl,
          method: "GET",
          responseType: "blob",
          headers: {
            "Content-Type": upload.contentType,
          },
        })

        FileDownload(response.data, upload.fileName)

        if (onDownload) onDownload(upload.id)
      } else {
        snackbar.showMessage({ message: "Download failed" })
      }
    } catch (err) {
      snackbar.showMessage({ message: "Download error" })
    } finally {
      setBusy(null)
    }
  }

  const handleRemove = (id) => {
    onRemove && onRemove(id)
  }

  const documents = uploads && uploads.length > 0 && uploads.filter((upload) => upload.fileGroup === "document")

  if (!documents || !documents.length) return null

  if (variant === "chips") {
    return (
      <RowBox flexWrap="wrap" mt={mt}>
        {documents.map(({ id, fileName, fileSize }) => (
          <Box key={id} mr={0.5} mt={0.5}>
            <Chip
              avatar={<FileIcon fileName={fileName} />}
              label={
                <Box alignItems="center" display="flex">
                  <Box className={classes.chipLabel} alignSelf="center">
                    <Hidden xsDown>{fileName}</Hidden>
                    <Hidden smUp>{truncateInMiddle(fileName, 22)}</Hidden>
                  </Box>
                  <Hidden xsDown>
                    <Box className={classes.chipSize} alignSelf="center">
                      <FormatBytes bytes={fileSize} brackets className={classes.light} />
                    </Box>
                  </Hidden>
                </Box>
              }
              clickable
              size="medium"
              title={fileName}
              classes={{ root: classes.chip }}
              onClick={(event) => preventDefault(event, () => handleDownload(id))}
              onDelete={(event) => preventDefault(event, () => handleDownload(id))}
              deleteIcon={
                busy === id ? (
                  <Box mr={1} alignSelf="center">
                    <LoadingSpinner size={20} />
                  </Box>
                ) : (
                  <CloudDownload />
                )
              }
            />
          </Box>
        ))}
      </RowBox>
    )
  }

  if (variant === "boxes") {
    return (
      <FlexBox flexGrow={1} alignItems="center" flexWrap="wrap">
        {documents.map(({ id, fileName, fileSize }) => (
          <Box key={id || fileName} mr={1} mt={1} onClick={() => handleDownload(id)} className={classes.boxRoot}>
            <PaperBox pl={1}>
              <RowBox>
                <Box mr={0.5}>
                  <FileIcon fileName={fileName} fontSize="small" />
                </Box>
                <Box mr={1} className={classes.fileName}>
                  {fileName} (<FormatBytes bytes={fileSize} />)
                </Box>
                <Box>
                  <IconButton edge="start" onClick={(event) => preventDefault(event, () => handleRemove(id))}>
                    <CancelOutlined fontSize="small" />
                  </IconButton>
                </Box>
              </RowBox>
            </PaperBox>
          </Box>
        ))}
      </FlexBox>
    )
  }

  return (
    <>
      {documents.map(({ id, fileName, fileGroup, fileSize }) => (
        <Box mt={mt}>
          <PaperBox
            key={id}
            p={2}
            onClick={(event) => preventDefault(event, () => handleDownload(id))}
            className={classes.cardRoot}
          >
            <Box display="flex" flexGrow={1} flexDirection="row">
              <Box mr={1}>
                <FileIcon fileName={fileName} />
              </Box>
              <Box flexGrow={1} flexDirection="column" alignSelf="center" alignItems="center">
                <Box>
                  <Typography variant="h2" className={classes.cardTitle}>
                    {fileName}
                  </Typography>
                </Box>
                <Box className={classes.cardDetail}>
                  <FormatBytes bytes={fileSize} />
                  {fileSize > 0 && " | "}
                  {fileName.split(".").pop().toUpperCase()} {fileGroup.charAt(0).toUpperCase()}
                  {fileGroup.slice(1)}
                </Box>
              </Box>
              <Box mt={1}>{busy === id ? <LoadingSpinner size={24} delay={false} /> : <GetAppOutlined />}</Box>
            </Box>
          </PaperBox>
        </Box>
      ))}
    </>
  )
}

export { DownloadList }
