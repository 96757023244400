import { useLazyQuery, gql, useQuery } from "@apollo/client"

const RETRIEVE_FILE_FROM_S3_QUERY = gql`
  query RetrieveFileFromS3($id: ID!) {
    downloads {
      one(id: $id) {
        signedUrl
        file {
          id
          fileName
          contentType
        }
      }
    }
  }
`

const useQueryRetrieveFileFromS3 = (options) => useQuery(RETRIEVE_FILE_FROM_S3_QUERY, { ...options })

const useLazyQueryRetrieveFileFromS3 = (options) => useLazyQuery(RETRIEVE_FILE_FROM_S3_QUERY, { ...options })

export { useQueryRetrieveFileFromS3, useLazyQueryRetrieveFileFromS3 }
