import React, { useMemo } from "react"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { LoadingSpinner } from "../LoadingSpinner"
import { toId } from "../../utils"
import {
  useQueryIntegrationAttributeValues,
  useQueryIntegrationGroupAttributeValues,
  useQueryIntegrationLocationAttributeValues,
  useQueryIntegrationLocationGroupAttributeValues,
  useQueryIntegrationUserAttributeValues,
} from "../../data/integrations/useQueryIntegration"

const IntegrationAttributeAutocompleteComponent = ({ type, integration, location, attribute, value, onChange }) => {
  const { data: integrationAttributeData, loading: integrationAttributeLoading } = useQueryIntegrationAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: type !== "integration" || !attribute.dataSource,
  })
  const { data: locationAttributeData, loading: locationAttributeLoading } = useQueryIntegrationLocationAttributeValues(
    {
      variables: { id: toId(integration), key: attribute.key },
      skip: type !== "location" || !attribute.dataSource,
    },
  )
  const { data: locationGroupAttributeData, loading: locationGroupAttributeLoading } =
    useQueryIntegrationLocationGroupAttributeValues({
      variables: { id: toId(integration), location: toId(location), key: attribute.key },
      skip: type !== "locationGroup" || !attribute.dataSource,
    })
  const { data: groupAttributeData, loading: groupAttributeLoading } = useQueryIntegrationGroupAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: type !== "group" || !attribute.dataSource,
  })
  const { data: userAttributeData, loading: userAttributeLoading } = useQueryIntegrationUserAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: type !== "user" || !attribute.dataSource,
  })

  const values = useMemo(() => {
    const results = []
    switch (type) {
      case "integration":
        results.push(...(integrationAttributeData?.integration?.attributeValues || []))
        break
      case "location":
        results.push(...(locationAttributeData?.integration?.locationAttributeValues || []))
        break
      case "locationGroup":
        results.push(...(locationGroupAttributeData?.integration?.locationGroupAttributeValues || []))
        break
      case "group":
        results.push(...(groupAttributeData?.integration?.groupAttributeValues || []))
        break
      case "user":
        results.push(...(userAttributeData?.integration?.userAttributeValues || []))
        break
      default:
        throw new Error("Invalid attribute type")
    }

    if (value && !results.find((item) => item.key === value)) {
      results.push({ key: value, value })
    }

    return results
  }, [
    type,
    value,
    integrationAttributeData,
    locationAttributeData,
    locationGroupAttributeData,
    groupAttributeData,
    userAttributeData,
  ])

  const loading =
    integrationAttributeLoading ||
    locationAttributeLoading ||
    locationGroupAttributeLoading ||
    groupAttributeLoading ||
    userAttributeLoading

  const handleChange = async (_, newValue) => {
    onChange && onChange(newValue)
  }

  const optionValue = useMemo(() => values?.find((item) => item.key === value) || null, [values, value])

  if (!attribute.dataSource) {
    throw new Error("Attribute is missing data source, choose another component")
  }

  return (
    <Autocomplete
      value={optionValue}
      autoHighlight
      getOptionLabel={(option) => `${option.value} (#${option.key})`}
      getOptionSelected={(option) => option.key === optionValue.key}
      options={values || []}
      loading={loading}
      disabled={loading}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={attribute.name}
          variant="outlined"
          placeholder="Not set"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <LoadingSpinner size={24} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export { IntegrationAttributeAutocompleteComponent }
