import React from "react"
import { Box, Typography, IconButton, Divider, SwipeableDrawer, makeStyles } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { RowBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
  },
  drawerPaper: {
    minHeight: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  drawerContent: {
    padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(2)),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    "& li": {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    "& li:last-child": {
      borderBottom: "none",
    },
  },
  drawerActions: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    justifyContent: "space-between",
    position: "sticky",
  },
}))

const MobileDrawer = ({ open, onOpen, onClose, title, disablePadding, children, actions }) => {
  const classes = useStyles({ disablePadding })

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={onOpen}
      onClose={handleClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <RowBox className={classes.root}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <IconButton aria-label="close" size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </RowBox>
      <Divider />
      <Box className={classes.drawerContent}>{children}</Box>
      {actions && (
        <>
          <Divider />
          <Box className={classes.drawerActions}>{actions}</Box>
        </>
      )}
    </SwipeableDrawer>
  )
}

export { MobileDrawer }
