import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Icon, RowBox } from ".."
import { SensorSelector } from "./SensorSelector"

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
    textAlign: "left",
  },
}))

const SelectedSensorButton = ({ sensor, onSelect, loading, disabled }) => {
  const classes = useStyles()
  const [openSelector, setOpenSelector] = useState(false)

  const handleOpen = () => {
    setOpenSelector(true)
  }

  const handleSelect = (selectedSensor) => {
    onSelect && onSelect(selectedSensor)
    setOpenSelector(false)
  }

  const handleClose = () => {
    setOpenSelector(false)
  }

  const sensorSelector = <>{openSelector && <SensorSelector open onSelect={handleSelect} onClose={handleClose} />}</>

  if (!sensor) {
    return (
      <>
        {sensorSelector}
        <RowBox>
          <Icon name="probe" color="inherit" fontSize="large" />

          <Button className={classes.link} color="primary" onClick={handleOpen} disabled={disabled || loading}>
            {!loading && <Trans>Please select a sensor</Trans>}
            {loading && <Trans>Please wait...</Trans>}
          </Button>
        </RowBox>
      </>
    )
  }

  return (
    <>
      {sensorSelector}
      <RowBox>
        <Icon name="probe" color="inherit" fontSize="large" />

        <Button className={classes.link} color="primary" onClick={handleOpen} disabled={disabled || loading}>
          {sensor.name}
        </Button>
      </RowBox>
    </>
  )
}

export { SelectedSensorButton }
