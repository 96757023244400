import React, { useState } from "react"
import { Button, MenuItem, Box, Popover, makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import Config from "react-global-configuration"
import { Trans, t } from "@lingui/macro"
import PopupState, { anchorRef, bindMenu, bindTrigger } from "material-ui-popup-state"
import { useAuth } from "../../services"
import { getDownload } from "../../data"
import { useSnackbar } from ".."
import { toId, useDeviceUtils } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { OutlinedSelect } from "../OutlinedSelect"
import { DatePicker } from "../DateTimePickers"

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

const DesktopSensorsReportsButton = () => {
  const filtersPopoverClasses = useFiltersPopoverStyles()
  const { location } = useAuth()
  const { clientKiosk, clientDevice } = Config.get()

  const { canPostMessage, postDownloadMessage } = useDeviceUtils()

  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const [date, setDate] = useState(moment())

  const handleClick = async () => {
    try {
      setLoading(true)
      const locationId = toId(location)
      const formattedDate = moment(date).format("YYYY-MM-DD")
      const exportUrl = `/sensors/weekly/${locationId}/${formattedDate}`
      const fileName = `${formattedDate}-Weekly-${locationId}.pdf`

      if ((clientKiosk || clientDevice) && canPostMessage()) {
        postDownloadMessage({
          signedDownloadUrl: exportUrl,
          upload: {
            fileName,
            contentType: "application/pdf",
          },
        })
        setLoading(false)
        return
      }

      await getDownload(exportUrl, {
        contentType: "application/pdf",
        fileName,
        onSuccess: () => setLoading(false),
        onError: (error) => {
          setLoading(false)
          snackbar.showMessage({ message: t`No data available for the selected date range` })
        },
      })
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const handleDateChange = (value) => {
    setDate(value)
  }

  return (
    <PopupState variant="popover" popupId="report-download">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            color="primary"
            data-cy="Button-reports"
            disabled={loading}
            {...bindTrigger(popupState)}
            ref={anchorRef(popupState)}
          >
            {!loading && <Trans>Download report</Trans>}
            {loading && <LoadingSpinner size="24px" delay={false} />}
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={filtersPopoverClasses}
          >
            <Box px={2} py={2}>
              <OutlinedSelect native={false} label={t`Report`} value="weekly" disabled={loading}>
                <MenuItem value="weekly">Weekly Temperatures</MenuItem>
              </OutlinedSelect>
              <Box mt={2}>
                <DatePicker label={t`Date`} value={date} onChange={handleDateChange} format="D MMM yyyy" />
              </Box>
              <Box mt={2}>
                <Button variant="contained" fullWidth color="primary" disabled={loading} onClick={() => handleClick()}>
                  <Box ml={1}>
                    <Trans>Download PDF</Trans>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { DesktopSensorsReportsButton }
