import { t } from "@lingui/macro"

import { Content, AreaHeader } from "../../components"
import { useQueryDownloads } from "../../data/downloads/useQueryDownloads"
import DownloadList from "../../components/Download"

const Downloads = () => {
  const downloadsQuery = useQueryDownloads({
    pollInterval: 20000,
  })

  return (
    <>
      <AreaHeader title={t`Downloads`} titleIcon="downloads-dark" />

      <Content full start>
        <DownloadList popover={false} downloadsQuery={downloadsQuery} shouldVirtualize limit={0} spinnerSize={60} />
      </Content>
    </>
  )
}

export default Downloads
