import React, { useEffect, useRef } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { t } from "@lingui/macro"
import { DeleteConfirmIconButton, ImagesViewer, DownloadList } from ".."
import { MobileDrawer } from "../Drawers/MobileDrawer"
import { ColumnBox, RowBox } from "../Boxes"
import { CompletedByAvatar } from "../Avatar"
import { toId, preventDefault } from "../../utils"
import { ProcessCollapse } from "./ProcessCollapse"
import NoteForm from "./NoteForm"

const useStyles = makeStyles((theme) => ({
  note: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:first-child": {
      borderBottom: "none",
    },
    width: "100%",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listContainer: {
    flexGrow: 1,
    overflowY: "auto",
    width: "100%",
    maxHeight: "calc(50vh - 100px)",
    flexDirection: "column-reverse",
  },
  by: {
    marginBottom: theme.spacing(1),
    float: "right",
    color: theme.palette.text.secondary,
    fontSize: 12,
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  text: {
    fontSize: 14,
    lineHeight: 1.4,
    color: theme.palette.text.primary,
    width: "100%",
  },
  inputContainer: {
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  noteContent: {
    padding: theme.spacing(1, 2),
    width: "100%",
  },
  form: {
    width: "100%",
  },
}))

const MobileNotesPopup = ({
  open,
  onClose,
  onEnter,
  hasNotes,
  notes,
  show,
  onShow,
  location,
  canDeleteNote,
  showDeleting,
  onDeleting,
  onDelete,
  deleteLoading,
  text,
  onTextChange,
  onFocus,
  hasNativeCamera,
  step,
  onDeviceUploaded,
  onItemUploaded,
  uploads,
  uploading,
  onRemoveUpload,
  onAdd,
  isValid,
}) => {
  const classes = useStyles()
  const listRef = useRef(null)

  useEffect(() => {
    if (!listRef.current) {
      return
    }

    listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: "smooth" })
  }, [notes])

  const handleEnter = (event) => {
    onEnter && onEnter(event)
  }

  const handleClose = (event) => {
    onClose && onClose(event)
  }

  const handleShow = (item) => {
    onShow && onShow(item)
  }

  const handleFocus = (event) => {
    onFocus && onFocus(event)
  }

  const handleTextChange = (event) => {
    onTextChange && onTextChange(event)
  }

  const handleDeviceUploaded = (item) => {
    onDeviceUploaded && onDeviceUploaded(item)
  }

  const handleItemUploaded = (item) => {
    onItemUploaded && onItemUploaded(item)
  }

  const handleRemoveUpload = (item) => {
    onRemoveUpload && onRemoveUpload(item)
  }

  const handleAdd = (event) => {
    onAdd && onAdd(event)
  }

  const handleDeleting = (item) => {
    onDeleting && onDeleting(item)
  }

  const handleDelete = (item) => {
    onDelete && onDelete(item)
  }

  return (
    <MobileDrawer title={t`Notes`} open={open} onClose={handleClose} disablePadding onOpen={() => {}}>
      {hasNotes && (
        <ColumnBox ref={listRef} className={classes.listContainer} data-cy="AddNoteFooterButton-list">
          {notes.map((item) => {
            const noteBy = (
              <Box>
                <ProcessCollapse in={show !== toId(item)}>
                  <CompletedByAvatar
                    at={item.createdAt}
                    by={item.author}
                    timeZone={location?.timeZone}
                    forceTime
                    alignSelf="flex-end"
                  />
                </ProcessCollapse>
                <ProcessCollapse in={show === toId(item)}>
                  {canDeleteNote(item) && (
                    <Box onClick={preventDefault}>
                      <DeleteConfirmIconButton
                        deleting={showDeleting}
                        onDeleting={handleDeleting}
                        onDelete={() => handleDelete(item)}
                        loading={deleteLoading}
                        size="small"
                      />
                    </Box>
                  )}
                </ProcessCollapse>
              </Box>
            )

            return (
              <Box key={toId(item)} className={classes.note} onClick={() => handleShow(item)}>
                <Box className={classes.noteContent}>
                  {!item.text && <RowBox justifyContent="flex-end">{noteBy}</RowBox>}
                  <ColumnBox gap={item.text ? 1 : 0} flexGrow={1}>
                    {item.text && (
                      <Box className={classes.text}>
                        <Box className={classes.by} ml={item.text ? 1 : 0}>
                          {noteBy}
                        </Box>
                        {item.text}
                      </Box>
                    )}

                    {item.uploads.length > 0 && (
                      <Box>
                        <ImagesViewer uploads={item.uploads} height={80} width={100} />
                        <DownloadList uploads={item.uploads} variant="chips" />
                      </Box>
                    )}
                  </ColumnBox>
                </Box>
              </Box>
            )
          })}
        </ColumnBox>
      )}

      <Box className={classes.inputContainer}>
        <NoteForm
          text={text}
          onTextChange={handleTextChange}
          onFocus={handleFocus}
          onEnter={handleEnter}
          hasNativeCamera={hasNativeCamera}
          step={step}
          onDeviceUploaded={handleDeviceUploaded}
          onItemUploaded={handleItemUploaded}
          uploads={uploads}
          uploading={uploading}
          onRemoveUpload={handleRemoveUpload}
          onAdd={handleAdd}
          isValid={isValid}
          containerClassName={classes.form}
        />
      </Box>
    </MobileDrawer>
  )
}

export default MobileNotesPopup
