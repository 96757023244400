import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Autocomplete } from "@material-ui/lab"
import { Chip, TextField, CircularProgress } from "@material-ui/core"

import {
  PROCESS_STEP_SELECTION_SOURCES,
  useQueryAssets,
  useQueryFoodItems,
  useQuerySuppliers,
  useQueryUsers,
} from "../../data"
import { toId } from "../../utils"
import { selectionTypes } from "../../types"
import { titleCase } from "../../utils/string"

const SelectionField = ({
  value,
  onChange,
  selection,
  disabled,
  isPreview = false,
  location,
  placeholder: defaultPlaceholder,
  label: defaultLabel,
  defaultValue,
  defaultValueAction,
}) => {
  const isFromCustomItemsSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS
  const isFromUsersSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.USERS
  const isFromSuppliersSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS
  const isFromAssetsSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.ASSETS
  const isFromFoodItemsSource = selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.FOOD_ITEMS
  const { data: usersData, loading: usersLoading } = useQueryUsers({
    skip: !isFromUsersSource,
    variables: {
      filter: {
        locations: !isPreview && location ? [location.id] : [],
      },
    },
  })
  const { data: suppliersData, loading: suppliersLoading } = useQuerySuppliers({
    variables: {
      limit: 0,
      offset: 0,
      filter: {
        locations: !isPreview && location ? [location.id] : [],
      },
    },
    skip: !isFromSuppliersSource,
  })
  const { data: assetsData, loading: assetsLoading } = useQueryAssets({
    variables: {
      limit: 0,
      offset: 0,
      filter: {
        locations: !isPreview && location ? [location.id] : [],
      },
    },
    skip: !isFromAssetsSource,
  })
  const { data: foodItemsData, loading: foodItemsLoading } = useQueryFoodItems({
    variables: {
      limit: 0,
      offset: 0,
      filter: {
        locations: !isPreview && location ? [location.id] : [],
      },
    },
    skip: !isFromFoodItemsSource,
  })
  const selectFrom = selection?.selectFrom?.replace("_", " ") ?? ""
  const label = defaultLabel || titleCase(selection?.allowMultiple ? selectFrom : selectFrom?.slice(0, -1))
  const placeholder = defaultPlaceholder || (!disabled ? `Select ${label.toLowerCase()}` : "")
  const inputProps = { label, placeholder }
  const [showHelperText, setShowHelperText] = useState(false)
  const [options, setOptions] = useState([])
  const isLoading = usersLoading || suppliersLoading || assetsLoading || foodItemsLoading

  useEffect(() => {
    if (defaultValueAction && defaultValue) {
      if (isFromFoodItemsSource && foodItemsData?.foodItems?.list?.length > 0) {
        const defaultItem = foodItemsData?.foodItems?.list?.find((item) => toId(item) === toId(defaultValue))
        defaultValueAction(defaultItem)
      }
    }
  }, [defaultValue, defaultValueAction, isFromFoodItemsSource, foodItemsData])

  useEffect(() => {
    if (isFromUsersSource && usersData?.users && usersData?.users.length > 0) {
      const newOptions =
        usersData?.users?.map((item) => ({
          id: item.id,
          name: item.fullName,
          item,
        })) ?? []

      setOptions(newOptions)
      return
    }

    if (isFromSuppliersSource && suppliersData?.suppliers?.list?.length > 0) {
      const newOptions =
        suppliersData?.suppliers?.list?.map((item) => ({
          id: item.id,
          name: item.name,
          item,
        })) ?? []

      setOptions(newOptions)
      return
    }

    if (isFromAssetsSource && assetsData?.assets?.list?.length > 0) {
      const newOptions =
        assetsData?.assets?.list?.map((item) => ({
          id: item.id,
          name: item.name,
          item,
        })) ?? []

      setOptions(newOptions)
      return
    }

    if (isFromFoodItemsSource && foodItemsData?.foodItems?.list?.length > 0) {
      const newOptions =
        foodItemsData?.foodItems?.list?.map((item) => ({
          id: item.id,
          name: item.name,
          item,
        })) ?? []

      setOptions(newOptions)
      return
    }

    if (isFromCustomItemsSource && selection?.customItems?.length > 0) {
      const newOptions = selection?.customItems ?? []

      setOptions(newOptions)
    }
  }, [
    selection,
    usersData,
    suppliersData,
    assetsData,
    foodItemsData,
    isFromUsersSource,
    isFromSuppliersSource,
    isFromAssetsSource,
    isFromCustomItemsSource,
    isFromFoodItemsSource,
  ])

  return (
    <Autocomplete
      fullWidth
      loading={isLoading}
      multiple={selection?.allowMultiple ?? false}
      getOptionLabel={(option) => {
        if (!option) return ""
        if (typeof option === "string") return option
        return isFromCustomItemsSource ? option : option?.name || ""
      }}
      getOptionSelected={(option, optionValue) =>
        isFromCustomItemsSource ? option === optionValue : toId(option) === toId(optionValue)
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const tagProps = {
            ...getTagProps({ index }),
            ...(disabled && {
              disabled: false,
              onDelete: undefined,
            }),
          }

          return <Chip label={isFromCustomItemsSource ? option : option.name} {...tagProps} />
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          variant="outlined"
          helperText={showHelperText ? "" : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={onChange}
      onInputChange={(_, newValue) => {
        setShowHelperText(newValue.length > 0)
      }}
      options={options}
      value={value}
      disabled={disabled}
      // disableClearable
    />
  )
}

SelectionField.propTypes = {
  selection: selectionTypes.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isPreview: PropTypes.bool,
  location: PropTypes.object, // TODO: Use locationTypes instead.
}

export default SelectionField
