import React, { memo, useEffect, useState } from "react"
import moment from "moment"
import { Chart, ArgumentAxis, ValueAxis, LineSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { EventTracker, ValueScale, ArgumentScale } from "@devexpress/dx-react-chart"
import { scaleTime } from "d3-scale"
import { Trans, t } from "@lingui/macro"
import { FormatDateTime, NoItemsMessage, RowBox } from ".."
import { HorizontalLegend, HorizontalLegendItem, NumberDisplay } from "."
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  temperature: {
    fill: theme.palette.sensor.temperature,
  },
  humidity: {
    fill: theme.palette.sensor.humidity,
  },
  probeTemperature: {
    fill: theme.palette.sensor.probeTemperature,
  },
}))

const useTooltipStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  number: {
    fontSize: 20,
    alignItems: "flex-start",
  },
  scale: {
    color: theme.palette.text.faint,
    fontSize: 14,
    lineHeight: "14px",
    paddingTop: theme.spacing(0.75),
  },
  at: {
    color: ({ overdue }) => (overdue ? "red" : "inherit"),
    fontWeight: ({ overdue }) => (overdue ? "600" : "inherit"),
  },
  indicators: {
    color: theme.palette.text.primary,
    opacity: 0.87,
  },
}))

const defaultInitialVisibleSeries = {
  temperature: true,
  probeTemperature: true,
  humidity: true,
}

const SensorSampleChart = memo(function SensorSampleChartMemo({
  data,
  height = 300,
  temperaturePadding = 1,
  probeTemperaturePadding = 1,
  temperatureUnit,
  humidityPadding = 5,
  loading,
  initialVisibleSeries = { ...defaultInitialVisibleSeries },
  onToggleSeries,
}) {
  const [visibleSeries, setVisibleSeries] = useState(initialVisibleSeries || { ...defaultInitialVisibleSeries })

  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    onToggleSeries && onToggleSeries(visibleSeries)
  }, [onToggleSeries, visibleSeries])

  const temperatureMap = data.map(({ temperature }) => temperature).filter(Number)
  const probeTemperatureMap = data.map(({ probeTemperature }) => probeTemperature).filter(Number)
  const allTemperatures = [...temperatureMap, ...probeTemperatureMap]
  const temperatureMax = Math.max(...allTemperatures) + Math.max(temperaturePadding, probeTemperaturePadding)
  const temperatureMin = Math.min(...allTemperatures) - Math.max(temperaturePadding, probeTemperaturePadding)

  const humidityMap = data.map(({ humidity }) => humidity).filter(Number)
  const hasHumidity = Boolean(humidityMap.length)
  const humidityMax = Math.max(...humidityMap) + humidityPadding
  const humidityMin = Math.min(...humidityMap) - humidityPadding

  const atMap = data.map(({ at }) => moment(at))
  const atMax = moment.max(atMap)
  const atMin = moment.min(atMap)

  const seriesConfig = [
    {
      name: t`Temperature`,
      scaleName: "temperature",
      color: theme.palette.sensor.temperature,
      showCondition: (series) => series.temperature && temperatureMap.length > 0,
      className: classes.temperature,
      key: "temperature",
    },
    {
      name: t`Probe Temperature`,
      scaleName: "probeTemperature",
      color: theme.palette.sensor.probeTemperature,
      showCondition: (series) => series.probeTemperature && probeTemperatureMap.length > 0,
      className: classes.probeTemperature,
      key: "probeTemperature",
    },
    {
      name: t`Humidity`,
      scaleName: "humidity",
      color: theme.palette.sensor.humidity,
      showCondition: (series) => series.humidity && hasHumidity,
      className: classes.humidity,
      key: "humidity",
      showIf: hasHumidity,
    },
  ]

  const handleToggleSeries = (key) => {
    const newValue = !visibleSeries[key]

    if (!newValue) {
      const isTemp = key === "temperature"
      const isHumidity = key === "humidity"

      if (isTemp && !visibleSeries.humidity) {
        // Instead of returning, turn on humidity
        setVisibleSeries({
          ...visibleSeries,
          temperature: false,
          humidity: true,
        })
        return
      }
      if (isHumidity && !visibleSeries.temperature) {
        // Instead of returning, turn on temperature
        setVisibleSeries({
          ...visibleSeries,
          humidity: false,
          temperature: true,
        })
        return
      }
    }

    setVisibleSeries({ ...visibleSeries, [key]: newValue })
  }

  const TooltipContent = ({ targetItem: { point } }) => {
    const tooltipClasses = useTooltipStyles()
    const { temperature, humidity, probeTemperature, at } = data[point]

    const getTitle = (item) => {
      if (item.temperature) return t`Temperature`
      if (item.probeTemperature) return t`Probe Temperature`
      if (item.humidity) return t`Humidity`
      return ""
    }

    return (
      <NumberDisplay
        alignItems="flex-start"
        justifyContent="flex-start"
        value={
          <>
            <Box className={tooltipClasses.title}>{getTitle(data[point])}</Box>
            <RowBox className={tooltipClasses.number}>
              {!!temperature && (
                <>
                  {temperature.toFixed(1)}
                  <sup className={tooltipClasses.scale}>{temperatureUnit === "f" ? "°F" : "°C"}</sup>
                </>
              )}
              {!!probeTemperature && (
                <>
                  {probeTemperature.toFixed(1)}
                  <sup className={tooltipClasses.scale}>{temperatureUnit === "f" ? "°F" : "°C"}</sup>
                </>
              )}
              {!!humidity && (
                <>
                  {humidity}
                  <sup className={tooltipClasses.scale}>%RH</sup>
                </>
              )}
            </RowBox>
          </>
        }
        subject={
          <Box className={classes.at}>
            <FormatDateTime value={at} />
          </Box>
        }
      />
    )
  }

  const ValueLabel = ({ suffix, ...props }) => {
    const { text } = props
    return <ValueAxis.Label {...props} text={`${text}${suffix}`} />
  }

  if (!data?.length)
    return (
      <NoItemsMessage>
        <Trans>No data</Trans>
      </NoItemsMessage>
    )

  return (
    <>
      {loading && (
        <RowBox style={{ height }} justifyContent="center">
          <LoadingSpinner size="60" delay={false} />
        </RowBox>
      )}
      {!loading && (
        <>
          <Chart
            data={data.map(({ temperature, humidity, probeTemperature, at }) => ({
              temperature,
              humidity,
              probeTemperature,
              at: moment(at).toDate(),
            }))}
            height={height}
          >
            <ValueScale name="temperature" modifyDomain={() => [temperatureMin, temperatureMax]} />
            <ValueScale name="probeTemperature" modifyDomain={() => [temperatureMin, temperatureMax]} />
            <ValueScale name="humidity" modifyDomain={() => [humidityMin, humidityMax]} />
            <ArgumentScale factory={scaleTime} modifyDomain={() => [moment(atMin).toDate(), moment(atMax).toDate()]} />

            <ArgumentAxis />
            <ValueAxis
              scaleName="temperature"
              labelComponent={(props) => <ValueLabel suffix={temperatureUnit === "f" ? "°F" : "°C"} {...props} />}
              showGrid
            />
            {hasHumidity && (
              <ValueAxis
                scaleName="humidity"
                position="right"
                labelComponent={(props) => <ValueLabel suffix="%" {...props} />}
                showGrid={false}
              />
            )}

            {seriesConfig.map(({ name, scaleName, key, color, showCondition }) => (
              <LineSeries
                key={name}
                name={name}
                scaleName={scaleName}
                valueField={key}
                argumentField="at"
                color={color}
                seriesComponent={(props) => (
                  <LineSeries.Path {...props} style={{ opacity: showCondition(visibleSeries) ? 1 : 0 }} />
                )}
              />
            ))}

            <EventTracker />
            <Tooltip contentComponent={TooltipContent} />
          </Chart>
          <HorizontalLegend>
            {seriesConfig
              .filter((item) => item.showIf !== false)
              .map(({ name, className, key }) => (
                <HorizontalLegendItem
                  key={key}
                  text={name}
                  className={className}
                  onClick={() => handleToggleSeries(key)}
                  isActive={visibleSeries[key]}
                />
              ))}
          </HorizontalLegend>
        </>
      )}
    </>
  )
})

export { SensorSampleChart }
