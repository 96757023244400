import React, { useMemo } from "react"
import { Box, Tooltip } from "@material-ui/core"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { PaperBox, RowBox } from "../Boxes"
import { InfoSection } from "../Viewers"
import { useLazyQueryIntegrations, USER_STATUS } from "../../data"
import TimeAgo from "../TimeAgo"
import { LinkButton } from "../LinkButton"
import { FormatDateTimeCompact } from "../Format"
import { Caption, FieldSectionHeading } from "../Headings"
import { toId, useMountEffect } from "../../utils"
import { PersonNotes } from "./PersonNotes"
import { useAuth } from "../../services"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { LoadingSpinner } from "../LoadingSpinner"
import { InfoCollapse } from "../Viewers/InfoCollapse"
import { snakeToTitle } from "../../utils/string"
import { Icon } from "../Icon"

const PersonAttributeList = ({ attributes, integrations }) => {
  const getAttribute = (userAttribute) => {
    const integration = integrations?.find((_integration) => toId(_integration) === toId(userAttribute.integration))
    return integration?.integrationProvider.attributes?.user.find((attribute) => attribute.key === userAttribute.key)
  }

  if (!attributes?.length > 0) {
    return null
  }

  return (
    <>
      {attributes.map((attribute) => {
        const integrationAttribute = getAttribute(attribute)

        const tooltip = [attribute.key]
        if (integrationAttribute) {
          tooltip.push(toId(attribute.integration))
        }

        return (
          <React.Fragment key={toId(attribute)}>
            <InfoSection
              title={
                <Tooltip title={tooltip.join(" - ")}>
                  <RowBox>
                    {Boolean(integrationAttribute) && (
                      <Box mr={1}>
                        <Icon name="integration" fontSize="small" />
                      </Box>
                    )}
                    <Box>{integrationAttribute?.name || snakeToTitle(attribute.key)}</Box>
                  </RowBox>
                </Tooltip>
              }
              value={attribute?.value || "(not set)"}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

const PersonDetails = ({ user, onResendInvite, sendInviteLoading }) => {
  const { hasPermission, canCreateRoles, isCurrentUser } = useAuth()
  const [loadIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQueryIntegrations()
  const isInitial = USER_STATUS.INITIAL === user.status
  const isInvite = [USER_STATUS.INVITED, USER_STATUS.INITIAL].includes(user.status)
  const isInviteExpired = isInvite && moment().isAfter(user.invite.expiresAt)

  useMountEffect(() => {
    if (hasPermission("integration_read")) {
      loadIntegrations()
    }
  })

  const handleResendInvite = () => {
    onResendInvite && onResendInvite()
  }

  const hasAttributes = useMemo(
    () =>
      user.attributes?.length > 0 ||
      integrationsData?.integrations.some(({ integrationProvider: { attributes } }) => attributes?.user.length),
    [integrationsData?.integrations, user.attributes?.length],
  )

  const hasEditDetails = canCreateRoles(user.roles)
  const hasNotes = hasPermission("user_note_read") && !isCurrentUser(user)

  const attributesWithValue = user.attributes?.filter((attribute) => attribute.value)
  const attributesWithoutValue = user.attributes?.filter((attribute) => !attribute.value)

  return (
    <>
      <FieldSectionHeading>
        <Trans>Account &amp; login</Trans>
      </FieldSectionHeading>
      <Box mb={3}>
        <PaperBox>
          <InfoSection title={<Trans>Email address</Trans>} value={user.email} />
          {isInvite && (
            <>
              <InfoSection
                title={<Trans>Invitation</Trans>}
                value={
                  <RowBox>
                    <LinkButton onClick={handleResendInvite} disabled={sendInviteLoading}>
                      {isInitial && <Trans>Send invite</Trans>}
                      {!isInitial && <Trans>Resend invite</Trans>}
                    </LinkButton>
                    {isInviteExpired && (
                      <Box ml={1}>
                        <Trans>Expired</Trans> <FormatDateTimeCompact value={user.invite.expiresAt} />
                      </Box>
                    )}
                    {!isInviteExpired && Boolean(user.invite?.sentAt) && (
                      <Box ml={1}>
                        <Trans>Sent</Trans> <TimeAgo date={user.invite.sentAt} lowercase />, <Trans>expires</Trans>{" "}
                        <FormatDateTimeCompact value={user.invite.expiresAt} />
                      </Box>
                    )}
                  </RowBox>
                }
              />
            </>
          )}
          {user.external && (
            <>
              <InfoSection title={<Trans>Identity provider</Trans>} value={user.identityProvider.name} />
            </>
          )}
        </PaperBox>
      </Box>
      <FieldSectionHeading>
        <Trans>Work, locations &amp; permissions</Trans>
      </FieldSectionHeading>
      <Box mb={3}>
        <PaperBox>
          <InfoSection title={<Trans>Areas of Work</Trans>} value={user.groups.map((group) => group.name).join(", ")} />
          <InfoSection
            title={<Trans>Locations</Trans>}
            value={<TruncateNames names={user.locations.map((location) => location.name)} max={3} />}
          />

          <InfoSection
            title={<Trans>Access level</Trans>}
            value={user.roles.map((role) => role.friendlyName).join(", ") || "Employee"}
          />
        </PaperBox>
      </Box>

      {hasAttributes && (
        <>
          <FieldSectionHeading>
            <Trans>Attributes</Trans>
          </FieldSectionHeading>
          <Box mb={3}>
            <PaperBox>
              {!integrationsLoading && integrationsData?.integrations && (
                <>
                  <PersonAttributeList attributes={attributesWithValue} integrations={integrationsData.integrations} />

                  {attributesWithoutValue.length > 0 && (
                    <InfoCollapse subject="attributes">
                      <PersonAttributeList
                        attributes={attributesWithoutValue}
                        integrations={integrationsData.integrations}
                      />
                    </InfoCollapse>
                  )}
                </>
              )}
              {integrationsLoading && (
                <Box display="flex" justifyContent="center" p={1}>
                  <LoadingSpinner delay={false} />
                </Box>
              )}
            </PaperBox>
          </Box>
        </>
      )}
      {hasEditDetails && hasNotes && (
        <>
          <FieldSectionHeading mb={0.5}>
            <Trans>Internal notes</Trans>
          </FieldSectionHeading>
          <Caption mb={1.5}>
            <Trans>
              Only users with <Trans>Regional Manager</Trans> permission level and above can view and leave notes.
            </Trans>
          </Caption>
          <PersonNotes user={user} />
        </>
      )}
    </>
  )
}

export { PersonDetails }
