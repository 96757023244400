import { Box, IconButton, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { Trans } from "@lingui/macro"
import TimeAgo from "../TimeAgo"
import DownloadIcon from "./DownloadIcon"
import { LoadingSpinner } from "../LoadingSpinner"
import { Icon } from "../Icon"
import { useDownload } from "../../utils/useDownload"
import { ErrorBoundary, FormatBytes, useSnackbar } from ".."
import { DOWNLOAD_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderBottom: ({ isLastItem }) => (!isLastItem ? `1px solid ${theme.palette.grey[200]}` : "none"),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    cursor: ({ completed }) => (completed ? "pointer" : "default"),
    ...({ popover }) =>
      popover
        ? {
            "&:last-child": {
              borderBottom: "none",
            },
          }
        : {},
    "&:hover": {
      background: theme.palette.action.hover,
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.action.focus,
      outline: "none",
    },
  },
  itemIcon: {
    minWidth: 0,
  },
  itemName: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: 600,
  },
  itemFileName: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  itemLinkIcon: {
    minWidth: 0,
    color: theme.palette.text.primary,
  },
  iconButton: {
    width: 32,
    height: 32,
  },
  warningIcon: {
    color: theme.palette.error.main,
  },
}))

const DownloadListItem = ({ download, popover, isLastItem = false }) => {
  const inProgress = download?.status === DOWNLOAD_STATUS.IN_PROGRESS && !download?.isOverdue
  const inProgressOverdue = download?.status === DOWNLOAD_STATUS.IN_PROGRESS && download?.isOverdue
  const completed = download?.status === DOWNLOAD_STATUS.COMPLETED
  const failed = download?.status === DOWNLOAD_STATUS.FAILED
  const classes = useStyles({ popover, isLastItem, completed })
  const { isDownloading, downloadProgress, downloadFile } = useDownload()
  const snackbar = useSnackbar()

  const handleDownloadButtonClick = () => {
    if (!download?.path || isDownloading) {
      return
    }

    downloadFile({
      id: download?.id,
      url: download?.path,
      fileName: download?.fileName,
      contentType: download?.contentType,
      disk: download?.disk,
    })
  }

  useEffect(() => {
    if (isDownloading) {
      snackbar.showMessage({
        icon: <Icon name="pdf" />,
        message: `Downloading PDF (${downloadProgress}%)`,
        showCloseButton: true,
      })
    }
  }, [snackbar, isDownloading, downloadProgress])

  return (
    <ErrorBoundary>
      <ListItem className={classes.item} onClick={handleDownloadButtonClick} disabled={isDownloading}>
        <ListItemIcon className={classes.itemIcon}>
          <DownloadIcon {...download} />
        </ListItemIcon>
        <ListItemText
          primary={
            download && (
              <>
                {download.name && <Box className={classes.itemName}>{download.name}</Box>}
                {!download.name && <Box className={classes.itemFileName}>{download.fileName}</Box>}
              </>
            )
          }
          secondary={
            <>
              {inProgress && (
                <span>
                  <Trans>In progress</Trans>
                </span>
              )}
              {inProgressOverdue && (
                <span>
                  <Trans>Taking longer than expected</Trans>
                </span>
              )}
              {completed && (
                <span>
                  {download?.fileSize !== null && <FormatBytes bytes={download.fileSize} />}
                  {download?.fileSize !== null && completed && " | "}
                  <TimeAgo date={download.completedAt} className={classes.age} />
                </span>
              )}
              {failed && (
                <span>
                  <Trans>Export failed</Trans> | <TimeAgo date={download.failAt} className={classes.age} />
                </span>
              )}
            </>
          }
        />
        <ListItemIcon className={classes.itemLinkIcon}>
          {(inProgress || inProgressOverdue) && <LoadingSpinner size="32px" opacity="1" />}
          {completed && (
            <IconButton className={classes.iconButton} disabled={isDownloading}>
              <Icon name="download-report" />
            </IconButton>
          )}
          {failed && <Icon className={classes.warningIcon} name="warning" />}
        </ListItemIcon>
      </ListItem>
    </ErrorBoundary>
  )
}

export default DownloadListItem
