// Taken from https://github.com/TeamWertarbyte/material-ui-snackbar-provider

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import { Chip } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/styles"
import SnackbarContext from "./SnackbarContext"
import { Icon } from "../Icon"

const snackbarStyles = () => ({
  root: {
    opacity: 0.9,
  },
})

const useChipStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    padding: theme.spacing(1),
    color: theme.palette.text.white,
    fontSize: 14,
    fontWeight: "600",
    maxWidth: "95vw",
    [theme.breakpoints.only("xs")]: {
      marginBottom: 70,
    },

    "& .MuiChip-label": {
      paddingLeft: 10,
      paddingRight: 10,
    },

    "& .MuiChip-deleteIcon": {
      width: 28,
      height: 28,
      padding: 4,
      color: theme.palette.text.white,
    },
  },
}))

const defaultState = {
  open: false,
  autoHideDuration: 3000,
}

class SnackbarProvider extends PureComponent {
  constructor(props) {
    super(props)
    this.contextValue = {
      showMessage: this.showMessage,
      clearMessage: this.clearMessage,
    }
    this.state = {
      ...defaultState,
    }
  }

  clearMessage = () => {
    this.setState({
      open: false,
    })
  }

  /**
   * Display a message with this snackbar.
   * @param {string} message message to display
   * @param {element} icon icon to display as part of chip
   * @param {string} color of chip, default primary
   * @param {object} chipProps additional props for chip
   * @param {string} [action] label for the action button
   * @param {function} [onActionClick] click handler for the action button
   * @public
   */
  showMessage = ({ color, chipProps, ...rest }) => {
    this.setState({
      open: true,
      color: color || "primary",
      chipProps,
      ...rest,
    })
  }

  handleActionClick = () => {
    this.handleClose()
    if (this.state.onActionClick) this.state.onActionClick()
  }

  handleClick = () => {
    if (this.state.onClick) this.state.onClick()
  }

  handleClose = () => {
    if (!this.state.keepOpen) this.setState({ open: false, handleAction: null })
  }

  render() {
    const { action, message, icon, color, chipProps, open, autoHideDuration, ...rest } = this.state

    const { ButtonProps = {}, children, SnackbarProps = {} } = this.props

    return (
      <>
        <SnackbarContext.Provider value={this.contextValue}>{children}</SnackbarContext.Provider>
        <StyledSnackbar
          {...{
            SnackbarProps,
            open,
            action,
            ButtonProps,
            icon,
            message,
            color,
            chipProps,
            onActionClick: this.handleActionClick,
            onClick: this.handleClick,
            onClose: this.handleClose,
            autoHideDuration,
            ...rest,
          }}
        />
      </>
    )
  }
}

const StyledSnackbar = ({
  SnackbarProps,
  open,
  action,
  ButtonProps,
  icon,
  message,
  color,
  chipProps,
  autoHideDuration,
  onActionClick,
  onClick,
  onClose,
  keepOpen,
  showCloseButton = false,
}) => {
  const classes = useChipStyles()

  const internalChipProps = showCloseButton
    ? {
        onDelete: onClose,
        deleteIcon: <Icon name="clear" />,
      }
    : {}

  return (
    <Snackbar
      {...{
        ...SnackbarProps,
        open,
        onClick,
        onClose,
        autoHideDuration: keepOpen ? 0 : autoHideDuration,
      }}
      action={
        action != null && (
          <Button color="secondary" size="small" {...ButtonProps} onClick={onActionClick}>
            {action}
          </Button>
        )
      }
    >
      <Chip {...{ icon, label: message, classes, color, ...chipProps }} {...internalChipProps} />
    </Snackbar>
  )
}

SnackbarProvider.propTypes = {
  /**
   * Props to pass through to the action button.
   */
  ButtonProps: PropTypes.object,
  /**
   * The children that are wrapped by this provider.
   */
  children: PropTypes.node,
  /**
   * Props to pass through to the snackbar.
   */
  SnackbarProps: PropTypes.object,
}

export default withStyles(snackbarStyles)(SnackbarProvider)

export { useChipStyles as useSnackbarChipStyles }
