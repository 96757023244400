import React from "react"
import { Box, Badge, makeStyles, Divider } from "@material-ui/core"
import moment from "moment"
import Config from "react-global-configuration"
import { RowBox, ActionCard, Icon, TimeAgo } from ".."
import { SENSOR_CHANNEL_NAME } from "../../data/enums"
import { SensorChannelNumberDisplay } from "../Sensors/SensorChannelNumberDisplay"

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  indicators: {
    color: theme.palette.text.primary,
    opacity: 0.87,
  },
  warning: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
  product: {
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  titleWrapper: {
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > h2": {
      marginBottom: 0,
    },
  },
}))

export const sentryIgnoreBatteryProducts = ["Guardian Sensor", "Guardian Sensor FMU130"]

const SensorIndicator = ({
  value,
  unit = "%",
  iconBase = "sensors",
  iconStep = 10,
  ml = 0,
  layout = "column",
  ...rest
}) => {
  const icon = <Icon name={`${iconBase}${Math.floor(value / iconStep) * iconStep}`} />

  const label = <small>{value}%</small>

  if (layout === "row") {
    return (
      <RowBox ml={ml} title={`${value}${unit}`} {...rest}>
        {icon}
        <RowBox ml={0.5}>{label}</RowBox>
      </RowBox>
    )
  }

  return (
    <Box ml={ml} title={`${value}${unit}`} justifyContent="center" {...rest}>
      {icon}
      <RowBox justifyContent="center">{label}</RowBox>
    </Box>
  )
}

const SensorLocationIndicator = ({ ...props }) => (
  <Box {...props} title="Last location reported">
    <Icon name="location-pin" />
  </Box>
)

const SensorActionCard = ({ index, sensor, loading, onClick, selected, ...rest }) => {
  const classes = useStyles()
  const config = Config.get("sensors")
  const { name, serial, product, sample } = sensor

  if (!sample) {
    return null
  }

  const { value, battery, signal, at, lbs, displayUnit, probeValue: probeTemperature, probeAt } = sample

  const humidity = sensor.samples.find((item) => item.type === SENSOR_CHANNEL_NAME.HUMIDITY)

  const handleSensorClick = (event) => {
    onClick && onClick(event)
  }

  const atWarning = moment().diff(moment(at), "minutes") > config.at.warnAgoMaxMin
  const hasBattery = !sentryIgnoreBatteryProducts.includes(product)
  const batteryWarning = battery < config.battery.warnLowPercent && hasBattery
  const hasWarning = (atWarning || batteryWarning) && !loading
  const hasLocation = Boolean(lbs?.length)

  // TODO: probeTemperature should be null if not available, otherwise falsy value 0 will be problematic
  const mainTemperature = probeTemperature || value
  const mainAt = probeTemperature ? probeAt : at

  return (
    <ActionCard
      title={name}
      titleWrapperProps={{ className: classes.titleWrapper }}
      detail={
        <RowBox flexGrow={1} mt={0.5} className={classes.product}>
          {product}
          {serial && ` - ${serial.toUpperCase()}`}
        </RowBox>
      }
      selected={selected}
      overdue={hasWarning && !selected}
      cardProps={{ onClick: handleSensorClick }}
      className={classes.fullWidth}
      footer={
        <RowBox justifyContent="space-between" flexGrow={1}>
          <RowBox ml={0.5}>
            <Badge variant="dot" color={atWarning ? "error" : "primary"} overlap="circular" />
            <Box ml={1.25} className={atWarning ? classes.warning : ""}>
              <small>
                <TimeAgo date={mainAt || new Date()} />
              </small>
            </Box>
          </RowBox>
          <RowBox>
            {hasLocation && <SensorLocationIndicator ml="auto" className={classes.indicators} />}
            <SensorIndicator
              value={signal}
              iconBase="signal"
              iconStep={10}
              ml={hasLocation ? 2 : "auto"}
              className={classes.indicators}
              layout="row"
            />
            {hasBattery && (
              <SensorIndicator
                value={battery}
                iconBase="battery"
                iconStep={5}
                ml={2}
                className={`${classes.indicators} ${batteryWarning ? classes.warning : ""}`}
                layout="row"
              />
            )}
          </RowBox>
        </RowBox>
      }
      {...rest}
    >
      <Box
        style={{ marginLeft: hasWarning || selected ? -16 : -18, marginRight: hasWarning || selected ? -16 : -18 }}
        my={2}
      >
        <Divider />
      </Box>
      <SensorChannelNumberDisplay
        probeTemperature={probeTemperature}
        mainTemperature={mainTemperature}
        displayUnit={displayUnit}
        humidity={humidity}
        atWarning={atWarning}
        probeAt={probeAt}
        temperatureAt={at}
      />
    </ActionCard>
  )
}

export { SensorActionCard, SensorIndicator }
