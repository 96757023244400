import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { Badge, ListItemIcon, ListItemText, MenuItem, Menu, Typography, useTheme } from "@material-ui/core"
import { Icon, LinkButton, RowBox } from ".."
import { RequirePermissions } from "../Permissions"
import { preventDefault } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  navLink: {
    display: ({ display }) => display,
    alignItems: "center",
    textDecoration: "none",
  },
  navLinkDisabled: {
    opacity: 0.3,
  },
  badgeResult: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  beta: {
    fontSize: 10,
    fontWeight: "600",
    opacity: 0.6,
    marginLeft: 3,
    color: theme.palette.text.primary,
  },
  menu: {
    minWidth: 220,
  },
  menuItem: {
    "&:hover": {
      background: theme.palette.action.hover,
    },
    "&:focus": {
      background: theme.palette.action.focus,
    },
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
  menuListItemIconSvg: {
    width: 24,
  },
  menuItemBeta: {
    fontSize: 8,
    fontWeight: "600",
    opacity: 0.6,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
}))

const useBadgeStyles = makeStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    marginLeft: "-3px",
    backgroundColor: (props) => props.backgroundColor,
    color: (props) => props.color,
    transform: "scale(1) translate(-50%, -50%)",
  },
}))

const NavLinkItem = ({
  className = "",
  to,
  display = "flex",
  text,
  external = false,
  icon,
  rightIcon,
  title,
  badgeCount,
  badgeShowZero = false,
  badgeStyleProps,
  requires,
  feature,
  operator = "or",
  onClick,
  disabled,
  beta = false,
  subMenu = null,
  cy = "",
}) => {
  const classes = useStyles({ display })
  const theme = useTheme()
  const badgeClasses = useBadgeStyles(badgeStyleProps)
  const [subMenuEl, setSubMenuEl] = useState(null)
  const { hasFeature, hasPermission } = useAuth()

  if (feature && !hasFeature(feature)) return null

  const handleSubMenuClick = (event) => {
    setSubMenuEl(event.currentTarget)
  }
  const handleSubMenuClose = () => {
    setSubMenuEl(null)
  }

  const handleClick = disabled
    ? (event) => preventDefault(event, () => {})
    : subMenu?.length
      ? handleSubMenuClick
      : (event) => {
          onClick && onClick(event)
        }

  const iconItem = icon ? (
    typeof icon === "object" ? (
      <Icon name={icon.name} className={icon.className} />
    ) : (
      <Icon name={icon} />
    )
  ) : null

  const rightIconItem = rightIcon ? (
    typeof rightIcon === "object" ? (
      <Icon name={rightIcon.name} fontSize={rightIcon.fontSize} className={rightIcon.className} />
    ) : (
      <Icon name={rightIcon} />
    )
  ) : null

  let result

  if (external) {
    result = (
      <a href={to} title={title} target="_blank" rel="noopener noreferrer">
        {iconItem} {text}
      </a>
    )
  } else if (to) {
    result = (
      <NavLink
        className={`${classes.navLink} ${disabled ? classes.navLinkDisabled : ""} ${className}`.trim()}
        href={to}
        to={to}
        title={title}
        onClick={onClick || disabled ? handleClick : null}
        data-cy={`NavLinkItem${to?.replaceAll ? to.replaceAll("/", "-") : ""}${cy}`}
      >
        {iconItem} {text} {rightIconItem}
        {beta && (
          <Typography className={classes.beta}>
            <sup>BETA</sup>
          </Typography>
        )}
      </NavLink>
    )
  } else {
    result = (
      <>
        <LinkButton
          className={`${classes.navLink} ${disabled ? classes.navLinkDisabled : ""} ${className}`.trim()}
          title={title}
          onClick={onClick || disabled || subMenu ? handleClick : null}
          data-cy={`NavLinkItem${to?.replaceAll ? to.replaceAll("/", "-") : ""}${cy}`}
        >
          {iconItem} {text} {rightIconItem}
          {beta && (
            <Typography className={classes.beta}>
              <sup>BETA</sup>
            </Typography>
          )}
        </LinkButton>
        {!!subMenu?.length && (
          <Menu
            open={Boolean(subMenuEl)}
            anchorEl={subMenuEl}
            onClose={handleSubMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: theme.spacing(1), horizontal: "left" }}
            classes={{ paper: classes.menu }}
          >
            {subMenu
              .filter(
                (item) =>
                  (!item.feature || hasFeature(item.feature)) && (!item.requires || hasPermission(item.requires)),
              )
              .map(
                (
                  {
                    text: itemText,
                    icon: itemIcon,
                    to: itemTo,
                    requires: itemRequires,
                    selected: itemSelected,
                    beta: itemBeta,
                    cy: itemCy = "",
                  },
                  index,
                  array,
                ) => (
                  <MenuItem
                    key={index}
                    divider={index !== array.length - 1 ? true : undefined}
                    component={NavLink}
                    to={itemTo}
                    selected={itemSelected}
                    className={classes.menuItem}
                    onClick={handleSubMenuClose}
                    data-cy={`NavLinkItem${itemTo?.replaceAll ? itemTo.replaceAll("/", "-") : ""}${itemCy}`}
                  >
                    <ListItemIcon className={classes.menuListItemIcon}>
                      <Icon name={itemIcon} className={classes.menuListItemIconSvg} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <RowBox>
                          {itemText}

                          {itemBeta && (
                            <Typography className={classes.menuItemBeta}>
                              <sup>BETA</sup>
                            </Typography>
                          )}
                        </RowBox>
                      }
                    />
                  </MenuItem>
                ),
              )}
          </Menu>
        )}
      </>
    )
  }

  result = badgeCount ? (
    <div className={classes.badgeResult}>
      {result}
      <Badge
        badgeContent={badgeCount}
        showZero={badgeShowZero}
        classes={badgeClasses}
        overlap="rectangular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </div>
  ) : (
    result
  )

  return requires ? (
    <RequirePermissions requires={requires} operator={operator}>
      {result}
    </RequirePermissions>
  ) : (
    result
  )
}

export default NavLinkItem
