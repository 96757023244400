export const USER_STATUS = {
  INITIAL: "initial",
  INVITED: "invited",
  ACTIVE: "active",
  INACTIVE: "inactive",
}

export const USER_TRAINING_STATUS = {
  NONE: "none",
  IN_PROGRESS: "in_progress",
  OVERDUE: "overdue",
  COMPLETE: "complete",
}

export const USER_TRAINING_MODULE_STATUS = {
  INCOMPLETE: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
}

export const USER_TRAINING_ASSESSMENT_STATUS = {
  NONE: "none",
  SCHEDULED: "scheduled",
  COMPETENT: "competent",
  NEEDS_TRAINING: "needs_training",
  TRAINER: "trainer",
  TRAINED: "trained",
}

export const USER_TRAINING_ASSESSMENT_STATUS_LABELS = {
  [USER_TRAINING_ASSESSMENT_STATUS.NONE]: "None",
  [USER_TRAINING_ASSESSMENT_STATUS.SCHEDULED]: "Scheduled",
  [USER_TRAINING_ASSESSMENT_STATUS.COMPETENT]: "Competent",
  [USER_TRAINING_ASSESSMENT_STATUS.NEEDS_TRAINING]: "Needs training",
  [USER_TRAINING_ASSESSMENT_STATUS.TRAINER]: "Trainer",
  [USER_TRAINING_ASSESSMENT_STATUS.TRAINED]: "Trained",
}

export const USER_ACCREDITATIONS_STATUS = {
  NONE: "none",
  AWAITING: "awaiting",
  WARNING: "warning",
  EXPIRED: "expired",
  COMPLIANT: "compliant",
}

export const USER_ACCREDITATIONS_STATUS_LABELS = {
  [USER_ACCREDITATIONS_STATUS.NONE]: "No accreditations",
  [USER_ACCREDITATIONS_STATUS.AWAITING]: "Awaiting upload",
  [USER_ACCREDITATIONS_STATUS.WARNING]: "Expiring soon",
  [USER_ACCREDITATIONS_STATUS.EXPIRED]: "Expired",
  [USER_ACCREDITATIONS_STATUS.COMPLIANT]: "Up to date",
}

export const NOTIFICATION_TYPE = {
  GENERAL: "general",
  PROCESS: "process",
  TASK: "task",
  KNOWLEDGE: "knowledge",
  POST: "post",
  JOB: "job",
  ACTION: "action",
  TRAINING: "training",
  ACCREDITATION: "accreditation",
}

export const NOTIFY_TYPE = {
  NONE: 0,
  NOTIFICATION: 1,
  EMAIL: 2,
}

export const CRON_JOB_TYPE = {
  JOB_GENERATE_LOCATION_DAY_JOBS: 0,
  JOB_STATUS_NOTIFICATION: 1,
}

export const PROCESS_TYPE = {
  PROCESS: "process",
  AUDIT: "audit",
}

export const SEARCH_RESULT = {
  PROCESS: "Process",
  KNOWLEDGE: "Knowledge",
  POST: "Post",
}

export const PROCESS_REPEAT = {
  NEVER: 0,
  DAILY: 1,
  WEEKLY: 2,
  FORTNIGHTLY: 4,
  MONTHLY: 8,
  YEARLY: 9,
  ADHOC: 100,
  UNSCHEDULED: 200,
}

export const PROCESS_REPEAT_RECURRING = [
  PROCESS_REPEAT.DAILY,
  PROCESS_REPEAT.WEEKLY,
  PROCESS_REPEAT.FORTNIGHTLY,
  PROCESS_REPEAT.MONTHLY,
  PROCESS_REPEAT.YEARLY,
]

export const SCHEDULE_FILTER = {
  ONCE: { label: "One-off", type: PROCESS_REPEAT.NEVER },
  DAILY: { label: `Daily`, type: PROCESS_REPEAT.DAILY, frequency: 1 },
  WEEKLY: { label: "Weekly", type: PROCESS_REPEAT.WEEKLY, frequency: 1 },
  FORTNIGHTLY: { label: "Fortnightly", type: PROCESS_REPEAT.FORTNIGHTLY, frequency: 1 },
  MONTHLY: { label: "Monthly", type: PROCESS_REPEAT.MONTHLY, frequency: 1 },
  QUARTERLY: { label: "Quarterly", type: PROCESS_REPEAT.QUARTERLY, frequency: 3 },
  YEARLY: { label: "Yearly", type: PROCESS_REPEAT.YEARLY, frequency: 1 },
  ADHOC: { label: "Adhoc", type: PROCESS_REPEAT.ADHOC },
}

export const SCHEDULE_FILTERS = Object.keys(SCHEDULE_FILTER).map((key) => ({
  key: key.toLowerCase(),
  ...SCHEDULE_FILTER[key],
}))

export const NON_RECURRING_SCHEDULE_FILTERS = Object.keys(SCHEDULE_FILTER)
  .filter(
    (key) =>
      ![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(SCHEDULE_FILTER[key].type),
  )
  .map((key) => ({
    key: key.toLowerCase(),
    ...SCHEDULE_FILTER[key],
  }))

export const PROCESS_SCHEDULE_AFTER = {
  DATE: "date",
}

// m/h/d must be compatible with moment unit of time
export const PROCESS_SCHEDULE_DUE = {
  NONE: "none",
  MINUTES: "minutes",
  HOURS: "hours",
  DAYS: "days",
}

export const PROCESS_SCHEDULE_UNTIL = {
  NONE: "none",
  COMPLETED: "completed",
  NEXT_INSTANCE: "next_instance",
  MINUTES: "minutes",
  HOURS: "hours",
  DAYS: "days",
}

export const PROCESS_SET = {
  ANY: "any",
  ACTIVE: "active",
  ACTIVE_RECURRING: "active-recurring",
  ACTIVE_ONEOFF: "active-oneoff",
  ACTIVE_ADHOC: "active-adhoc",
  ACTIVE_UNSCHEDULED: "active-unscheduled",
  INACTIVE: "inactive",
  INACTIVE_RECURRING: "inactive-recurring",
  INACTIVE_ONEOFF: "inactive-oneoff",
  INACTIVE_ADHOC: "inactive-adhoc",
  INACTIVE_UNSCHEDULED: "inactive-unscheduled",
}

export const PROCESS_STEP_RESPONSE_TYPE = {
  CHECKBOX: "checkbox",
  NUMBER: "number",
  TEXT: "text",
  LONG_TEXT: "long_text",
  DATE_TIME: "date_time",
  PHOTOS: "photos",
  MULTIPLE_CHOICE: "multiple_choice",
  PROCESS: "process",
  SIGNATURE: "signature",
  SECTION: "section",
  INSTRUCTION: "instruction",
  FILES: "files",
  SELECTION: "selection",
  TEMPERATURE_TIMER: "temperature_timer",

  // deprecated for multiple_choice responseSets
  NUMBER_RATING_5: "number_rating_5",
  NUMBER_RATING_10: "number_rating_10",
}

export const PROCESS_STEP_NON_RESPONSE_TYPES = [
  PROCESS_STEP_RESPONSE_TYPE.SECTION,
  PROCESS_STEP_RESPONSE_TYPE.INSTRUCTION,
]

export const PROCESS_STEP_RESPONSE_TYPES_WITH_SCORING = [
  PROCESS_STEP_RESPONSE_TYPE.MULTIPLE_CHOICE,
  PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_5,
  PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_10,
  PROCESS_STEP_RESPONSE_TYPE.PROCESS,
]

export const PROCESS_STEP_RESPONSE_TYPES_USING_SELECTIONS = [
  PROCESS_STEP_RESPONSE_TYPE.SELECTION,
  PROCESS_STEP_RESPONSE_TYPE.TEMPERATURE_TIMER,
]

export const PROCESS_STEP_LOGIC_RESPONSE_TYPES = [PROCESS_STEP_RESPONSE_TYPE.NUMBER]

export const PROCESS_STEP_RESPONSE_OPTIONS = [
  { type: PROCESS_STEP_RESPONSE_TYPE.CHECKBOX, icon: "checkbox", label: "Checkbox" },
  { type: PROCESS_STEP_RESPONSE_TYPE.PHOTOS, icon: "camera", label: "Photo submission" },
  { type: PROCESS_STEP_RESPONSE_TYPE.FILES, icon: "upload-file", label: "File submission" },
  { divider: "Inputs" },
  { type: PROCESS_STEP_RESPONSE_TYPE.TEXT, icon: "text", label: "Short text entry" },
  { type: PROCESS_STEP_RESPONSE_TYPE.LONG_TEXT, icon: "long-text", label: "Long text entry" },
  {
    type: PROCESS_STEP_RESPONSE_TYPE.NUMBER,
    icon: "number",
    label: "Number entry",
    adornments: ["logic", "bluetooth"],
  },
  {
    type: PROCESS_STEP_RESPONSE_TYPE.SELECTION,
    icon: "selection",
    label: "Selection",
    audit: true,
  },
  { type: PROCESS_STEP_RESPONSE_TYPE.DATE_TIME, icon: "date", label: "Date & time entry" },
  { type: PROCESS_STEP_RESPONSE_TYPE.SIGNATURE, icon: "signature", label: "Signature", audit: true },
  { divider: "Buttons" },
  {
    type: PROCESS_STEP_RESPONSE_TYPE.MULTIPLE_CHOICE,
    icon: "button",
    useResponseSet: true,
    appendToName: "buttons",
    canResponseFail: true,
  },
  { divider: "More" },
  { type: PROCESS_STEP_RESPONSE_TYPE.SECTION, icon: "section", label: "Section", audit: true },
  { type: PROCESS_STEP_RESPONSE_TYPE.INSTRUCTION, icon: "instruction", label: "Instruction", audit: true },
  {
    type: PROCESS_STEP_RESPONSE_TYPE.PROCESS,
    icon: "steps",
    label: "Nest an existing process",
    audit: true,
    canNestedFail: true,
  },
  {
    type: PROCESS_STEP_RESPONSE_TYPE.TEMPERATURE_TIMER,
    icon: "temperature-timer",
    label: "Temperature timer",
    features: ["food", "temperature_timer"],
  },
]

// Types that may be aggregated for purposes of reporting
export const PROCESS_STEP_AGGREGATABLE_RESPONSE_TYPES = [
  PROCESS_STEP_RESPONSE_TYPE.MULTIPLE_CHOICE,
  PROCESS_STEP_RESPONSE_TYPE.SELECTION,
  PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_5,
  PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_10,
]

export const PROCESS_STEP_INPUT_TYPE = {
  MANUAL: "manual",
  DEVICE: "device",
}

export const PROCESS_STEP_INPUT_TYPE_LABELS = {
  [PROCESS_STEP_INPUT_TYPE.MANUAL]: "Manual entry",
  [PROCESS_STEP_INPUT_TYPE.DEVICE]: "Bluetooth device",
}

export const RESPONSE_SET_DISPLAY_AS = {
  TEXT: "text",
  ICON: "icon",
}

export const JOBS_SET = {
  ALL_USERS: "all_users",
  SELF: "self",
  OTHERS: "others",
}

export const JOB_REPORT_DATE_TYPE = {
  COMPLETED: "completed",
  CREATED: "created",
}

export const JOB_STATUS = {
  TODO: "todo",
  IN_PROGRESS: "in_progress",
  AWAITING_SUBMISSION: "awaiting_submission",
  COMPLETED: "completed",
  SUBMITTED: "submitted",
}

export const JOB_STATUS_LABELS = {
  [JOB_STATUS.TODO]: "To Do",
  [JOB_STATUS.IN_PROGRESS]: "In progress",
  [JOB_STATUS.AWAITING_SUBMISSION]: "Awaiting submission",
  [JOB_STATUS.COMPLETED]: "Completed",
  [JOB_STATUS.SUBMITTED]: "Submitted",
}

export const KNOWLEDGE_CONTENT_TYPENAMES = {
  TEXT: "KnowledgeContentText",
  IMAGE: "KnowledgeContentImage",
  FILES: "KnowledgeContentFiles",
  NOTE: "KnowledgeContentNote",
  VIDEO: "KnowledgeContentVideo",
  MULTICHOICE: "KnowledgeContentMultichoice",
}

export const KNOWLEDGE_CONTENT_TYPENAME_LABELS = {
  [KNOWLEDGE_CONTENT_TYPENAMES.TEXT]: "Text content",
  [KNOWLEDGE_CONTENT_TYPENAMES.IMAGE]: "Image",
  [KNOWLEDGE_CONTENT_TYPENAMES.NOTE]: "Note (Information)",
  [KNOWLEDGE_CONTENT_TYPENAMES.NOTE_IMPORTANT]: "Note (Important)",
  [KNOWLEDGE_CONTENT_TYPENAMES.NOTE_DANGER]: "Note (Danger)",
  [KNOWLEDGE_CONTENT_TYPENAMES.FILES]: "File attachments",
  [KNOWLEDGE_CONTENT_TYPENAMES.VIDEO]: "Media",
  [KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE]: "Multiple choice question",
}

export const KNOWLEDGE_CONTENT_NOTE_TYPE = {
  INFORMATION: "information",
  IMPORTANT: "important",
  DANGER: "danger",
}

export const ORGANISATION_INDUSTRIES = [
  "Accommodation",
  "Administrative and Support Services",
  "Agriculture, Forestry and Fishing",
  "Arts and Recreation Services",
  "Aviation and Marine",
  "Construction",
  "Education and Training",
  "Electricity, Gas, Water and Waste Services",
  "Financial and Insurance Services",
  "Healthcare",
  "Hospitality",
  "Information Media and Telecommunications",
  "Manufacturing",
  "Mining",
  "Professional, Scientific and Technical Services",
  "Public Administration and Safety",
  "Rental, Hiring and Real Estate Services",
  "Retail Trade",
  "Transport",
  "Wholesale Trade",
  "Other Services",
]

export const BILLING_TYPE = {
  PER_USER: "per_user",
  PER_LOCATION: "per_location",
  FLAT_RATE: "flat_rate",
}

export const DEMO_PROFILE = {
  LABELS: "labels",
}

export const DEVICE_PLATFORM_OS = {
  ANDROID: "android",
  IOS: "ios",
}

export const DEVICE_TYPE = {
  UNKNOWN: 0,
  PHONE: 1,
  TABLET: 2,
  DESKTOP: 3,
  TV: 4,
}

export const WEB_POSTMESSAGE_TYPE = {
  IMAGES: 0,
  DOWNLOAD: 1,
  TAKE_PHOTOS: 2,
  ROUTE_CHANGED: 3,
  PRINT_LABELS: 4,
  FIND_PRINTERS: 5,
  FIND_PROBES: 6,
  START_READING_PROBE_VALUE: 7,
  STOP_READING_PROBE_VALUE: 8,
  SELECT_PRINTER: 9,
  PONG: 200,
}

export const DEVICE_POSTMESSAGE_BEHAVIOUR = {
  COMPLETE_JOB_STEP: "complete_job_step",
}

export const DEVICE_POSTMESSAGE_TYPE = {
  PRINTER_LIST: 1,
  TAKEN_PHOTOS: 2,
  PROBE_LIST: 3,
  PROBE_VALUE: 4,
  PROBE_BUTTON: 5,
  OIL_QUALITY_TESTER_LIST: 8,
  PING: 200,
}

export const IDENTITY_PROVIDER_BEHAVIOUR = {
  NORMAL: "normal",
  AZURE_AD: "azure_ad",
}

export const OPENID_PROMPT = {
  DEFAULT: "default",
  NONE: "none",
  LOGIN: "login",
  CONSENT: "consent",
  SELECT_ACCOUNT: "select_account",
}

export const SENSOR_DIALOG_TYPE = {
  EDIT_SENSOR_NAME: "edit_sensor_name",
}

export const SENSOR_CHANNEL_NAME = {
  TEMPERATURE: "temperature",
  PROBE_TEMPERATURE: "probe_temperature",
  HUMIDITY: "humidity",
  CARBON_DIOXIDE: "carbon_dioxide",
}

export const TIMESHEET_STATUS = {
  NOT_SCHEDULED: "not_scheduled",
  SCHEDULED: "scheduled",
  ACTIVE: "active",
  PAUSED: "paused",
  ENDED: "ended",
}

export const CALENDAR_ENTRY_TYPE = {
  NORMAL: "normal",
  AVAILABLE_UNTIL: "available_until",
  FUTURE: "future",
}

export const TRIGGER_TYPE = {
  SCHEDULE: "schedule",
  EVENT: "event",
}

export const TRIGGER_TARGET_TYPE = {
  NOTIFY_USERS: "notify_users",
  NOTIFY_EMAILS: "notify_emails",
  INTEGRATION: "integration",
  NOTIFY_AUTHOR: "notify_author",
}

export const TRIGGER_TARGET_TYPE_LABELS = {
  [TRIGGER_TARGET_TYPE.NOTIFY_USERS]: "Send a communication to Operandio users",
  [TRIGGER_TARGET_TYPE.NOTIFY_EMAILS]: "Send a communciation to external email address(es)",
  [TRIGGER_TARGET_TYPE.INTEGRATION]: "Raise an integration webhook",
  [TRIGGER_TARGET_TYPE.NOTIFY_AUTHOR]: "Send a communication to the Operandio user that assigned the course",
}

export const TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE = {
  MONTHLY_SUMMARY: "monthly_summary",
  WEEKLY_SUMMARY: "weekly_summary",
  YESTERDAY_SUMMARY: "yesterday_summary",
  INTRADAY_SUMMARY: "intraday_summary",
}

export const TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE_LABELS = {
  [TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE.MONTHLY_SUMMARY]: "Summary of previous calendar months activity",
  [TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE.WEEKLY_SUMMARY]: "Summary of the past 7 days of activity",
  [TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE.YESTERDAY_SUMMARY]: "Summary of the previous days activity",
  [TRIGGER_SCHEDULE_SOURCE_OUTPUT_TYPE.INTRADAY_SUMMARY]: "Intraday summary of activity",
}

export const TRIGGER_NOTIFY_METHOD = {
  IN_APP: "in_app",
  EMAIL: "email",
  SMS: "sms",
  PUSH: "push",
}

export const TRIGGER_NOTIFY_METHOD_LABELS = {
  [TRIGGER_NOTIFY_METHOD.IN_APP]: "In-application",
  [TRIGGER_NOTIFY_METHOD.EMAIL]: "Email",
  [TRIGGER_NOTIFY_METHOD.SMS]: "SMS",
  [TRIGGER_NOTIFY_METHOD.PUSH]: "Push notification",
}

export const EVENT_NAME = {
  ACTION_CREATED: "action_created",
  ACTION_UPDATED: "action_updated",
  ACTION_OVERDUE: "action_overdue",
  ACTION_COMMENT_CREATED: "action_comment_created",

  USER_TRAINING_COURSE_COMPLETED: "user_training_course_completed",
  USER_TRAINING_MODULE_COMPLETED: "user_training_module_completed",

  JOB_CREATED: "job_created",
  JOB_COMPLETED: "job_completed",
  JOB_STEP_COMPLETED: "job_step_completed",
  JOB_SUBMITTED: "job_submitted",
  JOB_OVERDUE: "job_overdue",
}

export const LABEL_TEMPLATE_FIELD_TYPE = {
  NAME: "name",
  USER: "user",
  LOCATION: "location",
  CURRENT_DATETIME: "current_datetime",
  PREP_DATETIME: "prep_datetime",
  READY_DATETIME: "ready_datetime",
  DISCARD_DATETIME: "discard_datetime",
  DYNAMIC_TEXT_ONCREATE: "dynamic_text_oncreate",
  DYNAMIC_TEXT_ONPRINT: "dynamic_text_onprint",
  STATIC_TEXT: "static_text",
  BARCODE: "barcode",
}

export const BARCODE_TYPE = {
  EAN8: "ean8",
  EAN13: "ean13",
  UPCA: "upca",
  UPCE: "upce",
  CODE128: "code128",
  CODE39: "code39",
  ITF: "interleaved2of5",
}

export const PRINTER_TYPE = {
  BIXOLON_USB: "bixolon_usb",
  ZEBRA_BLUETOOTH: "zebra_bluetooth",
  ZEBRA_BLUETOOTH_LOW_ENERGY: "zebra_bluetooth_low_energy",
  ZEBRA_WIFI: "zebra_wifi",
  ZEBRA_USB: "zebra_usb",
  GAINSCHA_BLUETOOTH: "gainscha_bluetooth",
  GAINSCHA_BLUETOOTH_LOW_ENERGY: "gainscha_bluetooth_low_energy",
  GAINSCHA_WIFI: "gainscha_wifi",
  GAINSCHA_USB: "gainscha_usb",
  UNKNOWN_BLUETOOTH: "unknown_bluetooth",
  UNKNOWN_BLUETOOTH_LOW_ENERGY: "unknown_bluetooth_low_energy",
  UNKNOWN_USB: "unknown_usb",
  UNKNOWN_NETWORK: "unknown_network",
}

export const PRINTER_TYPE_LABELS = {
  [PRINTER_TYPE.BIXOLON_USB]: "Bixolon USB",
  [PRINTER_TYPE.ZEBRA_BLUETOOTH]: "Zebra Bluetooth",
  [PRINTER_TYPE.ZEBRA_BLUETOOTH_LOW_ENERGY]: "Zebra Bluetooth Low Energy",
  [PRINTER_TYPE.ZEBRA_WIFI]: "Zebra Wifi",
  [PRINTER_TYPE.ZEBRA_USB]: "Zebra USB",
  [PRINTER_TYPE.GAINSCHA_BLUETOOTH]: "Gainscha Bluetooth",
  [PRINTER_TYPE.GAINSCHA_BLUETOOTH_LOW_ENERGY]: "Gainscha Bluetooth Low Energy",
  [PRINTER_TYPE.GAINSCHA_WIFI]: "Gainscha Wifi",
  [PRINTER_TYPE.GAINSCHA_USB]: "Gainscha USB",
  [PRINTER_TYPE.UNKNOWN_BLUETOOTH]: "Generic Bluetooth",
  [PRINTER_TYPE.UNKNOWN_BLUETOOTH_LOW_ENERGY]: "Generic Bluetooth Low Energy",
  [PRINTER_TYPE.UNKNOWN_USB]: "Generic USB",
  [PRINTER_TYPE.UNKNOWN_NETWORK]: "Network",
}

export const PROBE_TYPE = {
  ETI_BLUETOOTH_LE: "eti_bluetooth_le",
  OIL_QUALITY_TESTER: "oil_quality_tester",
}

export const ROLE_SCOPE = {
  ORGANISATION: "organisation",
  COMMON: "common",
}

export const GROUP_VISIBILITY = {
  GENERAL: "general",
  PRIVATE: "private",
}

export const PROCESS_STEP_LOGIC_RANGE = {
  BETWEEN: "between",
  NOT_BETWEEN: "not_between",
  EQUAL: "equal",
  NOT_EQUAL: "not_equal",
  GREATER_THAN: "greater_than",
  GREATER_OR_EQUAL: "greater_or_equal",
  LESS_THAN: "less_than",
  LESS_OR_EQUAL: "less_or_equal",
}

export const PROCESS_STEP_LOGIC_MULTIPLE_VALUE_RANGES = [
  PROCESS_STEP_LOGIC_RANGE.BETWEEN,
  PROCESS_STEP_LOGIC_RANGE.NOT_BETWEEN,
]

export const PROCESS_STEP_LOGIC_OUTCOME = {
  ACTION: "action",
  NOTE: "note",
  NOTE_TEXT: "note_text",
  NOTE_IMAGE: "note_image",
  NOTE_FILE: "note_file",
}

export const PROCESS_STEP_LOGIC_OUTCOME_LABELS = {
  [PROCESS_STEP_LOGIC_OUTCOME.ACTION]: "Require an action",
  [PROCESS_STEP_LOGIC_OUTCOME.NOTE]: "Require a note",
  [PROCESS_STEP_LOGIC_OUTCOME.NOTE_TEXT]: "Require a text note",
  [PROCESS_STEP_LOGIC_OUTCOME.NOTE_IMAGE]: "Require an image note",
  [PROCESS_STEP_LOGIC_OUTCOME.NOTE_FILE]: "Require a file note",
}

export const PROCESS_STEP_FORMAT_UNIT = {
  NUMERIC_PERCENTAGE: "numeric_percentage",
  TEMPERATURE_CELSIUS: "temperature_celsius",
  TEMPERATURE_FAHRENHEIT: "temperature_fahrenheit",
  WEIGHT_GRAMS: "weight_grams",
  WEIGHT_KILOGRAMS: "weight_kilograms",
  WEIGHT_OUNCES: "weight_ounces",
  WEIGHT_POUNDS: "weight_pounds",
  LENGTH_MILLIMETERS: "length_millimeters",
  LENGTH_CENTIMETERS: "length_centimeters",
  LENGTH_METERS: "length_meters",
  LENGTH_KILOMETERS: "length_kilometers",
  LENGTH_INCHES: "length_inches",
  LENGTH_FEET: "length_feet",
  LENGTH_YARDS: "length_yards",
  LENGTH_MILES: "length_miles",
  VOLUME_MILLILITERS: "volume_milliliters",
  VOLUME_LITERS: "volume_liters",
  VOLUME_CUBIC_CENTIMETERS: "volume_cubic_centimeters",
  VOLUME_CUBIC_METERS: "volume_cubic_meters",
  VOLUME_FLUID_OUNCES: "volume_fluid_ounces",
  VOLUME_GALLONS: "volume_gallons",
  VOLUME_CUBIC_INCHES: "volume_cubic_inches",
  VOLUME_CUBIC_FEET: "volume_cubic_feet",
  TIME_SECONDS: "time_seconds",
  TIME_MINUTES: "time_minutes",
  TIME_HOURS: "time_hours",
  TIME_DAYS: "time_days",
  CURRENCY_DOLLAR: "currency_dollar",
  CURRENCY_POUND: "currency_pound",
  CURRENCY_EURO: "currency_euro",
  ACIDITY_PH: "acidity_ph",
  OIL_QUALITY_TPM: "oil_quality_tpm",
}

export const PROCESS_STEP_SELECTION_SOURCES = {
  USERS: "users",
  SUPPLIERS: "suppliers",
  ASSETS: "assets",
  FOOD_ITEMS: "foodItems",
  CUSTOM_ITEMS: "custom_items",
}

export const ACTION_PRIORITY = {
  CRITICAL: "critical",
  HIGH: "high",
  NORMAL: "normal",
  LOW: "low",
}

export const ACTION_PRIORITY_LABELS = {
  [ACTION_PRIORITY.CRITICAL]: "Critical",
  [ACTION_PRIORITY.LOW]: "Low",
  [ACTION_PRIORITY.NORMAL]: "Normal",
  [ACTION_PRIORITY.HIGH]: "High",
}

export const ACTION_STATUS = {
  UNRESOLVED: "unresolved",
  IN_PROGRESS: "in_progress",
  BLOCKED: "blocked",
  RESOLVED: "resolved",
  CANCELLED: "cancelled",
}

export const ACTION_STATUS_LABELS = {
  [ACTION_STATUS.UNRESOLVED]: "Unresolved",
  [ACTION_STATUS.IN_PROGRESS]: "In progress",
  [ACTION_STATUS.BLOCKED]: "Blocked",
  [ACTION_STATUS.RESOLVED]: "Resolved",
  [ACTION_STATUS.CANCELLED]: "Cancelled",
}

export const ACTION_STATUS_VERBS = {
  [ACTION_STATUS.UNRESOLVED]: "Unresolve",
  [ACTION_STATUS.IN_PROGRESS]: "In progress",
  [ACTION_STATUS.BLOCKED]: "Block",
  [ACTION_STATUS.RESOLVED]: "Resolve",
  [ACTION_STATUS.CANCELLED]: "Cancel",
}

export const TEMPERATURE_TIMER_STATUS = {
  IN_RANGE: "in_range",
  OUT_OF_RANGE: "out_of_range",
}

export const TEMPERATURE_TIMER_STATUS_LABELS = {
  [TEMPERATURE_TIMER_STATUS.IN_RANGE]: "In range",
  [TEMPERATURE_TIMER_STATUS.OUT_OF_RANGE]: "Out of range",
}

export const JOB_COMPLETION_STATUS = {
  COMPLETED_ON_TIME: "completed_on_time",
  COMPLETED_LATE: "completed_late",
  IN_PROGRESS: "in_progress",
  OVERDUE: "overdue",
  UNCOMPLETED: "uncompleted",
}

export const JOB_COMPLETION_STATUS_LABELS = {
  [JOB_COMPLETION_STATUS.COMPLETED_ON_TIME]: "Completed (On time)",
  [JOB_COMPLETION_STATUS.COMPLETED_LATE]: "Completed (Late)",
  [JOB_COMPLETION_STATUS.IN_PROGRESS]: "In Progress",
  [JOB_COMPLETION_STATUS.OVERDUE]: "Overdue",
  [JOB_COMPLETION_STATUS.UNCOMPLETED]: "Uncompleted",
}

export const PREP_ITEM_ROUNDING = {
  NONE: "none",
  UP: "up",
  DOWN: "down",
}

export const DOWNLOAD_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAILED: "failed",
}
