import React from "react"
import { useDeviceUtils } from "../../utils"
import { DeviceClientUpdateRequiredAlert } from "./DeviceClientUpdateRequiredAlert"

const RequireDeviceClientVersion = ({ minimumVersion, fallback = null, children }) => {
  const { isDeviceClient, isLessThanVersion, versionStringToObject } = useDeviceUtils()

  if (minimumVersion && isDeviceClient && isLessThanVersion(versionStringToObject(minimumVersion))) {
    return fallback || <DeviceClientUpdateRequiredAlert />
  }

  return <>{children}</>
}

export { RequireDeviceClientVersion }
