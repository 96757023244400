import { useEffect, useState } from "react"
import {
  IconButton,
  Badge,
  Tooltip,
  Box,
  CircularProgress,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { t } from "@lingui/macro"
import { useQueryDownloads, useQueryNotifications } from "../../data"
import { NotificationsPopover, Icon } from ".."
import { useMountAwareReactiveVar } from "../../utils"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"
import { useDownloadReadySnackbar } from "../Download/useDownloadReadySnackbar"

const useStyles = makeStyles((theme) => ({
  iconButtonWrapper: {
    position: "relative",
  },
  circularProgress: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    cursor: "pointer",
  },
  badge: {
    "& .MuiBadge-badge": {
      right: 2,
      top: 4,
      fontWeight: "600",
      border: `2px solid ${theme.palette.background.paper}`,
      padding: theme.spacing(0, 0.5),
    },
  },
  snackbarLink: {
    color: theme.palette.text.white,
    marginLeft: 10,
  },
}))

const NotificationIndicator = ({ buttonClassName, iconClassName }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useStyles()
  const hasNewDeviceClientVersion = useMountAwareReactiveVar(newDeviceClientAvailableVar)
  const notificationsQuery = useQueryNotifications()
  const downloadsQuery = useQueryDownloads({
    variables: {
      limit: 10,
    },
    pollInterval: 60000,
  })
  useDownloadReadySnackbar()
  const [notificationsEl, setNotificationsEl] = useState(null)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const [notificationUnsubscribes, setNotificationUnsubscribes] = useState(null)
  const [downloadsUnsubscribes, setDownloadsUnsubscribes] = useState(null)

  const {
    loading: notificationsLoading,
    error: notificationsError,
    data: notificationsData,
    called: calledNotifications,
    subscribe: subscribeNotifications,
  } = notificationsQuery
  const { loading: downloadsLoading, called: calledDownloads, subscribe: subscribeDownloads } = downloadsQuery

  useEffect(() => {
    if (calledNotifications && !notificationUnsubscribes) {
      setNotificationUnsubscribes(subscribeNotifications())
      return () => {
        try {
          if (notificationUnsubscribes) {
            notificationUnsubscribes.map((unsub) => unsub())
          }
        } catch {
          // Ignore
        }
      }
    }
  }, [calledNotifications, notificationUnsubscribes, subscribeNotifications])

  useEffect(() => {
    if (!downloadsLoading && calledDownloads && !downloadsUnsubscribes) {
      setDownloadsUnsubscribes(subscribeDownloads())
      return () => {
        try {
          if (downloadsUnsubscribes) {
            downloadsUnsubscribes.map((unsub) => unsub())
          }
        } catch {
          // Ignore
        }
      }
    }
  }, [downloadsLoading, calledDownloads, downloadsUnsubscribes, subscribeDownloads])

  let notificationCount =
    notificationsLoading || notificationsError
      ? 0
      : notificationsData && notificationsData.notifications
        ? notificationsData.notifications.unread
        : 0
  notificationCount += !notificationsLoading && hasNewDeviceClientVersion ? 1 : 0
  const countOfInProgressDownloading = downloadsQuery?.data?.downloads.countOfInProgress ?? 0
  const hasAnyNotifications =
    !notificationsLoading && notificationsData && notificationsData.notifications.items.length > 0
  const hasAnyDownloads = !downloadsLoading && downloadsQuery?.data?.downloads.count > 0
  const hasAny = hasAnyNotifications || hasAnyDownloads
  const isDownloading = countOfInProgressDownloading > 0

  const handleNotificationsClick = (event) => {
    setNotificationsEl(event.currentTarget)
    setNotificationsOpen(true)
  }

  const handleNotificationsClose = () => {
    setNotificationsEl(null)
    setNotificationsOpen(false)
  }

  return (
    <>
      <Tooltip title={!hasAny ? "" : t`Notifications`}>
        <Box className={classes.iconButtonWrapper}>
          <IconButton
            aria-label={t`Notifications`}
            onClick={handleNotificationsClick}
            className={buttonClassName}
            disabled={!hasAny}
          >
            {isDownloading ? (
              <Icon name="notifications" className={iconClassName} />
            ) : (
              <Badge className={classes.badge} badgeContent={notificationCount} color="secondary" overlap="circular">
                <Icon name="notifications" className={iconClassName} />
              </Badge>
            )}
          </IconButton>
          {isDownloading && <CircularProgress className={classes.circularProgress} size={mdUp ? 40 : 34} />}
        </Box>
      </Tooltip>

      <NotificationsPopover
        open={notificationsOpen}
        anchorEl={notificationsEl}
        onClose={handleNotificationsClose}
        countOfInProgressDownloading={countOfInProgressDownloading}
        notificationsQuery={notificationsQuery}
        downloadsQuery={downloadsQuery}
      />
    </>
  )
}

export default NotificationIndicator
