import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { Icon, RowBox } from ".."
import { useProbes } from "../../utils/useProbes"
import { ProbeSelector } from "./ProbeSelector"
import { PROCESS_STEP_FORMAT_UNIT } from "../../data/enums"

const { OIL_QUALITY_TPM } = PROCESS_STEP_FORMAT_UNIT
const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
    textAlign: "left",
  },
}))

const SelectedProbeButton = ({ format, minimumDeviceClientVersion }) => {
  const classes = useStyles()
  const { probe, oilQualityTester } = useProbes({ format })
  const [openSelector, setOpenSelector] = useState(false)

  const probeToUse = format === OIL_QUALITY_TPM ? oilQualityTester : probe

  const handleOpen = () => {
    setOpenSelector(true)
  }

  const handleClose = () => {
    setOpenSelector(false)
  }

  const probeSelector = (
    <>
      {openSelector && (
        <ProbeSelector
          open
          onClose={handleClose}
          format={format}
          minimumDeviceClientVersion={minimumDeviceClientVersion}
        />
      )}
    </>
  )

  if (!probeToUse) {
    return (
      <>
        {probeSelector}
        <RowBox>
          <Icon name="probe" color="inherit" fontSize="large" />

          <Button className={classes.link} color="primary" onClick={handleOpen}>
            Please select a probe
          </Button>
        </RowBox>
      </>
    )
  }

  return (
    <>
      {probeSelector}
      <RowBox>
        <Icon name="probe" color="inherit" fontSize="large" />

        <Button className={classes.link} color="primary" onClick={handleOpen}>
          {probeToUse.name}
        </Button>
      </RowBox>
    </>
  )
}

export { SelectedProbeButton }
