import React, { forwardRef, memo } from "react"
import { Box, useTheme } from "@material-ui/core"

const ColumnBox = forwardRef(function ColumnBox({ alignItems = "flex-start", gap, children, ...rest }, ref) {
  const theme = useTheme()

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      sx={{ gap: gap ? theme.spacing(gap) : 0 }}
      {...rest}
    >
      {children}
    </Box>
  )
})

const ColumnBoxMemo = memo(ColumnBox)

export { ColumnBoxMemo as ColumnBox }
