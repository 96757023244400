import { useState, useEffect, useRef } from "react"
import { Popover, Divider, Grid, Button, Tabs, Tab, Chip, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import NotificationList from "../NotificationList"
import { useMutationReadAllNotification } from "../../data"
import DownloadList from "../Download"
import { RowBox } from "../Boxes"
import { LoadingSpinner } from "../LoadingSpinner"

const usePopoverStyles = makeStyles(() => ({
  root: {
    width: 380,
    maxHeight: "85vh",
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2, 2),
    fontWeight: "600",
    fontSize: "16px",
  },
  notifications: {},
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    textAlign: "center",
  },
  tabs: {
    "& .Mui-selected": {
      "& .MuiTab-wrapper": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiTabs-indicator": {
      background: `${theme.palette.primary.main} !important`,
    },
  },
  numberChip: {
    height: 20,
    fontSize: 12,
    fontWeight: "500",
    color: "white",
    backgroundColor: theme.palette.error.main,
  },
}))

const tabs = [<Trans>General</Trans>, <Trans>Downloads</Trans>]

const NotificationsPopover = ({
  open,
  anchorEl,
  onClose,
  notificationsQuery,
  downloadsQuery,
  countOfInProgressDownloading,
}) => {
  const classes = useStyles()
  const popoverClasses = usePopoverStyles()
  const [readAllNotifications, { loading: readAllLoading }] = useMutationReadAllNotification()
  const [currentTab, setCurrentTab] = useState(0)
  const actionRef = useRef(null)

  const id = open ? "notifications-popover" : undefined

  const hasUnreadNotifications = notificationsQuery?.data?.notifications?.items?.some((n) => !n.read)

  const handleMarkAllRead = async () => {
    if (!hasUnreadNotifications) {
      return
    }
    await readAllNotifications()
  }

  const handleViewAll = () => {
    onClose && onClose()
  }

  const getFooterName = () => {
    return currentTab === 0 ? t`notifications` : t`downloads`
  }

  const renderTabTitle = (tab) => {
    if (tab === tabs?.[0]) return <Trans>{tab}</Trans>

    return (
      <RowBox>
        <Box mr="6px">
          <Trans>{tab}</Trans>
        </Box>
        {countOfInProgressDownloading > 0 && (
          <Chip label={countOfInProgressDownloading} color="primary" size="small" className={classes.numberChip} />
        )}
      </RowBox>
    )
  }

  // Force update.
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        actionRef.current.updateIndicator()
      }, 200)

      return () => {
        clearTimeout(timeout)
        setCurrentTab(0)
      }
    }
  }, [open])

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={popoverClasses}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div className={classes.header}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Trans>Notifications</Trans>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleMarkAllRead} disabled={readAllLoading}>
              {!readAllLoading && <Trans>Mark all as read</Trans>}
              {readAllLoading && <LoadingSpinner size="25px" delay={false} />}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.notifications}>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={currentTab}
          aria-label="Select tabs"
          action={actionRef}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`${tab}-${index}`}
              label={renderTabTitle(tab)}
              value={index}
              onClick={() => setCurrentTab(index)}
            />
          ))}
        </Tabs>
        <Divider />
        {currentTab === 0 && <NotificationList onNavigate={onClose} {...notificationsQuery} />}
        {currentTab === 1 && <DownloadList downloadsQuery={downloadsQuery} />}
      </div>
      <div className={classes.footer}>
        <Button component={NavLink} to={`/${getFooterName()}`} onClick={handleViewAll} color="primary">
          <Trans>View all {getFooterName()}</Trans>
        </Button>
      </div>
    </Popover>
  )
}

export default NotificationsPopover
