import React, { useState } from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { CancelOutlined, GetAppOutlined } from "@material-ui/icons"
import Axios from "axios"
import FileDownload from "js-file-download"
import Config from "react-global-configuration"
import { FileIcon } from "../Icon"
import { useMutationRetrieveDownload } from "../../data"
import { useSnackbar } from "../SnackbarProvider"
import { preventDefault, useDeviceUtils } from "../../utils"
import { LoadingSpinner } from ".."
import { FormatBytes } from "../Format"
import { PaperBox } from "../Boxes"

const useStyle = makeStyles((theme) => ({
  cardRoot: {
    cursor: "pointer",
  },
  cardTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  cardDetail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
}))

const UploadEditList = ({
  uploads,
  onDownload,
  onRemove,
  mt = 0.5,
  editMode = false,
  documents = true,
  images = false,
}) => {
  const classes = useStyle()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const [retrieveDownload] = useMutationRetrieveDownload()
  const { canPostMessage, postDownloadMessage } = useDeviceUtils()
  const snackbar = useSnackbar()
  const [busy, setBusy] = useState(null)

  const handleDownload = async (id) => {
    if (editMode) return

    if ((kiosk || device) && !canPostMessage()) {
      snackbar.showMessage({ message: "Please download via desktop browser" })
      return
    }

    setBusy(id)
    try {
      const { data } = await retrieveDownload({ variables: { id } })
      if (data) {
        if (kiosk || device) {
          postDownloadMessage(data.retrieveDownload)
          setBusy(null)
          return
        }

        const { signedDownloadUrl, upload } = data.retrieveDownload
        const response = await Axios({
          url: signedDownloadUrl,
          method: "GET",
          responseType: "blob",
          headers: {
            "Content-Type": upload.contentType,
          },
        })

        FileDownload(response.data, upload.fileName)

        if (onDownload) onDownload(upload.id)
      } else {
        snackbar.showMessage({ message: "Download failed" })
      }
    } catch (err) {
      snackbar.showMessage({ message: "Download error" })
    } finally {
      setBusy(null)
    }
  }

  const handleRemove = (id) => {
    onRemove && onRemove(id)
  }

  let files = uploads

  if (editMode) {
    files = files.map(({ id, fileSize, fileName, fileGroup, contentType, total }) => {
      const contentTypeToGroup = (t) => {
        if (!t) return null

        if (t.includes("image")) return "image"
        return "document"
      }

      return {
        id,
        fileSize: total || fileSize,
        fileName,
        fileGroup: contentTypeToGroup(contentType) || fileGroup,
      }
    })
  } else {
    if (documents) {
      files = uploads && uploads.length > 0 && uploads.filter((upload) => upload.fileGroup === "document")
    }

    if (images) {
      files = [...files, ...(uploads && uploads.length > 0 && uploads.filter((upload) => upload.fileGroup === "image"))]
    }
  }

  if (!files || !files.length) return null

  return (
    <>
      {files.map(({ id, fileName, fileGroup, fileSize }) => (
        <Box mb={1} key={id}>
          <PaperBox
            p={2}
            mt={mt}
            onClick={(event) => preventDefault(event, () => (editMode ? handleRemove(id) : handleDownload(id)))}
            className={classes.cardRoot}
          >
            <Box display="flex" flexGrow={1} flexDirection="row">
              <Box mr={1}>
                <FileIcon fileName={fileName} />
              </Box>
              <Box flexGrow={1} flexDirection="column" alignSelf="center" alignItems="center">
                <Box>
                  <Typography variant="h2" className={classes.cardTitle}>
                    {fileName}
                  </Typography>
                </Box>
                <Box className={classes.cardDetail}>
                  <FormatBytes bytes={fileSize} />
                  {fileSize > 0 && " | "}
                  {fileName.split(".").pop().toUpperCase()} {fileGroup.charAt(0).toUpperCase()}
                  {fileGroup.slice(1)}
                </Box>
              </Box>
              <Box mt={1}>
                {busy === id ? (
                  <LoadingSpinner size={24} delay={false} />
                ) : editMode ? (
                  <CancelOutlined />
                ) : (
                  <GetAppOutlined />
                )}
              </Box>
            </Box>
          </PaperBox>
        </Box>
      ))}
    </>
  )
}

export { UploadEditList }
