import React, { useState, useMemo } from "react"
import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import moment from "moment"
import Config from "react-global-configuration"
import { Plural, Trans } from "@lingui/macro"

import { useMakeJobStepOptimistic, useMutationJobStepAddNote, useMutationJobStepDeleteNote } from "../../data"
import { useAuth } from "../../services"
import FooterButton from "../Buttons/ActionCardFooterButton"
import { toId, useJobUtils, mapToIds } from "../../utils"
import MobileNotesPopup from "./MobileNotesPopup"
import DesktopNotesPopup from "./DesktopNotesPopup"

const useStyles = makeStyles((theme) => ({
  noNotesIcon: {
    fontSize: 24,
  },
  hasNotesIcon: {
    color: theme.palette.raised.secondary,
  },
  badge: {
    "&>.MuiBadge-dot": {
      backgroundColor: theme.palette.raised.secondary,
    },
  },
}))

const AddNoteFooterButton = ({ job, step, isPreview, isScheduled, required }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasFeature, location } = useAuth()
  const { canDeleteNote } = useJobUtils()
  const [addNote] = useMutationJobStepAddNote(job, step)
  const [deleteNote, { loading: deleteLoading }] = useMutationJobStepDeleteNote(job, step)
  const { makeJobStepAddNoteOptimisticResponse } = useMakeJobStepOptimistic()
  const [text, setText] = useState("")
  const [show, setShow] = useState(null)
  const [showDeleting, setShowDeleting] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { clientKiosk, clientDevice } = Config.get()
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  const notes = useMemo(
    () =>
      step.notes ? [...step.notes].sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1)) : [],
    [step.notes],
  )

  const open = Boolean(anchorEl)
  const hasNotes = Boolean(notes?.length)
  const isValid = Boolean(text?.trim() || uploads.length > 0)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFocus = () => {
    if (show) {
      setShow(null)
      setShowDeleting(false)
    }
  }

  const handleAdd = async () => {
    if (!isValid) {
      return
    }

    const variables = {
      job,
      step: toId(step),
      text,
      uploads: mapToIds(uploads),
    }

    setText("")
    setUploads([])
    setUploading([])
    await addNote({
      variables,
      optimisticResponse: makeJobStepAddNoteOptimisticResponse({
        ...variables,
        uploads: uploads.map((u) => ({ ...u, fileSize: u.loaded })),
        step,
      }),
    })
  }

  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAdd()
    }
  }

  const handleShow = (note) => {
    setShow(show === toId(note) || !canDeleteNote(note) ? null : toId(note))
    setShowDeleting(false)
  }

  const handleDeleting = () => setShowDeleting(true)

  const handleDelete = async (note) => {
    const variables = {
      job,
      step: toId(step),
      note: toId(note),
    }

    await deleteNote({
      variables,
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!hasFeature("process_step_notes")) {
    return null
  }

  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
    setUploading([...uploading.filter((upload) => toId(upload) !== toId(item))])
  }

  const handleDeviceUploaded = (items) => {
    setUploads((prev) => [...prev, ...items])
  }

  const handleRemoveUpload = (id) => {
    const newUploads = uploads.filter((upload) => toId(upload) !== id)
    setUploads([...newUploads])
    setUploading([...uploading.filter((upload) => toId(upload) !== id)])
  }

  const hasNativeCamera = clientDevice || clientKiosk

  const notesMessage = <Plural value={notes.length} one="# note" other="# notes" />

  const commonProps = {
    open,
    hasNotes,
    notes,
    show,
    location,
    canDeleteNote,
    showDeleting,
    deleteLoading,
    text,
    onTextChange: setText,
    hasNativeCamera,
    step,
    uploads,
    uploading,
    isValid,
    onClose: handleClose,
    onShow: handleShow,
    onDeleting: handleDeleting,
    onDelete: handleDelete,
    onFocus: handleFocus,
    onEnter: handleEnter,
    onDeviceUploaded: handleDeviceUploaded,
    onItemUploaded: handleItemUploaded,
    onRemoveUpload: handleRemoveUpload,
    onAdd: handleAdd,
  }

  const renderDesktopPopupAddNote = () => {
    return <DesktopNotesPopup {...commonProps} anchorEl={anchorEl} />
  }

  const renderMobilePopupAddNote = () => {
    return <MobileNotesPopup {...commonProps} />
  }

  return (
    <Box>
      <FooterButton
        icon={hasNotes ? "note-added" : "note"}
        iconClassName={hasNotes ? classes.hasNotesIcon : classes.noNotesIcon}
        label={hasNotes ? notesMessage : <Trans>Add note/media</Trans>}
        onClick={handleOpen}
        badgeProps={{
          invisible: !required,
          className: classes.badge,
        }}
        badgeColor={theme.palette.raised.secondary}
        disabled={isPreview || isScheduled}
        preview={isPreview}
        data-cy="AddNoteFooterButton-button"
      />

      {!xs && renderDesktopPopupAddNote()}
      {xs && renderMobilePopupAddNote()}
    </Box>
  )
}

export default AddNoteFooterButton
