import { gql, useMutation } from "@apollo/client"
import { toId } from "../../utils"
import { CALENDAR_FIELDS_FRAGMENT } from "./fragments/calendarFieldsFragment"
import { CALENDARS_QUERY } from "./useQueryCalendars"

const CREATE_CALENDAR_QUERY = gql`
  mutation CreateCalendar($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      ...CalendarFields
    }
  }
  ${CALENDAR_FIELDS_FRAGMENT}
`

const DELETE_CALENDAR_QUERY = gql`
  mutation DeleteCalendar($id: ID!) {
    calendar(id: $id) {
      delete
    }
  }
`

const useMutationCreateCalendar = (options) =>
  useMutation(CREATE_CALENDAR_QUERY, {
    refetchQueries: [{ query: CALENDARS_QUERY }],
    ...options,
  })

const useMutationDeleteCalendar = () => {
  return useMutation(DELETE_CALENDAR_QUERY, {
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Calendar" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })
}

export { useMutationCreateCalendar, useMutationDeleteCalendar }
